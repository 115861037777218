import { conditionalSpread, rem } from 'clyne-core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import './index.scss';

const Flex = props => {
    const {
        gap,
        shrink,
        column,
        justify,
        children,
        className,
        minHeight,
        row = true,
        stretch = true,
        align = props.column ? 'flex-start' : 'center',
    } = props;

    const style = useMemo(() => ({
        ...conditionalSpread({
            gap: rem(gap),
        }, gap === 0 || gap > 0),
        ...conditionalSpread({
            minHeight: rem(minHeight),
        }, minHeight > 0),
        ...conditionalSpread({
            alignItems: align,
        }, align),
        ...conditionalSpread({
            justifyContent: justify,
        }, justify),
        ...conditionalSpread({
            flexShrink: shrink,
        }, !isNaN(shrink)),
    }), [gap, minHeight, align, justify, shrink]);

    return (
        <div
            style={style}
            className={classNames(
                'flex-component',
                className,
                {
                    row,
                    column,
                    stretch,
                }
            )}
        >
            {children}
        </div>
    );
};

Flex.propTypes = {
    gap: PropTypes.number,
    row: PropTypes.bool,
    align: PropTypes.oneOf([
        'normal',
        'stretch',
        'center',
        'start',
        'end',
        'flex-start',
        'flex-end',
        'self-start',
        'self-end',
        'anchor-center',
        'baseline',
        'first baseline',
        'last baseline',
        'safe center',
        'unsafe center',
        'inherit',
        'initial',
        'revert',
        'revert-layer',
        'unset',
    ]),
    shrink: PropTypes.number,
    column: PropTypes.bool,
    stretch: PropTypes.bool,
    justify: PropTypes.oneOf([
        'center',
        'start',
        'end',
        'flex-start',
        'flex-end',
        'left',
        'right',
        'normal',
        'space-between',
        'space-around',
        'space-evenly',
        'stretch',
        'safe center',
        'unsafe center',
        'inherit',
        'initial',
        'revert',
        'revert-layer',
        'unset',
    ]),
    children: PropTypes.any,
    minHeight: PropTypes.number,
    className: PropTypes.string,
};

export default Flex;
