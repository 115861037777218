import React, { cloneElement, useEffect, useRef, useState } from 'react';
import { conditionalSpread, isFunction, rem } from 'clyne-core';
import { AnimatePresence, motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import Menu from '../menu';
import Button from '../button';

import { popperConfig } from '../../props';

import useDevice from '../../hooks/useDevice';
import useClickOutside from '../../hooks/useClickOutside';
import usePopoverInputFocus from '../../hooks/usePopoverInputFocus';

import PopoverContext from '../../contexts/popoverContext';

import { popoverConfig, popoverProps } from './props';

import './index.scss';

const Popover = props => {
    const {
        menu,
        width,
        header,
        footer,
        opened,
        sticky,
        onOpen,
        module,
        onClose,
        content,
        padding,
        loading,
        maxItems,
        children,
        headerRef,
        className,
        minHeight,
        passState,
        itemHeight,
        scrollerRef,
        onCloseState,
        onContentClick,
        onClickOutside,
        overflowPadding,
        selfSizing = true,
        offsetBounding = [0, 10],
        closeOnMenuClick = true,
        position = popoverConfig.position[11],
    } = props;

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);

    const options = {
        placement: position,
        strategy: sticky ? 'fixed' : 'absolute',
        modifiers: popperConfig.modifiers(offsetBounding, overflowPadding),
    };

    const { styles, attributes } = usePopper(referenceElement, popperElement, options);

    const { pathname } = useLocation();
    const [openedState, setOpenedState] = useState(opened);
    const [popperRef, hasPopperClickedOutside] = useClickOutside();
    const [referenceRef, hasReferenceClickedOutside] = useClickOutside();

    const { isMobile } = useDevice();

    useEffect(() => {
        setOpenedState(false);
    }, [pathname]);

    useEffect(() => {
        setOpenedState(opened);
    }, [opened]);

    useEffect(() => {
        !opened && isFunction(onCloseState);
    }, [opened]); // eslint-disable-line

    useEffect(() => {
        if (hasPopperClickedOutside && hasReferenceClickedOutside && openedState && !isMobile) {
            onClickOutside && onClickOutside();
            setOpenedState(false);
            onClose && onClose();
        }
    }, [hasPopperClickedOutside, hasReferenceClickedOutside, onClickOutside, openedState, isMobile]); // eslint-disable-line

    const popoverRef = useRef(null);
    const inputRef = useRef(null);
    const { focused } = usePopoverInputFocus(inputRef);

    const shouldRender = openedState && !loading;

    const startingFromTop = `${attributes?.popper?.['data-popper-placement']}`.includes('top');

    return (
        <PopoverContext.Provider
            value={{
                setOpenedState,
            }}
        >
            {cloneElement(children, {
                ref: ref => {
                    setReferenceElement(ref);
                    referenceRef.current = ref;
                },
                ...conditionalSpread({
                    popoverOpened: openedState,
                }, passState),
                ...conditionalSpread({
                    onClick: (children.props.onClick) ? children.props.onClick : (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOpenedState(val => !val);
                        typeof onOpen === 'function' && onOpen(true);
                    },
                }, !props.hasOwnProperty('opened')),
                className: classNames(children.props.className, {
                    'popover-opened': openedState,
                })
            })}
            {createPortal((
                <AnimatePresence>
                    {!!shouldRender && (
                        <div
                            ref={ref => {
                                setPopperElement(ref);
                                popperRef.current = ref;
                            }}
                            {...attributes.popper}
                            style={conditionalSpread({
                                ...conditionalSpread({ width: (width ? rem(width) : referenceRef?.current?.clientWidth) }, !selfSizing || !!width),
                                ...styles.popper,
                                '--top': styles.popper.top,
                                '--rendered-top': `${parseInt(`${styles.popper.top || 0}`) - window.scrollY}px`,
                            }, !isMobile)}
                            className={classNames(
                                `popover-holder`,
                                className,
                                {
                                    sticky,
                                    'animate-pointer-events': children?.props?.onDoubleClick,
                                }
                            )}
                        >
                            {isMobile && (
                                <motion.div
                                    key='popoverBackdrop'
                                    className='absolutely-splash popover-backdrop'
                                    onClick={() => {
                                        onClickOutside && onClickOutside();
                                        setOpenedState(false);
                                        onClose && onClose();
                                    }}
                                    initial={{
                                        opacity: 0,
                                    }}
                                    animate={{
                                        opacity: 1,
                                    }}
                                    exit={{
                                        opacity: 0,
                                        transition: {
                                            delay: 0.2,
                                        },
                                    }}
                                />
                            )}
                            <motion.ul
                                key='popoverC'
                                ref={popoverRef}
                                className='popover-c'
                                initial={isMobile ? {
                                    transform: 'translate3d(0, 110%, 0)',
                                } : {
                                    opacity: 0,
                                    clipPath: startingFromTop ? 'inset(100% 0 0 0)' : 'inset(0 0 100% 0)',
                                }}
                                animate={isMobile ? {
                                    transform: 'translate3d(0, 0, 0)',
                                    transition: {
                                        delay: 0.15,
                                    },
                                } : {
                                    opacity: 1,
                                    clipPath: 'inset(0)',
                                }}
                                exit={isMobile ? {
                                    transform: 'translate3d(0, 110%, 0)',
                                } : {
                                    opacity: 0,
                                    clipPath: startingFromTop ? 'inset(100% 0 0 0)' : 'inset(0 0 100% 0)',
                                }}
                                style={{
                                    ...conditionalSpread({
                                        height: popoverRef.current?.clientHeight,
                                    }, focused),
                                    ...conditionalSpread({
                                        minHeight: rem(minHeight),
                                    }, minHeight && isMobile),
                                }}
                            >
                                {(!!header || !!headerRef || !!module?.header) && (
                                    <li
                                        ref={val => {
                                            inputRef.current = val;
                                            !!headerRef && headerRef(val);
                                        }}
                                        className={classNames(
                                            'po-header',
                                            {
                                                'border': !module?.header?.children,
                                            }
                                        )}
                                    >
                                        {header}
                                        {module?.header && (
                                            <ul className='po-header-module'>
                                                <li>
                                                    {module?.header?.title && (
                                                        <div className='po-h-m-title text-ellipsis'>
                                                            {module.header.title}
                                                        </div>
                                                    )}
                                                    {module?.header?.subTitle && (
                                                        <div>
                                                            <div className='text-ellipsis'>
                                                                {module.header.subTitle}
                                                            </div>
                                                        </div>
                                                    )}
                                                </li>
                                                <li>
                                                    {module?.header?.cta && (
                                                        <Button
                                                            size='medium'
                                                            color='grayscale'
                                                            flexibility='fit'
                                                            {...module.header.cta}
                                                        />
                                                    )}
                                                </li>
                                                {!!module?.header?.children && (
                                                    <li className='span-children'>
                                                        {module?.header?.children}
                                                    </li>
                                                )}
                                            </ul>
                                        )}
                                    </li>
                                )}
                                <li
                                    className={classNames(
                                        `po-content`,
                                        {
                                            padding,
                                            'clipped': maxItems && itemHeight && !isMobile,
                                        }
                                    )}
                                    ref={val => !!scrollerRef && scrollerRef(val)}
                                    onClick={onContentClick}
                                    style={conditionalSpread(
                                        {
                                            '--maxItems': maxItems,
                                            '--itemHeight': rem(itemHeight),
                                        },
                                        (maxItems && itemHeight && !isMobile)
                                    )}
                                >
                                    {content}
                                    {menu && (
                                        <Menu
                                            data={menu}
                                            closePopover={() => closeOnMenuClick && setOpenedState(false)}
                                        />
                                    )}
                                </li>
                                {(footer || !!module?.footer?.cta) && (
                                    <li className='po-footer'>
                                        {footer}
                                        {module?.footer?.cta && (
                                            <div className='po-m-f-cta-holder'>
                                                {Array.isArray(module.footer.cta) ? module.footer.cta.map((cta, index) => (
                                                    <Button
                                                        key={index}
                                                        color='accent'
                                                        {...cta}
                                                    />
                                                )) : (
                                                    <Button
                                                        color='accent'
                                                        flexibility='fit'
                                                        {...module.footer.cta}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </li>
                                )}
                            </motion.ul>
                        </div>
                    )}
                </AnimatePresence>
            ), document.body)}
        </PopoverContext.Provider>
    );
};

Popover.propTypes = popoverProps;

export default Popover;
