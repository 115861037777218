import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { conditionalSpread } from 'clyne-core';
import Autosuggest from 'react-autosuggest';
import { useSetRecoilState } from 'recoil';
import { createPortal } from 'react-dom';

import Button from '../button';
import Search from '../search';
import Option from '../option';
import NoData from '../noData';
import Popover from '../popover';
import Translate from '../translate';
import { loadGoogleScriptState, searchForceUpdateState } from '../../state';

import useDevice from '../../hooks/useDevice';

import { geoLocationKey, getLSItem, guid, setLSItem, urlConstruct } from '../../helpers';

import translate from '../../utils/translate';
import searchParamsConstructor from '../../utils/searchParamsConstructor';

import { locationSearchProps } from './props';

const LocationSearch = props => {
    const {
        size,
        urlMode,
        appearance,
        cornerRadius,
    } = props;

    const { isMobile } = useDevice();

    const [autocompleteData, setAutocompleteData] = useState([]);
    const [contentRef, setContentRef] = useState(null);
    const [searchValue, setSearchValue] = useState('');

    const setLoadGoogleScript = useSetRecoilState(loadGoogleScriptState);
    const setSearchForceUpdate = useSetRecoilState(searchForceUpdateState);

    const dummyRef = useRef(null);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const param = searchParamsConstructor(location.search, ['searched'], 'get');
        urlMode && setSearchValue(param.searched || '');
    }, [location.search, urlMode]);

    const placeholder = translate('Search your city...');

    const handleOnChange = (predictions, status) => status === 'OK' && setAutocompleteData(predictions.map(({ structured_formatting, types, reference }) => ({
        mainText: structured_formatting.main_text,
        secondaryText: structured_formatting.secondary_text,
        icons: `icon-${types?.join(' icon-')}`,
        id: reference,
    })));

    const onSuggestionSelected = suggestion => {
        const params = {
            reference: suggestion.id,
            fields: ['geometry'],
            sessionToken: new window.google.maps.places.AutocompleteSessionToken(),
        };
        const placesService = new window.google.maps.places.PlacesService(dummyRef.current);
        placesService.getDetails(params, (details, status) => {
            if (status === 'OK') {
                const location = details.geometry.viewport.toJSON();
                setLSItem('lastSearchedQuery', suggestion.mainText);
                navigate(urlConstruct({
                    pathname: '/s',
                    search: searchParamsConstructor(
                        location.search,
                        {
                            east: location.east,
                            north: location.north,
                            south: location.south,
                            west: location.west,
                            searched: suggestion.mainText,
                        },
                        'set'
                    ),
                }));
                setTimeout(() => {
                    setSearchForceUpdate(guid());
                }, 100);
            }
        });
    };

    const onSuggestionsFetchRequested = value => {
        const city = getLSItem(geoLocationKey)?.data?.city;
        if (!value && city) {
            value = city;
        }
        const params = {
            input: value,
            sessionToken: new window.google.maps.places.AutocompleteSessionToken(),
        };
        !!value.length && new window.google.maps.places.AutocompleteService().getQueryPredictions(params, (predictions, status) => handleOnChange(predictions, status));
    };

    const renderSuggestion = (suggestion, isHighlighted) => (
        <Option
            icon={{
                type: `icon-map-marker ${suggestion.icons}`,
                size: 22,
                multiColor: true,
            }}
            size='big'
            key={suggestion.id}
            name={suggestion.mainText}
            highlighted={isHighlighted}
            information={suggestion.secondaryText}
        />
    );

    const inputProps = {
        value: searchValue,
        onChange: (_, { newValue }) => {
            setSearchValue(newValue);
        }
    };

    const renderInputComponent = props => {
        const {
            ref,
            value,
            onBlur,
            onFocus,
            onChange,
            onKeyDown,
        } = props;

        return (
            <Popover
                minHeight={386}
                selfSizing={false}
                key={location.search}
                scrollerRef={ref => setContentRef(ref)}
                {...conditionalSpread({
                    opened: !!autocompleteData?.length,
                }, !isMobile)}
                onClose={() => {
                    if (isMobile) {
                        setSearchValue('');
                        setAutocompleteData([]);
                    }
                }}
                {...conditionalSpread({
                    header: (
                        <Search
                            size='medium'
                            value={value}
                            autoFocus={!isMobile}
                            appearance='inline'
                            placeholder={placeholder}
                            ref={val => ref(val)}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                        />
                    ),
                    content: !autocompleteData.length && (
                        <NoData
                            size='small'
                            type='search'
                            title={
                                <Translate>Type to search...</Translate>
                            }
                            subTitle={
                                <Translate>try anything, city, street or a shopping mall</Translate>
                            }
                        />
                    ),
                }, isMobile)}
            >
                <div onMouseEnter={() => setLoadGoogleScript(true)}>
                    {appearance === 'icon' ? (
                        <Button
                            cornerRadius='full'
                            color='grayscale'
                            ariaLabel={translate('Location Search')}
                            icon={{
                                size: 22,
                                type: 'icon-a-search',
                            }}
                        />
                    ) : (
                        <Search
                            size={size}
                            value={value}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onChange={onChange}
                            readOnly={isMobile}
                            ref={val => ref(val)}
                            onKeyDown={onKeyDown}
                            appearance={appearance}
                            placeholder={placeholder}
                            cornerRadius={cornerRadius}
                        />
                    )}
                </div>
            </Popover>
        );
    };

    const renderSuggestionsContainer = (containerProps, children) => {
        containerProps.ref(contentRef);
        return (contentRef && children) && createPortal(children, contentRef);
    };

    return (
        <>
            <Autosuggest
                inputProps={inputProps}
                alwaysRenderSuggestions={isMobile}
                focusInputOnSuggestionClick={!isMobile}
                highlightFirstSuggestion={!isMobile}
                suggestions={autocompleteData}
                getSuggestionValue={suggestion => suggestion.mainText}
                renderInputComponent={props => renderInputComponent(props)}
                onSuggestionsClearRequested={() => !isMobile && setAutocompleteData([])}
                onSuggestionSelected={(_, { suggestion }) => !!window.google && onSuggestionSelected(suggestion)}
                onSuggestionsFetchRequested={({ value }) => !!window.google && onSuggestionsFetchRequested(value)}
                renderSuggestion={(suggestion, { isHighlighted }) => renderSuggestion(suggestion, isHighlighted)}
                renderSuggestionsContainer={({ containerProps, children }) => renderSuggestionsContainer(containerProps, children)}
            />
            <div className='absolute-hidden' ref={dummyRef} />
        </>
    );
};

LocationSearch.propTypes = locationSearchProps;

export default LocationSearch;
