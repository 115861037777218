import { fakeArray } from 'clyne-core';
import PropTypes from 'prop-types';
import React from 'react';

import To from '../to';
import Icon from '../icon';
import Translate from '../translate';

import './index.scss';

const IconLabelInsetCard = props => {
    const {
        url,
        icon,
        children,
        skeleton,
    } = props;

    return (
        <div className='i-label-inset-card'>
            <Icon
                size={32}
                type={icon}
                skeleton={skeleton}
            />
            {skeleton ? (
                <div className='skeleton-group'>
                    {fakeArray(2).map((_, index) => (
                        <div
                            key={index}
                            className='card-text-skeleton skeleton-wave'
                        />
                    ))}
                </div>
            ) : (
                <p>
                    <Translate>{children}</Translate>
                </p>
            )}
            {!!url && (
                <To
                    url={url}
                    className='absolute-splash'
                />
            )}
        </div>
    );
};

IconLabelInsetCard.propTypes = {
    url: PropTypes.string,
    icon: PropTypes.string,
    children: PropTypes.any,
    skeleton: PropTypes.bool,
};

export default IconLabelInsetCard;
