import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import React from 'react';

import { genericState, languageState } from '../../state';

const getMetricUnit = (number, code) => {
    if (number < 0) {
        return '0';
    }

    if (number < 0.01) {
        const postFix = code === 'hy' ? 'մմ' : code === 'ru' ? 'мм' : code === 'ka' ? 'მმ' : 'mm';
        return `${(number * 1000)}${postFix}`;
    } else if (number < 1) {
        const postFix = code === 'hy' ? 'սմ' : code === 'ru' ? 'см' : code === 'ka' ? 'სმ' : 'cm';
        return `${(number * 100)}${postFix}`;
    }
    const postFix = code === 'hy' ? 'մ' : code === 'ru' ? 'м' : code === 'ka' ? 'მ' : 'm';

    return `${number}${postFix}`;
};

const Metrics = props => {
    const {
        typeID,
        placementID,
        physicalWidth,
        physicalHeight,
    } = props;

    const generic = useRecoilValue(genericState);
    const language = useRecoilValue(languageState);

    const physicalSize = `${getMetricUnit(physicalWidth, language?.code)} x ${getMetricUnit(physicalHeight, language?.code)}`;

    return (
        <>
            {(!!physicalWidth && !!physicalWidth) && physicalSize}
            {!!typeID && (
                `${generic?.screenTypes?.find(type => type.id === typeID)?.name}`
            )}
            {!!placementID && (
                `${generic?.placements?.find(placement => placement.id === placementID)?.name}`
            )}
        </>
    );
};

Metrics.propTypes = {
    typeID: PropTypes.number,
    placementID: PropTypes.number,
    physicalWidth: PropTypes.number,
    physicalHeight: PropTypes.number,
};

export default Metrics;
