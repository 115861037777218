import { toProps } from 'clyne-core';
import PropTypes from 'prop-types';

import { uiKitCornerRadius } from '../../props';

export const tagConfig = {
    size: [
        'small',
        'default',
        'medium',
        'big',
        'extra-small',
        'semi-small',
    ],
    appearance: [
        'fill',
        'outline',
    ],
    cornerRadius: uiKitCornerRadius,
    color: [
        'default',
        'brand',
        'error',
        'warning',
        'success',
        'base',
        'grey',
        'complimentary',
    ],
    itemsDirection: [
        'start',
        'end',
    ],
};

export const tagProps = {
    url: PropTypes.string,
    size: PropTypes.oneOf(tagConfig.size),
    name: PropTypes.any,
    title: PropTypes.string,
    icon: PropTypes.shape({
        size: PropTypes.number,
        type: PropTypes.string,
    }),
    color: PropTypes.oneOf(tagConfig.color),
    times: PropTypes.number,
    target: PropTypes.oneOf(toProps.target),
    tooltip: PropTypes.any,
    onClick: PropTypes.func,
    appearance: PropTypes.oneOf(tagConfig.appearance),
    cornerRadius: PropTypes.oneOf(tagConfig.cornerRadius),
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    itemsDirection: PropTypes.oneOf(tagConfig.itemsDirection),
};
