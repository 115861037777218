import Translate from '../components/translate';

const interestsDataMapping = {
    artsAndCulture: {
        icon: 'icon-palette',
        label: (
            <>
                <Translate>Arts</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>Culture</Translate>
            </>
        ),
    },
    businessAndTechnology: {
        icon: 'icon-briefcase',
        label: (
            <>
                <Translate>Business</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>Technology</Translate>
            </>
        ),
    },
    educationAndLearning: {
        icon: 'icon-book-open',
        label: (
            <>
                <Translate>Education</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>Learning</Translate>
            </>
        ),
    },
    entertainmentAndRecreation: {
        icon: 'icon-film-board',
        label: (
            <>
                <Translate>Entertainment</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>Recreation</Translate>
            </>
        ),
    },
    healthAndFitness: {
        icon: 'icon-heart-pulse',
        label: (
            <>
                <Translate>Health</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>Fitness</Translate>
            </>
        ),
    },
    foodAndDrink: {
        icon: 'icon-cutlery',
        label: (
            <>
                <Translate>Food</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>Drink</Translate>
            </>
        ),
    },
    homeAndGarden: {
        icon: 'icon-home-3',
        label: (
            <>
                <Translate>Home</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>Garden</Translate>
            </>
        ),
    },
    familyAndParenting: {
        icon: 'icon-baby-carriage',
        label: (
            <>
                <Translate>Family</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>Parenting</Translate>
            </>
        ),
    },
    relationshipsAndDating: {
        icon: 'icon-heart',
        label: (
            <>
                <Translate>Relationships</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>Dating</Translate>
            </>
        ),
    },
    socialCausesAndActivism: {
        icon: 'icon-glass-cocktail',
        label: (
            <>
                <Translate>Social Causes</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>Activism</Translate>
            </>
        ),
    },
    petsAndAnimals: {
        icon: Math.random() < 0.5 ? 'icon-cat' : 'icon-dog',
        label: (
            <>
                <Translate>Pets</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>Animals</Translate>
            </>
        ),
    },
    hobbiesAndActivities: {
        icon: 'icon-dumbell',
        label: (
            <>
                <Translate>Hobbies</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>Activities</Translate>
            </>
        ),
    },
    clothingAndAccessories: {
        icon: 'icon-dress',
        label: (
            <>
                <Translate>Clothing</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>Accessories</Translate>
            </>
        ),
    },
    electronics: {
        icon: 'icon-devices',
        label: (
            <>
                <Translate>Electronics</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>Gadgets</Translate>
            </>
        ),
    },
    groceriesAndFood: {
        icon: 'icon-organic-food',
        label: (
            <>
                <Translate>Groceries</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>Food</Translate>
            </>
        ),
    },
    beautyAndPersonalCare: {
        icon: 'icon-wellness',
        label: (
            <>
                <Translate>Beauty</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>Personal Care</Translate>
            </>
        ),
    },
    sportsEquipment: {
        icon: 'icon-equipment-gym',
        label: (
            <>
                <Translate>Sports</Translate>
                <br />
                <Translate>Equipment</Translate>
            </>
        ),
    },
    automotive: {
        icon: 'icon-solar-energy',
        label: (
            <>
                <Translate>Automotive</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>Engineering</Translate>
            </>
        ),
    },
    healthAndWellness: {
        icon: 'icon-healtcare',
        label: (
            <>
                <Translate>Health</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>Wellness</Translate>
            </>
        ),
    },
    furniture: {
        icon: 'icon-sofa',
        label: (
            <>
                <Translate>Furniture</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>DIY</Translate>
            </>
        ),
    },
    toysAndGames: {
        icon: 'icon-game-controller',
        label: (
            <>
                <Translate>Toys</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>Games</Translate>
            </>
        ),
    },
    travelAndTourism: {
        icon: 'icon-airplane',
        label: (
            <>
                <Translate>Travel</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>Tourism</Translate>
            </>
        ),
    },
    luxuryAndLifestyle: {
        icon: 'icon-necklace',
        label: (
            <>
                <Translate>Luxury</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>Lifestyle</Translate>
            </>
        ),
    },
    fashionAndApparel: {
        icon: 'icon-clothes',
        label: (
            <>
                <Translate>Fashion</Translate>
                <br />
                <Translate>and</Translate>
                {` `}
                <Translate>Apparel</Translate>
            </>
        ),
    },
};

export default interestsDataMapping;
