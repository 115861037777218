import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../icon';

import './index.scss';

const iconMapping = {
    error: 'icon-a-alert-triangle',
    info: 'icon-a-info',
};

const Information = props => {
    const {
        children,
        className,
        type = 'error',
        appearance = 'box',
    } = props;

    return (
        <div
            className={classNames(
                `information-holder`,
                `t-${type}`,
                `a-${appearance}`,
                className,
            )}
        >
            {appearance !== 'inline' && (
                <Icon type={iconMapping[type]} />
            )}
            <div className='information-content'>
                {children}
            </div>
        </div>
    );
};

Information.propTypes = {
    type: PropTypes.oneOf([
        'error',
        'info',
    ]),
    children: PropTypes.any,
    className: PropTypes.string,
    appearance: PropTypes.oneOf([
        'box',
        'inline',
        'outline',
        'light',
    ]),
};

export default Information;
