import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';

import { getCurrencySymbol, getCurrentCurrency, getIntlLocales } from '../helpers';

import { preciseLocationState, userState, workspaceModeState } from '../state';

const usePrice = (number, condition = true, postfix = '') => {
    const user = useRecoilValue(userState);
    const workspaceMode = useRecoilValue(workspaceModeState);
    const preciseLocation = useRecoilValue(preciseLocationState);

    const locales = getIntlLocales({
        user,
        preciseLocation,
    });

    const currency = getCurrentCurrency({
        user,
        workspaceMode,
        preciseLocation,
    });

    const currencySymbol = getCurrencySymbol({
        user,
        workspaceMode,
        preciseLocation,
    });

    const formatPrice = number => {
        if (isNaN(number) || !currency) {
            return number;
        }

        switch (currency) {
            case 'RUB':
            case 'GEL':
            case 'AMD':
                return `${new Intl.NumberFormat().format(number)}${postfix} ${currencySymbol?.symbol}`;
            default:
                return `${new Intl.NumberFormat(locales, {
                    currency,
                    style: 'currency',
                }).format(number)}${postfix}`;
        }
    };

    const price = useMemo(() => `${formatPrice(number)}`.replace(/\D00(?=\D*$)/, ''), [locales, number, currency, postfix]); // eslint-disable-line

    return condition ? price : number;
};

export default usePrice;
