import React, { Fragment, memo, Suspense, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLocation } from 'react-router-dom';

import Guide from '../../components/guide';
import Footer from '../../components/footer';
import Header from '../../components/header';
import Helmet from '../../components/helmet';
import Loader from '../../components/loader';
import Toaster from '../../components/toaster';
import AppUrlListener from '../../components/appUrlListener';
import SharedComponents from '../../components/sharedComponents';
import landingRouteURLs from '../../constants/landingRouteURLs';

import lazyWithRetry from '../../utils/lazyWithRetry';
import getRouteFromURL from '../../utils/getRouteFromURL';

import { crState, loadToasterState } from '../../state';

import Home from '../home';

import useDevice from '../../hooks/useDevice';

const Routing = lazyWithRetry(() => import('../routing').then());

const Layout = memo(function Layout() {
    const location = useLocation();

    const [cr, setCR] = useRecoilState(crState);
    const loadToaster = useRecoilValue(loadToasterState);

    useEffect(() => {
        setCR(getRouteFromURL(location.pathname));
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, [location.pathname, location.search, location.hash]); // eslint-disable-line

    const {
        isMobile,
    } = useDevice();

    return (
        <>
            <Helmet />
            <AppUrlListener />
            <Header />
            <main>
                {landingRouteURLs.includes(location.pathname) ? (
                    <Guide>
                        <Home />
                    </Guide>
                ) : (
                    <Suspense fallback={<Loader absolute />}>
                        <Routing />
                    </Suspense>
                )}
            </main>
            {(cr.footer && cr.footer(isMobile)) && (
                <Footer />
            )}
            {loadToaster && (
                <Toaster />
            )}
            <SharedComponents />
        </>
    );
});

export default Layout;
