import { conditionalSpread } from 'clyne-core';
import React, { Fragment } from 'react';
import classNames from 'classnames';

import To from '../to';
import Icon from '../icon';
import Button from '../button';
import Avatar from '../avatar';
import Popover from '../popover';
import Translate from '../translate';

import { isWhiteLabel, removeLSItem } from '../../helpers';

import translate from '../../utils/translate';
import getRoutesBySlug from '../../utils/getRoutesBySlug';
import checkRouteMatchByURL from '../../utils/checkRouteMatchByURL';
import cleanURLFromLanguage from '../../utils/cleanURLFromLanguage';

import { userRoles } from '../../constants/construct';
import { PROFILE, URLS } from '../../constants/apiKeys';

import authService from '../../services/authService';
import connectionService from '../../services/connectionService';

import { userRoutes, userRoutesData } from '../../containers/user/data';
import { authenticationRoutes } from '../../containers/authentication/data';

const popoverProps = {
    sticky: true,
    position: 'bottom-end',
    offsetBounding: [0, 25],
    maxItems: 3,
    itemHeight: 86,
};

export const headerNavigation = props => {
    const {
        user,
        isMobile,
        pathname,
        navigation,
        setNavigation,
        setMobileNavigationOpened,
    } = props;

    const routeContentRenderer = route => (
        <>
            {isMobile && (
                <Icon
                    size={22}
                    type={`${route.icon}${route.active ? '-active' : ''}`}
                    className='route-icon'
                />
            )}
            <span className='pointer-events-none'>{translate(route.name)}</span>
            {(isMobile || !!route.subMenu) && (
                <Icon
                    size={isMobile ? 24 : 18}
                    type={isMobile ? 'icon-a-chevron-right' : 'icon-a-chevron-down'}
                />
            )}
        </>
    );

    return (
        <nav
            className={classNames(
                {
                    'reduce-gap': navigation?.length > 4,
                }
            )}
        >
            {navigation.map(route => {
                const subMenu = route?.subMenu?.filter(route => user?.sa ? true : !route.superAdminOnly);

                return (
                    <Fragment key={route.url}>
                        {subMenu ? (
                            <div
                                className={classNames(
                                    'sub-menu-holder',
                                    {
                                        'opened': route.opened,
                                    }
                                )}
                            >
                                <div
                                    className={classNames(
                                        `route`,
                                        {
                                            'active': subMenu.some(route => checkRouteMatchByURL(route.url, pathname)),
                                        }
                                    )}
                                    onClick={() => setNavigation(val => val.map(item => ({ ...item, opened: item.url === route.url ? !item.opened : false })))}
                                >
                                    {routeContentRenderer({ ...route, active: subMenu.some(route => checkRouteMatchByURL(route.url, pathname)) })}
                                </div>
                                <div
                                    className='route-submenu-positioner'
                                    style={{
                                        '--items-count': subMenu.length,
                                    }}
                                >
                                    <ul>
                                        {subMenu.map((route, index) => (
                                            <li key={index}>
                                                <To
                                                    url={route.url}
                                                    onClick={route.onClick}
                                                    className={classNames({
                                                        'route': isMobile,
                                                    })}
                                                    onTap={() => setMobileNavigationOpened(false)}
                                                >
                                                    <span>{translate(route.name)}</span>
                                                </To>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            <To
                                url={route?.externalOnWhiteLabel && isWhiteLabel ? `${process.env.REACT_APP_FRONTEND_URL}${route.url}` : route.url}
                                className='route'
                                onClick={route.onClick}
                                onTap={e => {
                                    setMobileNavigationOpened(false);
                                    !!route.onTap && route.onTap(e);
                                }}
                                {...conditionalSpread({ href: route.url }, !route.component)}
                                {...conditionalSpread({
                                    target: '_blank',
                                }, route?.externalOnWhiteLabel && isWhiteLabel)}
                            >
                                <Icon
                                    size={16}
                                    type='icon-a-loader'
                                />
                                {routeContentRenderer({ ...route, active: Array.isArray(route.url) ? route.url.includes(cleanURLFromLanguage(pathname)) : checkRouteMatchByURL(route.url, pathname) })}
                            </To>
                        )}
                    </Fragment>
                );
            })}
        </nav>
    );
};

export const headerAuthentication = props => {
    const {
        isMobile,
    } = props;

    const sharedProps = {
        size: isMobile ? 'big' : 'medium',
        flexibility: isMobile ? 'full' : 'fit',
    };

    return (
        <div className='header-cta'>
            <Button
                color='grayscale'
                {...sharedProps}
                to={authenticationRoutes.signIn}
            >
                <Translate>Sign In</Translate>
            </Button>
            <Button
                {...conditionalSpread({
                    hero: 'pureWhite',
                }, isMobile)}
                {...sharedProps}
                to={authenticationRoutes.signUp}
            >
                <Translate>Sign Up</Translate>
            </Button>
        </div>
    );
};

export const headerUserProfile = props => {
    const {
        user,
        setUser,
        userRole,
        isMobile,
        navigate,
        workspaceMode,
        locationConfig,
        firstTimePartner,
        setWorkspaceMode,
    } = props;

    const userRoutesMenu = ([
        ...userRoutesData.map(route => !user?.verified && route.url === userRoutes?.personalInformation ? ({
            ...route,
            badge: true,
        }) : route),
        {
            divider: true,
        },
        {
            icon: 'icon-a-nut',
            name: <Translate>Application Settings</Translate>,
            children: locationConfig,
        },
        {
            divider: true,
        },
        {
            name: <Translate>Log Out</Translate>,
            icon: 'icon-a-sign-out',
            onClick: () => {
                navigate('/');
                removeLSItem('droppedFrom');
                authService.signOut();
            },
        },
    ]);

    const handleWorkspaceChange = () => {
        setWorkspaceMode(val => !val);
        firstTimePartner && connectionService.putJson(URLS[PROFILE], {
            onboarding: {
                firstTimePartner: true,
            },
        }).subscribe(res => setUser(val => ({
            ...val,
            onboarding: res?.data?.data?.onboarding || val?.onboarding || {},
        })));
        navigate(workspaceMode ? '/' : getRoutesBySlug(['wpDashboard'])?.[0]?.url, { replace: true });
    };

    return (
        <Popover
            {...popoverProps}
            maxItems={10}
            menu={[
                ...conditionalSpread([
                    {
                        badge: firstTimePartner,
                        avatar: (
                            <Avatar
                                size='small'
                                fallbackIcon={workspaceMode ? 'icon-a-user' : 'icon-a-users'}
                                url={workspaceMode ? user?.avatarURL : user?.workspace?.avatarURL}
                            />
                        ),
                        onClick: handleWorkspaceChange,
                        name: workspaceMode ? (
                            <Translate>Switch to Personal</Translate>
                        ) : (
                            <Translate replaceMap={{ '_NAME_': user?.workspace?.name }}>Switch to _NAME_</Translate>
                        ),
                    },
                    { divider: true },
                ], userRole === userRoles.partner),
                ...userRoutesMenu,
            ]}
            offsetBounding={[0, 20]}
        >
            <Avatar
                {...conditionalSpread({
                    onDoubleClick: handleWorkspaceChange,
                }, userRole === userRoles.partner)}
                size={isMobile ? 'medium' : 'big'}
                {...conditionalSpread({
                    badge: !user?.verified || firstTimePartner,
                }, !!Object.keys(user || {}).length)}
                fallbackIcon={workspaceMode ? 'icon-a-users' : 'icon-a-user'}
                url={workspaceMode ? user?.workspace?.avatarURL : user?.avatarURL}
            />
        </Popover>
    );
};

export const headerLocationButton = props => {
    const {
        locationConfig,
        selectedLanguage,
    } = props;

    return (
        <Popover
            {...popoverProps}
            maxItems={10}
            offsetBounding={[0, 20]}
            position='bottom'
            menu={locationConfig}
        >
            <div className='language-switcher-flag-holder'>
                <Icon
                    flag={selectedLanguage.flag}
                />
            </div>
        </Popover>
    );
};
