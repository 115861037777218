import PropTypes from 'prop-types';

import { popperConfig } from '../../props';

export const popoverConfig = {
    position: popperConfig.position,
};

export const popoverProps = {
    menu: PropTypes.array,
    width: PropTypes.number,
    header: PropTypes.any,
    footer: PropTypes.any,
    opened: PropTypes.bool,
    sticky: PropTypes.bool,
    onOpen: PropTypes.func,
    module: PropTypes.shape({
        header: PropTypes.shape({
            title: PropTypes.any,
            subTitle: PropTypes.any,
            cta: PropTypes.object,
        }),
        footer: PropTypes.shape({
            cta: PropTypes.any,
        }),
    }),
    onClose: PropTypes.func,
    content: PropTypes.any,
    padding: PropTypes.bool,
    loading: PropTypes.bool,
    maxItems: PropTypes.number,
    children: PropTypes.any,
    position: PropTypes.oneOf(popoverConfig.position),
    headerRef: PropTypes.func,
    className: PropTypes.string,
    minHeight: PropTypes.number,
    passState: PropTypes.bool,
    itemHeight: PropTypes.number,
    selfSizing: PropTypes.bool,
    scrollerRef: PropTypes.func,
    onCloseState: PropTypes.func,
    onContentClick: PropTypes.func,
    onClickOutside: PropTypes.func,
    offsetBounding: PropTypes.arrayOf(PropTypes.number),
    overflowPadding: PropTypes.number,
    closeOnMenuClick: PropTypes.bool,
};
