import { conditionalSpread } from 'clyne-core';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import translate from '../../utils/translate';

import { subDomain } from '../../helpers';

import whiteLabel from '../../constants/whiteLabel';

import { ReactComponent as LogoSVG } from './media/logo.svg';
import { ReactComponent as BBMediaLogoSVG } from './media/bbmedia.svg';
import { ReactComponent as ArbaMediaLogoSVG } from './media/arbamedia.svg';

import './index.scss';

const logoMapping = {
    bbmedia: <BBMediaLogoSVG />,
    arbamedia: <ArbaMediaLogoSVG />,
};

const Logo = props => {
    const {
        og,
    } = props;

    const sharedProps = {
        className: classNames(
            'logo',
            {
                og,
            }
        ),
        'aria-label': translate('Go to Homepage'),
    };

    return og ? (
        <a
            href={process.env.REACT_APP_FRONTEND_URL}
            target='_blank'
            rel='noreferrer'
            {...sharedProps}
        >
            <LogoSVG />
        </a>
    ) : (
        <NavLink
            to='/'
            {...sharedProps}
            style={{
                ...conditionalSpread({
                    '--width': `${whiteLabel?.logo?.width}px`,
                }, whiteLabel?.logo?.width && !og),

                ...conditionalSpread({
                    '--height': `${whiteLabel?.logo?.height}px`,
                }, whiteLabel?.logo?.height && !og),
            }}
        >
            {logoMapping[subDomain] || (
                <LogoSVG />
            )}
        </NavLink>
    );
};

Logo.propTypes = {
    og: PropTypes.bool,
};

export default Logo;
