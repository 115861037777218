import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import useDevice from '../../hooks/useDevice';

import { crState } from '../../state';

import './index.scss';

const Guide = props => {
    const {
        middle,
        children,
        enabled = true,
        padding = true,
    } = props;

    const { isMobile } = useDevice();

    const cr = useRecoilValue(crState);

    return enabled ? (
        <div
            className={classNames(
                `guide`,
                {
                    padding,
                    'fit': cr?.guide?.fit && !isMobile,
                    'rubber': cr?.guide?.rubber,
                    'middle': cr?.guide?.middle || middle,
                }
            )}
        >
            {children}
        </div>
    ) : children;
};

Guide.propTypes = {
    middle: PropTypes.bool,
    enabled: PropTypes.bool,
    padding: PropTypes.bool,
    children: PropTypes.any,
};

export default Guide;
