import step1ArmDesktopDark from './media/step1ArmDesktopDark.jpg';
import step1ArmDesktopDark2x from './media/step1ArmDesktopDark@2x.jpg';
import step1ArmDesktopLight from './media/step1ArmDesktopLight.jpg';
import step1ArmDesktopLight2x from './media/step1ArmDesktopLight@2x.jpg';
import step1ArmMobileDark from './media/step1ArmMobileDark.jpg';
import step1ArmMobileDark2x from './media/step1ArmMobileDark@2x.jpg';
import step1ArmMobileLight from './media/step1ArmMobileLight.jpg';
import step1ArmMobileLight2x from './media/step1ArmMobileLight@2x.jpg';
import step1EngDesktopDark from './media/step1EngDesktopDark.jpg';
import step1EngDesktopDark2x from './media/step1EngDesktopDark@2x.jpg';
import step1EngDesktopLight from './media/step1EngDesktopLight.jpg';
import step1EngDesktopLight2x from './media/step1EngDesktopLight@2x.jpg';
import step1EngMobileDark from './media/step1EngMobileDark.jpg';
import step1EngMobileDark2x from './media/step1EngMobileDark@2x.jpg';
import step1EngMobileLight from './media/step1EngMobileLight.jpg';
import step1EngMobileLight2x from './media/step1EngMobileLight@2x.jpg';
import step1RusDesktopDark from './media/step1RusDesktopDark.jpg';
import step1RusDesktopDark2x from './media/step1RusDesktopDark@2x.jpg';
import step1RusDesktopLight from './media/step1RusDesktopLight.jpg';
import step1RusDesktopLight2x from './media/step1RusDesktopLight@2x.jpg';
import step1RusMobileDark from './media/step1RusMobileDark.jpg';
import step1RusMobileDark2x from './media/step1RusMobileDark@2x.jpg';
import step1RusMobileLight from './media/step1RusMobileLight.jpg';
import step1RusMobileLight2x from './media/step1RusMobileLight@2x.jpg';
import step2ArmDesktopDark from './media/step2ArmDesktopDark.jpg';
import step2ArmDesktopDark2x from './media/step2ArmDesktopDark@2x.jpg';
import step2ArmDesktopLight from './media/step2ArmDesktopLight.jpg';
import step2ArmDesktopLight2x from './media/step2ArmDesktopLight@2x.jpg';
import step2ArmMobileDark from './media/step2ArmMobileDark.jpg';
import step2ArmMobileDark2x from './media/step2ArmMobileDark@2x.jpg';
import step2ArmMobileLight from './media/step2ArmMobileLight.jpg';
import step2ArmMobileLight2x from './media/step2ArmMobileLight@2x.jpg';
import step2EngDesktopDark from './media/step2EngDesktopDark.jpg';
import step2EngDesktopDark2x from './media/step2EngDesktopDark@2x.jpg';
import step2EngDesktopLight from './media/step2EngDesktopLight.jpg';
import step2EngDesktopLight2x from './media/step2EngDesktopLight@2x.jpg';
import step2EngMobileDark from './media/step2EngMobileDark.jpg';
import step2EngMobileDark2x from './media/step2EngMobileDark@2x.jpg';
import step2EngMobileLight from './media/step2EngMobileLight.jpg';
import step2EngMobileLight2x from './media/step2EngMobileLight@2x.jpg';
import step2RusDesktopDark from './media/step2RusDesktopDark.jpg';
import step2RusDesktopDark2x from './media/step2RusDesktopDark@2x.jpg';
import step2RusDesktopLight from './media/step2RusDesktopLight.jpg';
import step2RusDesktopLight2x from './media/step2RusDesktopLight@2x.jpg';
import step2RusMobileDark from './media/step2RusMobileDark.jpg';
import step2RusMobileDark2x from './media/step2RusMobileDark@2x.jpg';
import step2RusMobileLight from './media/step2RusMobileLight.jpg';
import step2RusMobileLight2x from './media/step2RusMobileLight@2x.jpg';
import step3ArmDesktopDark from './media/step3ArmDesktopDark.jpg';
import step3ArmDesktopDark2x from './media/step3ArmDesktopDark@2x.jpg';
import step3ArmDesktopLight from './media/step3ArmDesktopLight.jpg';
import step3ArmDesktopLight2x from './media/step3ArmDesktopLight@2x.jpg';
import step3ArmMobileDark from './media/step3ArmMobileDark.jpg';
import step3ArmMobileDark2x from './media/step3ArmMobileDark@2x.jpg';
import step3ArmMobileLight from './media/step3ArmMobileLight.jpg';
import step3ArmMobileLight2x from './media/step3ArmMobileLight@2x.jpg';
import step3EngDesktopDark from './media/step3EngDesktopDark.jpg';
import step3EngDesktopDark2x from './media/step3EngDesktopDark@2x.jpg';
import step3EngDesktopLight from './media/step3EngDesktopLight.jpg';
import step3EngDesktopLight2x from './media/step3EngDesktopLight@2x.jpg';
import step3EngMobileDark from './media/step3EngMobileDark.jpg';
import step3EngMobileDark2x from './media/step3EngMobileDark@2x.jpg';
import step3EngMobileLight from './media/step3EngMobileLight.jpg';
import step3EngMobileLight2x from './media/step3EngMobileLight@2x.jpg';
import step3RusDesktopDark from './media/step3RusDesktopDark.jpg';
import step3RusDesktopDark2x from './media/step3RusDesktopDark@2x.jpg';
import step3RusDesktopLight from './media/step3RusDesktopLight.jpg';
import step3RusDesktopLight2x from './media/step3RusDesktopLight@2x.jpg';
import step3RusMobileDark from './media/step3RusMobileDark.jpg';
import step3RusMobileDark2x from './media/step3RusMobileDark@2x.jpg';
import step3RusMobileLight from './media/step3RusMobileLight.jpg';
import step3RusMobileLight2x from './media/step3RusMobileLight@2x.jpg';
import step4ArmDesktopDark from './media/step4ArmDesktopDark.jpg';
import step4ArmDesktopDark2x from './media/step4ArmDesktopDark@2x.jpg';
import step4ArmDesktopLight from './media/step4ArmDesktopLight.jpg';
import step4ArmDesktopLight2x from './media/step4ArmDesktopLight@2x.jpg';
import step4ArmMobileDark from './media/step4ArmMobileDark.jpg';
import step4ArmMobileDark2x from './media/step4ArmMobileDark@2x.jpg';
import step4ArmMobileLight from './media/step4ArmMobileLight.jpg';
import step4ArmMobileLight2x from './media/step4ArmMobileLight@2x.jpg';
import step4EngDesktopDark from './media/step4EngDesktopDark.jpg';
import step4EngDesktopDark2x from './media/step4EngDesktopDark@2x.jpg';
import step4EngDesktopLight from './media/step4EngDesktopLight.jpg';
import step4EngDesktopLight2x from './media/step4EngDesktopLight@2x.jpg';
import step4EngMobileDark from './media/step4EngMobileDark.jpg';
import step4EngMobileDark2x from './media/step4EngMobileDark@2x.jpg';
import step4EngMobileLight from './media/step4EngMobileLight.jpg';
import step4EngMobileLight2x from './media/step4EngMobileLight@2x.jpg';
import step4RusDesktopDark from './media/step4RusDesktopDark.jpg';
import step4RusDesktopDark2x from './media/step4RusDesktopDark@2x.jpg';
import step4RusDesktopLight from './media/step4RusDesktopLight.jpg';
import step4RusDesktopLight2x from './media/step4RusDesktopLight@2x.jpg';
import step4RusMobileDark from './media/step4RusMobileDark.jpg';
import step4RusMobileDark2x from './media/step4RusMobileDark@2x.jpg';
import step4RusMobileLight from './media/step4RusMobileLight.jpg';
import step4RusMobileLight2x from './media/step4RusMobileLight@2x.jpg';
import step5ArmDesktopDark from './media/step5ArmDesktopDark.jpg';
import step5ArmDesktopDark2x from './media/step5ArmDesktopDark@2x.jpg';
import step5ArmDesktopLight from './media/step5ArmDesktopLight.jpg';
import step5ArmDesktopLight2x from './media/step5ArmDesktopLight@2x.jpg';
import step5ArmMobileDark from './media/step5ArmMobileDark.jpg';
import step5ArmMobileDark2x from './media/step5ArmMobileDark@2x.jpg';
import step5ArmMobileLight from './media/step5ArmMobileLight.jpg';
import step5ArmMobileLight2x from './media/step5ArmMobileLight@2x.jpg';
import step5EngDesktopDark from './media/step5EngDesktopDark.jpg';
import step5EngDesktopDark2x from './media/step5EngDesktopDark@2x.jpg';
import step5EngDesktopLight from './media/step5EngDesktopLight.jpg';
import step5EngDesktopLight2x from './media/step5EngDesktopLight@2x.jpg';
import step5EngMobileDark from './media/step5EngMobileDark.jpg';
import step5EngMobileDark2x from './media/step5EngMobileDark@2x.jpg';
import step5EngMobileLight from './media/step5EngMobileLight.jpg';
import step5EngMobileLight2x from './media/step5EngMobileLight@2x.jpg';
import step5RusDesktopDark from './media/step5RusDesktopDark.jpg';
import step5RusDesktopDark2x from './media/step5RusDesktopDark@2x.jpg';
import step5RusDesktopLight from './media/step5RusDesktopLight.jpg';
import step5RusDesktopLight2x from './media/step5RusDesktopLight@2x.jpg';
import step5RusMobileDark from './media/step5RusMobileDark.jpg';
import step5RusMobileDark2x from './media/step5RusMobileDark@2x.jpg';
import step5RusMobileLight from './media/step5RusMobileLight.jpg';
import step5RusMobileLight2x from './media/step5RusMobileLight@2x.jpg';
import step1ArmDesktopDarkWEBP from './media/step1ArmDesktopDark.webp';
import step1ArmDesktopDark2xWEBP from './media/step1ArmDesktopDark@2x.webp';
import step1ArmDesktopLightWEBP from './media/step1ArmDesktopLight.webp';
import step1ArmDesktopLight2xWEBP from './media/step1ArmDesktopLight@2x.webp';
import step1ArmMobileDarkWEBP from './media/step1ArmMobileDark.webp';
import step1ArmMobileDark2xWEBP from './media/step1ArmMobileDark@2x.webp';
import step1ArmMobileLightWEBP from './media/step1ArmMobileLight.webp';
import step1ArmMobileLight2xWEBP from './media/step1ArmMobileLight@2x.webp';
import step1EngDesktopDarkWEBP from './media/step1EngDesktopDark.webp';
import step1EngDesktopDark2xWEBP from './media/step1EngDesktopDark@2x.webp';
import step1EngDesktopLightWEBP from './media/step1EngDesktopLight.webp';
import step1EngDesktopLight2xWEBP from './media/step1EngDesktopLight@2x.webp';
import step1EngMobileDarkWEBP from './media/step1EngMobileDark.webp';
import step1EngMobileDark2xWEBP from './media/step1EngMobileDark@2x.webp';
import step1EngMobileLightWEBP from './media/step1EngMobileLight.webp';
import step1EngMobileLight2xWEBP from './media/step1EngMobileLight@2x.webp';
import step1RusDesktopDarkWEBP from './media/step1RusDesktopDark.webp';
import step1RusDesktopDark2xWEBP from './media/step1RusDesktopDark@2x.webp';
import step1RusDesktopLightWEBP from './media/step1RusDesktopLight.webp';
import step1RusDesktopLight2xWEBP from './media/step1RusDesktopLight@2x.webp';
import step1RusMobileDarkWEBP from './media/step1RusMobileDark.webp';
import step1RusMobileDark2xWEBP from './media/step1RusMobileDark@2x.webp';
import step1RusMobileLightWEBP from './media/step1RusMobileLight.webp';
import step1RusMobileLight2xWEBP from './media/step1RusMobileLight@2x.webp';
import step2ArmDesktopDarkWEBP from './media/step2ArmDesktopDark.webp';
import step2ArmDesktopDark2xWEBP from './media/step2ArmDesktopDark@2x.webp';
import step2ArmDesktopLightWEBP from './media/step2ArmDesktopLight.webp';
import step2ArmDesktopLight2xWEBP from './media/step2ArmDesktopLight@2x.webp';
import step2ArmMobileDarkWEBP from './media/step2ArmMobileDark.webp';
import step2ArmMobileDark2xWEBP from './media/step2ArmMobileDark@2x.webp';
import step2ArmMobileLightWEBP from './media/step2ArmMobileLight.webp';
import step2ArmMobileLight2xWEBP from './media/step2ArmMobileLight@2x.webp';
import step2EngDesktopDarkWEBP from './media/step2EngDesktopDark.webp';
import step2EngDesktopDark2xWEBP from './media/step2EngDesktopDark@2x.webp';
import step2EngDesktopLightWEBP from './media/step2EngDesktopLight.webp';
import step2EngDesktopLight2xWEBP from './media/step2EngDesktopLight@2x.webp';
import step2EngMobileDarkWEBP from './media/step2EngMobileDark.webp';
import step2EngMobileDark2xWEBP from './media/step2EngMobileDark@2x.webp';
import step2EngMobileLightWEBP from './media/step2EngMobileLight.webp';
import step2EngMobileLight2xWEBP from './media/step2EngMobileLight@2x.webp';
import step2RusDesktopDarkWEBP from './media/step2RusDesktopDark.webp';
import step2RusDesktopDark2xWEBP from './media/step2RusDesktopDark@2x.webp';
import step2RusDesktopLightWEBP from './media/step2RusDesktopLight.webp';
import step2RusDesktopLight2xWEBP from './media/step2RusDesktopLight@2x.webp';
import step2RusMobileDarkWEBP from './media/step2RusMobileDark.webp';
import step2RusMobileDark2xWEBP from './media/step2RusMobileDark@2x.webp';
import step2RusMobileLightWEBP from './media/step2RusMobileLight.webp';
import step2RusMobileLight2xWEBP from './media/step2RusMobileLight@2x.webp';
import step3ArmDesktopDarkWEBP from './media/step3ArmDesktopDark.webp';
import step3ArmDesktopDark2xWEBP from './media/step3ArmDesktopDark@2x.webp';
import step3ArmDesktopLightWEBP from './media/step3ArmDesktopLight.webp';
import step3ArmDesktopLight2xWEBP from './media/step3ArmDesktopLight@2x.webp';
import step3ArmMobileDarkWEBP from './media/step3ArmMobileDark.webp';
import step3ArmMobileDark2xWEBP from './media/step3ArmMobileDark@2x.webp';
import step3ArmMobileLightWEBP from './media/step3ArmMobileLight.webp';
import step3ArmMobileLight2xWEBP from './media/step3ArmMobileLight@2x.webp';
import step3EngDesktopDarkWEBP from './media/step3EngDesktopDark.webp';
import step3EngDesktopDark2xWEBP from './media/step3EngDesktopDark@2x.webp';
import step3EngDesktopLightWEBP from './media/step3EngDesktopLight.webp';
import step3EngDesktopLight2xWEBP from './media/step3EngDesktopLight@2x.webp';
import step3EngMobileDarkWEBP from './media/step3EngMobileDark.webp';
import step3EngMobileDark2xWEBP from './media/step3EngMobileDark@2x.webp';
import step3EngMobileLightWEBP from './media/step3EngMobileLight.webp';
import step3EngMobileLight2xWEBP from './media/step3EngMobileLight@2x.webp';
import step3RusDesktopDarkWEBP from './media/step3RusDesktopDark.webp';
import step3RusDesktopDark2xWEBP from './media/step3RusDesktopDark@2x.webp';
import step3RusDesktopLightWEBP from './media/step3RusDesktopLight.webp';
import step3RusDesktopLight2xWEBP from './media/step3RusDesktopLight@2x.webp';
import step3RusMobileDarkWEBP from './media/step3RusMobileDark.webp';
import step3RusMobileDark2xWEBP from './media/step3RusMobileDark@2x.webp';
import step3RusMobileLightWEBP from './media/step3RusMobileLight.webp';
import step3RusMobileLight2xWEBP from './media/step3RusMobileLight@2x.webp';
import step4ArmDesktopDarkWEBP from './media/step4ArmDesktopDark.webp';
import step4ArmDesktopDark2xWEBP from './media/step4ArmDesktopDark@2x.webp';
import step4ArmDesktopLightWEBP from './media/step4ArmDesktopLight.webp';
import step4ArmDesktopLight2xWEBP from './media/step4ArmDesktopLight@2x.webp';
import step4ArmMobileDarkWEBP from './media/step4ArmMobileDark.webp';
import step4ArmMobileDark2xWEBP from './media/step4ArmMobileDark@2x.webp';
import step4ArmMobileLightWEBP from './media/step4ArmMobileLight.webp';
import step4ArmMobileLight2xWEBP from './media/step4ArmMobileLight@2x.webp';
import step4EngDesktopDarkWEBP from './media/step4EngDesktopDark.webp';
import step4EngDesktopDark2xWEBP from './media/step4EngDesktopDark@2x.webp';
import step4EngDesktopLightWEBP from './media/step4EngDesktopLight.webp';
import step4EngDesktopLight2xWEBP from './media/step4EngDesktopLight@2x.webp';
import step4EngMobileDarkWEBP from './media/step4EngMobileDark.webp';
import step4EngMobileDark2xWEBP from './media/step4EngMobileDark@2x.webp';
import step4EngMobileLightWEBP from './media/step4EngMobileLight.webp';
import step4EngMobileLight2xWEBP from './media/step4EngMobileLight@2x.webp';
import step4RusDesktopDarkWEBP from './media/step4RusDesktopDark.webp';
import step4RusDesktopDark2xWEBP from './media/step4RusDesktopDark@2x.webp';
import step4RusDesktopLightWEBP from './media/step4RusDesktopLight.webp';
import step4RusDesktopLight2xWEBP from './media/step4RusDesktopLight@2x.webp';
import step4RusMobileDarkWEBP from './media/step4RusMobileDark.webp';
import step4RusMobileDark2xWEBP from './media/step4RusMobileDark@2x.webp';
import step4RusMobileLightWEBP from './media/step4RusMobileLight.webp';
import step4RusMobileLight2xWEBP from './media/step4RusMobileLight@2x.webp';
import step5ArmDesktopDarkWEBP from './media/step5ArmDesktopDark.webp';
import step5ArmDesktopDark2xWEBP from './media/step5ArmDesktopDark@2x.webp';
import step5ArmDesktopLightWEBP from './media/step5ArmDesktopLight.webp';
import step5ArmDesktopLight2xWEBP from './media/step5ArmDesktopLight@2x.webp';
import step5ArmMobileDarkWEBP from './media/step5ArmMobileDark.webp';
import step5ArmMobileDark2xWEBP from './media/step5ArmMobileDark@2x.webp';
import step5ArmMobileLightWEBP from './media/step5ArmMobileLight.webp';
import step5ArmMobileLight2xWEBP from './media/step5ArmMobileLight@2x.webp';
import step5EngDesktopDarkWEBP from './media/step5EngDesktopDark.webp';
import step5EngDesktopDark2xWEBP from './media/step5EngDesktopDark@2x.webp';
import step5EngDesktopLightWEBP from './media/step5EngDesktopLight.webp';
import step5EngDesktopLight2xWEBP from './media/step5EngDesktopLight@2x.webp';
import step5EngMobileDarkWEBP from './media/step5EngMobileDark.webp';
import step5EngMobileDark2xWEBP from './media/step5EngMobileDark@2x.webp';
import step5EngMobileLightWEBP from './media/step5EngMobileLight.webp';
import step5EngMobileLight2xWEBP from './media/step5EngMobileLight@2x.webp';
import step5RusDesktopDarkWEBP from './media/step5RusDesktopDark.webp';
import step5RusDesktopDark2xWEBP from './media/step5RusDesktopDark@2x.webp';
import step5RusDesktopLightWEBP from './media/step5RusDesktopLight.webp';
import step5RusDesktopLight2xWEBP from './media/step5RusDesktopLight@2x.webp';
import step5RusMobileDarkWEBP from './media/step5RusMobileDark.webp';
import step5RusMobileDark2xWEBP from './media/step5RusMobileDark@2x.webp';
import step5RusMobileLightWEBP from './media/step5RusMobileLight.webp';
import step5RusMobileLight2xWEBP from './media/step5RusMobileLight@2x.webp';

export const onboardingGuideContent = {
    blurHashes: {
        light: {
            desktop: {
                step1: 'LBRC_G000000~p%Mo#o#~q-=?b-;',
                step2: 'LKRyjNRV.8In_L%eR6My%NM{Ri%M',
                step3: 'LMSF-DD%%g%LV@V@ofoe_N%MIAM|',
                step4: 'L6S$ovIU?b~q-;M|WBRj_4-;9F00',
                step5: 'L5S?Gf-mM|NMxvf5oda}~TSixts,',
            },
            mobile: {
                step1: 'LSPQHWxtxuf,~qWBj=az-=ofR*WE',
                step2: 'LqQ]jDR.j[kC_Ln$jbj[M|j]fkay',
                step3: 'LTRfh4of~qaytRWBRjof_Nay9Fj[',
                step4: 'L4Ss50?bD*~q~W9F%gM{XBIT-=9F',
                step5: 'L4S?Ge%L~Uxutmoe%1fQ~Vj[Ips,',
            },
        },
        dark: {
            desktop: {
                step1: 'L95}:Y_4?b%NM_M{R:bJM|M|M_RO',
                step2: 'L57T#0rO0c*H0]JL=gw7E2tQxtIV',
                step3: 'L46*E#_30yR3$*oLRjfh00D$}@gP',
                step4: 'L455LT-;9F00V@j[ofof00D%?b~q',
                step5: 'L0261#%5tVgPxuoGjWfhMaM]Vpnf',
            },
            mobile: {
                step1: 'L25=U_,~DPSo4,NhjAsrN9IQNgW1',
                step2: 'L79Y=pBrS0R*0v,mjKoI-HNxWBj[',
                step3: 'L78D@PtR00IUD*V[%MtR00WC~Wt6',
                step4: 'L14_qd00?w00Dj?c4:x]E3?v4n~p',
                step5: 'L03Iecj=4TbHbxWAM{oe00WAo#oc',
            },
        },
    },
    en: {
        light: {
            desktop: {
                step1: [step1EngDesktopLight, step1EngDesktopLight2x, step1EngDesktopLightWEBP, step1EngDesktopLight2xWEBP],
                step2: [step2EngDesktopLight, step2EngDesktopLight2x, step2EngDesktopLightWEBP, step2EngDesktopLight2xWEBP],
                step3: [step3EngDesktopLight, step3EngDesktopLight2x, step3EngDesktopLightWEBP, step3EngDesktopLight2xWEBP],
                step4: [step4EngDesktopLight, step4EngDesktopLight2x, step4EngDesktopLightWEBP, step4EngDesktopLight2xWEBP],
                step5: [step5EngDesktopLight, step5EngDesktopLight2x, step5EngDesktopLightWEBP, step5EngDesktopLight2xWEBP],
            },
            mobile: {
                step1: [step1EngMobileLight, step1EngMobileLight2x, step1EngMobileLightWEBP, step1EngMobileLight2xWEBP],
                step2: [step2EngMobileLight, step2EngMobileLight2x, step2EngMobileLightWEBP, step2EngMobileLight2xWEBP],
                step3: [step3EngMobileLight, step3EngMobileLight2x, step3EngMobileLightWEBP, step3EngMobileLight2xWEBP],
                step4: [step4EngMobileLight, step4EngMobileLight2x, step4EngMobileLightWEBP, step4EngMobileLight2xWEBP],
                step5: [step5EngMobileLight, step5EngMobileLight2x, step5EngMobileLightWEBP, step5EngMobileLight2xWEBP],
            },
        },
        dark: {
            desktop: {
                step1: [step1EngDesktopDark, step1EngDesktopDark2x, step1EngDesktopDarkWEBP, step1EngDesktopDark2xWEBP],
                step2: [step2EngDesktopDark, step2EngDesktopDark2x, step2EngDesktopDarkWEBP, step2EngDesktopDark2xWEBP],
                step3: [step3EngDesktopDark, step3EngDesktopDark2x, step3EngDesktopDarkWEBP, step3EngDesktopDark2xWEBP],
                step4: [step4EngDesktopDark, step4EngDesktopDark2x, step4EngDesktopDarkWEBP, step4EngDesktopDark2xWEBP],
                step5: [step5EngDesktopDark, step5EngDesktopDark2x, step5EngDesktopDarkWEBP, step5EngDesktopDark2xWEBP],
            },
            mobile: {
                step1: [step1EngMobileDark, step1EngMobileDark2x, step1EngMobileDarkWEBP, step1EngMobileDark2xWEBP],
                step2: [step2EngMobileDark, step2EngMobileDark2x, step2EngMobileDarkWEBP, step2EngMobileDark2xWEBP],
                step3: [step3EngMobileDark, step3EngMobileDark2x, step3EngMobileDarkWEBP, step3EngMobileDark2xWEBP],
                step4: [step4EngMobileDark, step4EngMobileDark2x, step4EngMobileDarkWEBP, step4EngMobileDark2xWEBP],
                step5: [step5EngMobileDark, step5EngMobileDark2x, step5EngMobileDarkWEBP, step5EngMobileDark2xWEBP],
            },
        },
    },
    ru: {
        light: {
            desktop: {
                step1: [step1RusDesktopLight, step1RusDesktopLight2x, step1RusDesktopLightWEBP, step1RusDesktopLight2xWEBP],
                step2: [step2RusDesktopLight, step2RusDesktopLight2x, step2RusDesktopLightWEBP, step2RusDesktopLight2xWEBP],
                step3: [step3RusDesktopLight, step3RusDesktopLight2x, step3RusDesktopLightWEBP, step3RusDesktopLight2xWEBP],
                step4: [step4RusDesktopLight, step4RusDesktopLight2x, step4RusDesktopLightWEBP, step4RusDesktopLight2xWEBP],
                step5: [step5RusDesktopLight, step5RusDesktopLight2x, step5RusDesktopLightWEBP, step5RusDesktopLight2xWEBP],
            },
            mobile: {
                step1: [step1RusMobileLight, step1RusMobileLight2x, step1RusMobileLightWEBP, step1RusMobileLight2xWEBP],
                step2: [step2RusMobileLight, step2RusMobileLight2x, step2RusMobileLightWEBP, step2RusMobileLight2xWEBP],
                step3: [step3RusMobileLight, step3RusMobileLight2x, step3RusMobileLightWEBP, step3RusMobileLight2xWEBP],
                step4: [step4RusMobileLight, step4RusMobileLight2x, step4RusMobileLightWEBP, step4RusMobileLight2xWEBP],
                step5: [step5RusMobileLight, step5RusMobileLight2x, step5RusMobileLightWEBP, step5RusMobileLight2xWEBP],
            },
        },
        dark: {
            desktop: {
                step1: [step1RusDesktopDark, step1RusDesktopDark2x, step1RusDesktopDarkWEBP, step1RusDesktopDark2xWEBP],
                step2: [step2RusDesktopDark, step2RusDesktopDark2x, step2RusDesktopDarkWEBP, step2RusDesktopDark2xWEBP],
                step3: [step3RusDesktopDark, step3RusDesktopDark2x, step3RusDesktopDarkWEBP, step3RusDesktopDark2xWEBP],
                step4: [step4RusDesktopDark, step4RusDesktopDark2x, step4RusDesktopDarkWEBP, step4RusDesktopDark2xWEBP],
                step5: [step5RusDesktopDark, step5RusDesktopDark2x, step5RusDesktopDarkWEBP, step5RusDesktopDark2xWEBP],
            },
            mobile: {
                step1: [step1RusMobileDark, step1RusMobileDark2x, step1RusMobileDarkWEBP, step1RusMobileDark2xWEBP],
                step2: [step2RusMobileDark, step2RusMobileDark2x, step2RusMobileDarkWEBP, step2RusMobileDark2xWEBP],
                step3: [step3RusMobileDark, step3RusMobileDark2x, step3RusMobileDarkWEBP, step3RusMobileDark2xWEBP],
                step4: [step4RusMobileDark, step4RusMobileDark2x, step4RusMobileDarkWEBP, step4RusMobileDark2xWEBP],
                step5: [step5RusMobileDark, step5RusMobileDark2x, step5RusMobileDarkWEBP, step5RusMobileDark2xWEBP],
            },
        },
    },
    hy: {
        light: {
            desktop: {
                step1: [step1ArmDesktopLight, step1ArmDesktopLight2x, step1ArmDesktopLightWEBP, step1ArmDesktopLight2xWEBP],
                step2: [step2ArmDesktopLight, step2ArmDesktopLight2x, step2ArmDesktopLightWEBP, step2ArmDesktopLight2xWEBP],
                step3: [step3ArmDesktopLight, step3ArmDesktopLight2x, step3ArmDesktopLightWEBP, step3ArmDesktopLight2xWEBP],
                step4: [step4ArmDesktopLight, step4ArmDesktopLight2x, step4ArmDesktopLightWEBP, step4ArmDesktopLight2xWEBP],
                step5: [step5ArmDesktopLight, step5ArmDesktopLight2x, step5ArmDesktopLightWEBP, step5ArmDesktopLight2xWEBP],
            },
            mobile: {
                step1: [step1ArmMobileLight, step1ArmMobileLight2x, step1ArmMobileLightWEBP, step1ArmMobileLight2xWEBP],
                step2: [step2ArmMobileLight, step2ArmMobileLight2x, step2ArmMobileLightWEBP, step2ArmMobileLight2xWEBP],
                step3: [step3ArmMobileLight, step3ArmMobileLight2x, step3ArmMobileLightWEBP, step3ArmMobileLight2xWEBP],
                step4: [step4ArmMobileLight, step4ArmMobileLight2x, step4ArmMobileLightWEBP, step4ArmMobileLight2xWEBP],
                step5: [step5ArmMobileLight, step5ArmMobileLight2x, step5ArmMobileLightWEBP, step5ArmMobileLight2xWEBP],
            },
        },
        dark: {
            desktop: {
                step1: [step1ArmDesktopDark, step1ArmDesktopDark2x, step1ArmDesktopDarkWEBP, step1ArmDesktopDark2xWEBP],
                step2: [step2ArmDesktopDark, step2ArmDesktopDark2x, step2ArmDesktopDarkWEBP, step2ArmDesktopDark2xWEBP],
                step3: [step3ArmDesktopDark, step3ArmDesktopDark2x, step3ArmDesktopDarkWEBP, step3ArmDesktopDark2xWEBP],
                step4: [step4ArmDesktopDark, step4ArmDesktopDark2x, step4ArmDesktopDarkWEBP, step4ArmDesktopDark2xWEBP],
                step5: [step5ArmDesktopDark, step5ArmDesktopDark2x, step5ArmDesktopDarkWEBP, step5ArmDesktopDark2xWEBP],
            },
            mobile: {
                step1: [step1ArmMobileDark, step1ArmMobileDark2x, step1ArmMobileDarkWEBP, step1ArmMobileDark2xWEBP],
                step2: [step2ArmMobileDark, step2ArmMobileDark2x, step2ArmMobileDarkWEBP, step2ArmMobileDark2xWEBP],
                step3: [step3ArmMobileDark, step3ArmMobileDark2x, step3ArmMobileDarkWEBP, step3ArmMobileDark2xWEBP],
                step4: [step4ArmMobileDark, step4ArmMobileDark2x, step4ArmMobileDarkWEBP, step4ArmMobileDark2xWEBP],
                step5: [step5ArmMobileDark, step5ArmMobileDark2x, step5ArmMobileDarkWEBP, step5ArmMobileDark2xWEBP],
            },
        },
    },
};
