import { conditionalSpread } from 'clyne-core';
import { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import languages from '../../configs/languages';

import { languageState, metasState } from '../../state';

import { urlConstruct } from '../../helpers';

const Helmet = () => {
    const metas = useRecoilValue(metasState);
    const language = useRecoilValue(languageState);

    const imageUrlFormatter = str => `${/^\/\//gm.test(str) ? 'https:' : ''}${str}`;

    const urlLanguage = window.location.pathname.split('/')[1];
    const detectedLanguage = languages.find(language => language.code === urlLanguage);
    const cleanUrl = detectedLanguage ? window.location.href.replace(`${window.location.pathname.split('/').length > 2 ? '/' : ''}${detectedLanguage?.code}`, '') : window.location.href;

    const links = languages.reduce((prev, current) => ([
        ...prev,
        current,
        ...conditionalSpread([{
            ...current,
            canonical: true,
        }], current.default)
    ]), []).map(lang => {
        const location = new URL(cleanUrl);

        return {
            ...lang,
            href: `${location.origin}${urlConstruct({
                hash: location.hash,
                search: location.search,
                pathname: `${lang.default ? '' : `/${lang.code}`}${location.pathname === '/' ? '' : location.pathname}`,
            })}`,
            langHref: `${location.origin}${urlConstruct({
                hash: location.hash,
                search: location.search,
                pathname: `/${lang.code}${location.pathname === '/' ? '' : location.pathname}`,
            })}`,
        };
    });

    const content = [
        {
            value: metas.title,
            selectors: [
                ['title', 'innerText'],
                [`meta[name='title'], meta[itemProp='name'], meta[property='og:title'], meta[property='og:image:alt'], meta[name='twitter:title']`, 'content'],
            ],
        },
        {
            value: metas.description,
            selectors: [
                [`meta[name='description'], meta[itemProp='description'], meta[property='og:description'], meta[name='twitter:description']`, 'content'],
            ],
        },
        {
            value: metas.keywords,
            selectors: [
                [`meta[name='keywords']`, 'content'],
            ],
        },
        {
            value: window.location.href,
            selectors: [
                [`meta[property='og:url'], meta[property='twitter:url']`, 'content'],
            ],
        },
        {
            value: imageUrlFormatter(metas.image.source),
            selectors: [
                [`meta[itemProp='image'], meta[property='og:image'], meta[name='twitter:image']`, 'content'],
            ],
        },
        {
            value: metas.image.width,
            selectors: [
                [`meta[property='og:image:width']`, 'content'],
            ],
        },
        {
            value: metas.image.height,
            selectors: [
                [`meta[property='og:image:height']`, 'content'],
            ],
        },
        {
            value: [
                {
                    href: `${window.location.origin}${
                        window.location.pathname === '/'
                            ? ''
                            : (window.location.pathname + window.location.search).match(/^(\/[a-z]{2})?\/s(\?|$)/)
                                ? window.location.pathname
                                : window.location.pathname + window.location.hash + window.location.search}`,
                },
            ],
            selectors: [
                [`link[rel="canonical"]`],
            ],
        },
        {
            value: links.map(lang => ({
                href: (window.location.pathname + window.location.search).match(/^(\/[a-z]{2})?\/s(\?|$)/)
                    ? new URL(lang.canonical ? lang.langHref : lang.href).origin + new URL(lang.canonical ? lang.langHref : lang.href).pathname
                    : lang.href,
                hrefLang: lang.default && !lang.canonical ? 'x-default' : lang.code,
            })),
            selectors: [
                [`link[rel="alternate"]`],
            ],
        },
    ];

    if (metas.productRichSnippetScript) {
        content.push({
            value: metas.productRichSnippetScript,
            selectors: [
                [`script[type="application/ld+json"]`, 'innerText'],
            ],
        });
    }

    const dependencyContent = useMemo(() => JSON.stringify(content), [content, language?.code]); // eslint-disable-line

    useEffect(() => {
        content.forEach(item => item.selectors.forEach(([selector, modification]) => {
            const tags = document.querySelectorAll(selector);
            tags.forEach((tag, index) => {
                if (Array.isArray(item.value)) {
                    const value = item.value[index];
                    Object.keys(value).forEach(attr => tag.setAttribute(attr, value[attr]));
                } else {
                    if (modification === 'innerText') {
                        tag.innerText = item.value;
                    } else {
                        tag.setAttribute(modification, item.value);
                    }
                }
            });
        }));
    }, [dependencyContent]); // eslint-disable-line

    return null;
};

export default Helmet;
