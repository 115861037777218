import FileUploadThumbnail from 'file-upload-thumbnail';
import { FastAverageColor } from 'fast-average-color';
import { encode } from 'blurhash';

const loadImage = async src => new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (...args) => reject(args);
    img.src = src;
});

const getImageData = image => {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext('2d');
    context.drawImage(image, 0, 0);
    return context.getImageData(0, 0, image.width, image.height);
};

const encodeImageToBlurHash = async imageUrl => {
    const image = await loadImage(imageUrl);
    const imageData = getImageData(image);
    return encode(imageData.data, imageData.width, imageData.height, 4, 3);
};

const getImageThumbnail = (file, width, height) => new Promise((resolve, reject) => {
    try {
        new FileUploadThumbnail({
            [width > height ? 'maxWidth' : 'maxHeight']: 520,
            file: file,
            onError: reject,
            onSuccess: src => fetch(src).then(res => res.blob()).then(thumbnail => {
                const url = URL.createObjectURL(thumbnail);

                encodeImageToBlurHash(url).then(blurHash => {
                    const image = document.createElement('img');
                    image.src = url;
                    const fac = new FastAverageColor();
                    fac.getColorAsync(image).then(data => {
                        if (data?.hex && !data.error) {
                            resolve({
                                url,
                                blurHash,
                                color: data.hex,
                                blob: thumbnail,
                            });
                        } else {
                            reject(data?.error);
                        }
                    }).catch(reject);
                }).catch(reject);
            }).catch(reject),
        }).createThumbnail();
    } catch (e) {
        reject(e);
    }
});

export default getImageThumbnail;
