import { useEffect, useState } from 'react';

const useDevice = () => {
    const condition = () => Math.min(window.innerWidth, window.screen.width) < 577;

    const [isMobile, setIsMobile] = useState(condition());

    const checkForDevice = () => setIsMobile(condition());

    useEffect(() => {
        checkForDevice();

        window.addEventListener('resize', checkForDevice);

        return () => {
            window.removeEventListener('resize', checkForDevice);
        };
    }, []); // eslint-disable-line

    return { isMobile };
};

export default useDevice;
