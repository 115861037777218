import { useRecoilValue, useSetRecoilState } from 'recoil';
import React, { useEffect, useState } from 'react';
import { conditionalSpread } from 'clyne-core';
import PropTypes from 'prop-types';

import Loader from '../loader';
import NoData from '../noData';
import Translate from '../translate';
import VirtualList from '../virtualList';
import Notification from '../notification';

import { campaignDetailsState, reviewCampaignState, workspaceIdState } from '../../state';

import { NOTIFICATIONS } from '../../constants/apiKeys';
import { infiniteScrollLimit, userRoles, notificationKeys } from '../../constants/construct';

import translate from '../../utils/translate';

import { successStatus } from '../../helpers';

import useConnector from '../../hooks/useConnector';
import usePopoverContext from '../../hooks/usePopoverContext';

import connectionService from '../../services/connectionService';

const MiniNotifications = props => {
    const {
        mode,
        scrollElement,
        notificationsAreRead,
    } = props;

    const workspaceId = useRecoilValue(workspaceIdState);
    const setReviewCampaign = useSetRecoilState(reviewCampaignState);
    const setCampaignDetails = useSetRecoilState(campaignDetailsState);

    const {
        setOpenedState,
    } = usePopoverContext();

    const scrollerElement = typeof scrollElement === 'string' ? document.querySelector(scrollElement) : scrollElement?.current;

    useEffect(() => {
        !!scrollerElement && (scrollerElement.scrollTop = 0);
    }, [scrollerElement]);

    const [page, setPage] = useState(1);

    const urlPrefix = mode === notificationKeys.workspaceNotifications ? `/workspaces/${workspaceId}` : '';

    const url = `${urlPrefix}${NOTIFICATIONS}?page=${page}&limit=${infiniteScrollLimit}`;

    const { data, initialLoading, total, setData } = useConnector(url, [], {}, false, page, 'add');

    const handleClick = (id, read, params) => {
        const url = `${urlPrefix}${NOTIFICATIONS}/${id}/read`;
        !read && connectionService.putJson(url).subscribe(res => {
            if (successStatus(res)) {
                setData(val => val.map(obj => obj.id === id ? {
                    ...obj,
                    read: true,
                } : obj));
            }
        });
        if (params.id) {
            const data = {
                id: params.id,
                mode: params.mode,
                active: true,
            };
            if (params.type === 'review') {
                setReviewCampaign(data);
            } else {
                setCampaignDetails(data);
            }
            setOpenedState(false);
        }
    };

    const dataMapping = {
        campaignStopped: {
            icon: 'icon-a-cross',
            title: name => translate('_NAME_ is stopped', { _NAME_: name }),
            message: id => translate('Campaign #_ID_ is stopped by partner', { _ID_: id })
        },
        campaignScheduled: {
            icon: 'icon-a-check',
            title: name => translate('_NAME_ is approved', { _NAME_: name }),
            message: id => translate('Campaign #_ID_ is approved by partner', { _ID_: id })
        },
        campaignRejected: {
            icon: 'icon-a-cross',
            title: name => translate('_NAME_ is rejected', { _NAME_: name }),
            message: id => translate('Campaign #_ID_ is rejected by partner', { _ID_: id })
        },
        campaignReScheduled: {
            icon: 'icon-list-unordered-checks',
            title: name => translate('_NAME_ has been reviewed', { _NAME_: name }),
            message: id => translate('Campaign #_ID_ has been review by partner', { _ID_: id })
        },
        campaignCreate: {
            icon: 'icon-a-image',
            title: name => translate('_NAME_ - New campaign', { _NAME_: name }),
            message: id => translate('Campaign #_ID_ is waiting for your review', { _ID_: id })
        },
        campaignEdited: {
            icon: 'icon-a-edit',
            title: name => translate('_NAME_ has been edited', { _NAME_: name }),
            message: id => translate('Campaign #_ID_ needs to be reviewed', { _ID_: id })
        },
        organisationExcluded: {
            icon: 'icon-a-cross-circle',
            title: name => translate('_NAME_ has been cancelled', { _NAME_: name }),
            message: id => translate('Campaign #_ID_ has been cancelled or removed your screens', { _ID_: id })
        },
    };

    return initialLoading ? (
        <Loader placement='popover' />
    ) : (
        !!data?.length && !!scrollerElement ? (
            <VirtualList
                data={data}
                total={total}
                defaultSize={86}
                scrollElement={scrollerElement}
                fetchMore={() => setPage(val => val + 1)}
                renderer={({ data: item }) => {
                    const campaignID = item.extraInfo;
                    const data = dataMapping[item.triggeredBy] || {};
                    const image = item?.extraInfoObj?.campaign?.mediaURL;
                    const campaignName = item?.extraInfoObj?.campaign?.name || 'Campaign';
                    const icon = data.icon || 'icon-a-bell';

                    return !!item && (
                        <Notification
                            icon={icon}
                            date={item.time}
                            {...conditionalSpread({
                                image,
                                inlineIcon: icon,
                            }, !!image)}
                            name={data.title && campaignName ? data.title(campaignName) : translate(item.title)}
                            message={data.message ? data.message(campaignID) : translate(item.text)}
                            disabled={!campaignID && (item.read || notificationsAreRead[mode])}
                            onClick={() => handleClick(item.id, item.read, {
                                id: campaignID,
                                key: item.id,
                                mode: item.redirectTo === 'myCampaigns' ? userRoles.client : userRoles.partner,
                                type: ((item.triggeredBy === 'campaignCreate' || item.triggeredBy === 'campaignEdited') && item.redirectTo === 'campaigns') ? 'review' : 'info',
                            })}
                            badge={!notificationsAreRead[mode] ? !item.read : false}
                        />
                    );
                }}
            />
        ) : (
            <NoData
                size='small'
                type='notification'
                subTitle={
                    <Translate>You don't have any notifications. ...yet!</Translate>
                }
            />
        )
    );
};

MiniNotifications.propTypes = {
    mode: PropTypes.oneOf(Object.values(notificationKeys)),
    scrollElement: PropTypes.any,
    notificationsAreRead: PropTypes.object,
};

export default MiniNotifications;
