import { subDomain } from '../helpers';

const whiteLabels = {
    arbamedia: {
        colors: {
            hero: ['#5c3690', '#7a4fb5'],
            s5: ['#f5f3f8', '#231e34'],
        },
        workspacesId: '2fcae4cd-775b-4a94-aece-fdf48a419253',
        name: 'ARBA Media',
        meta: {
            title: 'ARBA Media | Digital Advertising Platform',
            keywords: '',
            description: '',
        },
        logo: {
            height: 40,
        },
        socials: [],
    },
    bbmedia: {
        colors: {
            hero: ['#81b907', '#8ab92a'],
            s5: ['#f5f9ee', '#222920'],
        },
        workspacesId: '976cee64-3f2f-439e-97cb-a035e0458471',
        name: 'BB Media',
        meta: {
            title: 'BB Media | Digital Advertising Platform',
            keywords: '',
            description: '',
        },
        logo: {
            height: 26,
        },
        socials: [],
    },
};

export default whiteLabels[subDomain];
