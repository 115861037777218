import React from 'react';

import features from '../../configs/features';
import translate from '../../utils/translate';
import Translate from '../../components/translate';
import lazyWithRetry from '../../utils/lazyWithRetry';

const Payments = lazyWithRetry(() => import('../payments'));
const Security = lazyWithRetry(() => import('../security'));
const BillingInfo = lazyWithRetry(() => import('../billingInfo'));
const PersonalInfo = lazyWithRetry(() => import('../personalInfo'));
const TransactionHistory = lazyWithRetry(() => import('../transactionHistory'));
const NotificationSettings = lazyWithRetry(() => import('../notificationSettings'));

export const userRoutes = {
    personalInformation: '/user/personal-info',
    transactionHistory: '/user/transaction-history',
    paymentMethods: '/user/payment-methods',
    billingInformation: '/user/billing-information',
    notificationSettings: '/user/notification-settings',
    security: '/user/security',
};

export const userRoutesData = [
    {
        name: translate('Personal Information'),
        subTitle: <Translate>All your personal settings can be changed here</Translate>,
        icon: 'icon-a-user',
        url: userRoutes.personalInformation,
        component: props => (
            <PersonalInfo {...props} />
        ),
    },
    {
        name: translate('Billing Information'),
        subTitle: <Translate>This data is used for legal services</Translate>,
        icon: 'icon-a-invoice',
        url: userRoutes.billingInformation,
        component: props => (
            <BillingInfo {...props} />
        ),
    },
    {
        name: translate('Payment Methods'),
        subTitle: <Translate>We value and respect your privacy, we keep your records private</Translate>,
        icon: 'icon-a-credit-card',
        url: userRoutes.paymentMethods,
        component: props => (
            <Payments {...props} />
        ),
    },
    {
        name: translate('Transaction History'),
        subTitle: <Translate>See all transactions that were made</Translate>,
        icon: 'icon-a-history',
        url: userRoutes.transactionHistory,
        component: props => (
            <TransactionHistory {...props} />
        ),
    },
    ...(features.notificationSettings.enabled ? ([
        {
            name: translate('Notification Settings'),
            subTitle: <Translate>We are very sorry if you want to disable something here</Translate>,
            icon: 'icon-a-bell',
            url: userRoutes.notificationSettings,
            component: props => (
                <NotificationSettings {...props} />
            ),
        },
    ]) : []),
    {
        name: translate('Account Security'),
        subTitle: <Translate>You can change or set your password here</Translate>,
        icon: 'icon-a-lock',
        url: userRoutes.security,
        component: props => (
            <Security {...props} />
        ),
    },
];
