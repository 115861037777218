import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

const useCopy = () => {
    const timeoutRef = useRef(null);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, []);

    const canCopy = text => (typeof text === 'string' && text !== '') || Number.isInteger(text);

    const handleCopy = (text, onSuccess) => {
        if (canCopy(text)) {
            setCopied(false);
            clearTimeout(timeoutRef.current);
            onSuccess && onSuccess();
            navigator.clipboard.writeText(text).then(() => {
                setCopied(true);
                timeoutRef.current = setTimeout(() => {
                    setCopied(false);
                }, 1500);
            });
        }
    };

    const className = classNames(
        {
            'active': copied,
        }
    );

    const icon = copied ? 'icon-a-checkbox-square' : 'icon-copy';

    return ({
        icon,
        copied,
        canCopy,
        className,
        handleCopy,
    });
};

export default useCopy;
