import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

const useDetectScreenSyncFromQR = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const syncScreenCode = searchParams.get('sync');

    useEffect(() => {
        if (syncScreenCode && syncScreenCode?.length === 6) {
            navigate(`/sync-screen?page=1&limit=10&sync=${syncScreenCode}`, {
                replace: true,
            });
        }
    }, [syncScreenCode]); // eslint-disable-line
};

export default useDetectScreenSyncFromQR;
