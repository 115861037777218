import { conditionalSpread } from 'clyne-core';
import { BehaviorSubject } from 'rxjs';

import { deleteObjectKey, successStatus } from '../helpers';

import createAxios from '../utils/createAxios';
import preprocessMediaFile from '../utils/preprocessMediaFile';

class UploadService {
    constructor() {
        this.axios = createAxios();
        this.stack = new BehaviorSubject({});
    };

    handleUpload = (file, id, options) => {
        const {
            guid,
            getterUrl,
            completeUrl,
            onKeyChange,
        } = options;

        return new Promise((resolve, reject) => {
            preprocessMediaFile(file).then(data => {
                const getCancelTokenSource = this.axios.CancelToken.source();
                const mainCancelTokenSource = this.axios.CancelToken.source();
                const completeCancelTokenSource = this.axios.CancelToken.source();
                const thumbnailCancelTokenSource = this.axios.CancelToken.source();

                this.stack.next({
                    ...this.stack.getValue(),
                    [id]: {
                        data,
                        guid,
                        cancel: () => {
                            getCancelTokenSource.cancel();
                            mainCancelTokenSource.cancel();
                            completeCancelTokenSource.cancel();
                            thumbnailCancelTokenSource.cancel();
                        },
                        status: 'pending',
                        progress: 0,
                    },
                });

                this.axios.get(`${getterUrl}?file=${encodeURI(file.name)}`, {
                    cancelToken: getCancelTokenSource.token,
                }).then(response => {
                    if (successStatus(response)) {
                        const {
                            data: {
                                key,
                                uploadUrl,
                                thumbnailUploadUrl,
                            },
                        } = response.data;

                        const headers = {
                            'Content-Type': 'b2/x-auto',
                        };

                        const stack = this.stack.getValue();
                        const current = stack[id];

                        this.stack.next({
                            ...deleteObjectKey(stack, id),
                            [key]: {
                                ...current,
                                getter: response.data.data,
                            },
                        });

                        !!onKeyChange && onKeyChange(key);

                        this.updateUpload(key, {
                            key,
                        });

                        const uploads = [
                            ...(thumbnailUploadUrl ? [
                                this.axios.put(thumbnailUploadUrl, data.thumbnail, {
                                    headers,
                                    cancelToken: thumbnailCancelTokenSource.token,
                                })
                            ] : []),
                            this.axios.put(uploadUrl, file, {
                                headers,
                                cancelToken: mainCancelTokenSource.token,
                                onUploadProgress: e => {
                                    const progress = e.loaded / file.size * 100;
                                    this.updateUpload(key, {
                                        progress,
                                        status: 'uploading',
                                    });
                                },
                            }),
                        ];

                        Promise.all(uploads).then(results => {
                            if (results.some(p => p.status === 'rejected')) {
                                return reject(`Couldn't upload`);
                            }

                            this.updateUpload(key, {
                                status: 'loading',
                            });

                            new Promise((resolve, reject) => {
                                if (!completeUrl) {
                                    return resolve({
                                        results,
                                    });
                                }

                                const metadata = {
                                    size: data.size,
                                    type: data.type,
                                    color: data.color,
                                    width: data.width,
                                    height: data.height,
                                    blurHash: data.blurHash,
                                    ...conditionalSpread({
                                        duration: data.duration,
                                    }, data.duration),
                                    extension: `.${data.extension}`,
                                };

                                this.axios.post(`${completeUrl}/${key}?filename=${file.name}`, metadata, {
                                    cancelToken: completeCancelTokenSource.token,
                                }).then(response => {
                                    if (successStatus(response)) {
                                        resolve({
                                            results,
                                        });
                                    } else {
                                        reject(response);
                                    }
                                }).catch(reject);
                            }).then(output => {
                                this.updateUpload(key, {
                                    output,
                                    status: 'done',
                                });
                                resolve(this.stack.getValue()[key]);
                            }).catch(reject);
                        }).catch(reject);
                    } else {
                        reject(response);
                    }
                }).catch(reject);
            }).catch(reject);
        });
    };

    cancelUpload = id => {
        const stack = this.stack.getValue();
        const upload = stack[id];
        if (upload) {
            if (upload.status === 'pending' || upload.status === 'uploading' || upload.status === 'loading') {
                upload.cancel();
            }
            this.stack.next(deleteObjectKey(stack, id));
        }
    };

    updateUpload = (id, data) => {
        const stack = this.stack.getValue();
        const upload = stack[id];
        upload && this.stack.next({
            ...stack,
            [id]: {
                ...upload,
                ...data,
            },
        });
    };

    removeUpload = id => {
        (Array.isArray(id) ? id : [id]).forEach(id => {
            const stack = this.stack.getValue();
            this.stack.next(deleteObjectKey(stack, id));
        });
    };

    getUploads = () => {
        return this.stack;
    };
}

export default new UploadService(); // eslint-disable-line
