import { useLocation, useNavigate } from 'react-router-dom';
import { isFunction } from 'clyne-core';
import { useRecoilValue } from 'recoil';
import { useEffect } from 'react';

import { getLSItem, setLSItem } from '../helpers';

import languages from '../configs/languages';

import { preciseLocationState, signedInState, userState } from '../state';

import translationService from '../services/translationService';

import { authenticationRoutes } from '../containers/authentication/data';

import cleanURLFromLanguage from '../utils/cleanURLFromLanguage';

import useDevice from './useDevice';

const citiesRedirect = [
    {
        pathname: '/yerevan',
        fallbackLanguage: 'hy',
        mobile: `north=40.3296930177552&south=40.03986617516082&west=44.448768373396845&east=44.57751440611169&zoom=12`,
        desktop: `north=40.2246663117884&south=40.130757660913574&west=44.32293003110892&east=44.709511452007355&zoom=13`,
    },
    {
        pathname: '/demo',
        fallbackLanguage: 'hy',
        mobile: `north=40.3296930177552&south=40.03986617516082&west=44.448768373396845&east=44.57751440611169&zoom=12`,
        desktop: `north=40.2246663117884&south=40.130757660913574&west=44.32293003110892&east=44.709511452007355&zoom=13`,
    },
];

const useLanguageDetect = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const user = useRecoilValue(userState);
    const signedIn = useRecoilValue(signedInState);
    const preciseLocation = useRecoilValue(preciseLocationState);

    const { isMobile } = useDevice();

    const setLanguage = (language, callback) => {
        translationService.setLanguage(language);
        setLSItem('language', language);
        isFunction(callback);
    };

    useEffect(() => {
        const handleEventListener = e => {
            if (e.key === 'language' && e.newValue !== e.oldValue && e.newValue !== 'null') {
                setLanguage(e.newValue);
            }
        };

        window.addEventListener('storage', handleEventListener);

        return () => {
            window.removeEventListener('storage', handleEventListener);
        };
    }, []);

    useEffect(() => {
        const urlLanguage = location.pathname.split('/')[1];
        const detectedLanguage = languages.find(language => language.code === urlLanguage);
        const foundCity = citiesRedirect.find(city => location.pathname.includes(city.pathname));
        const finalUrl = location.pathname.replace(`${urlLanguage}/`, '') || '/';

        if (foundCity) {
            setLanguage(detectedLanguage?.code || foundCity.fallbackLanguage, () => navigate(`/s?${isMobile ? foundCity.mobile : foundCity.desktop}`, {
                replace: true,
            }));
        } else {
            if (detectedLanguage) {
                setLanguage(detectedLanguage?.code, () => {
                    const bilingualPaths = [
                        '/s',
                        '/demo',
                        '/terms/',
                        '/screen/',
                        '/one-status',
                        '/become-a-partner',
                        ...Object.values(authenticationRoutes),
                    ];

                    if (!bilingualPaths.some(url => url === '/s' ? cleanURLFromLanguage(location.pathname) === '/s' : location.pathname.includes(url))) {
                        navigate(finalUrl, {
                            replace: true,
                        });
                    }
                });
            }

            if (!detectedLanguage && Object.keys(preciseLocation || {})?.length && signedIn !== null && (!signedIn || user?.language)) {
                const lang = [
                    user?.language,
                    getLSItem('language'),
                    preciseLocation?.country_code === 'AM' ? 'hy' : (preciseLocation?.location?.languages?.[0]?.code || Object.keys(preciseLocation?.location?.languages || {})?.[0]),
                    languages.find(lang => lang.default)?.code,
                ].filter(Boolean)[0];
                setLanguage(lang);
            }
        }
    }, [JSON.stringify(preciseLocation), signedIn, user?.language]); // eslint-disable-line
};

export default useLanguageDetect;
