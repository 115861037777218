import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';

import Tab from '../tab';
import Tabs from '../tabs';
import Loader from '../loader';
import NoData from '../noData';
import Translate from '../translate';
import Notification from '../notification';

import { workspaceIdState } from '../../state';

const MiniMessages = props => {
    const {
        setHasConversation,
    } = props;

    const workspaceId = useRecoilValue(workspaceIdState);
    const [initialLoading, setInitialLoading] = useState(true);

    const [messagesData, updateMessagesData] = useState([]);

    useEffect(() => {
        setInitialLoading(false);
        updateMessagesData([]);

        return () => {
        };
    }, []);

    useEffect(() => {
        typeof setHasConversation === 'function' && setHasConversation(!!messagesData?.length);
    }, [setHasConversation, messagesData]);

    return (
        <Tabs head={false}>
            <Tab>
                {initialLoading ? (
                    <Loader placement='popover' />
                ) : (
                    messagesData?.length ? messagesData.sort((a, b) => a?.overview?.isRead - b?.overview?.isRead).map(message => !!message.messages && (
                        <Notification
                            key={message.id}
                            to={`/messages/${message?.id}`}
                            name={workspaceId === message?.workspace?.id ? message?.participants?.[0]?.user?.name : message.workspace?.name}
                            date={message?.overview?.lastMessageTime}
                            badge={message?.overview && message?.overview?.lastMessageTime && !message.overview.isRead}
                            image={workspaceId === message?.workspace?.id ? message?.participants?.[0]?.user?.avatar : message?.workspace?.avatar}
                            message={message?.overview?.lastMessage}
                            placeholder={
                                <Translate>Start a conversation</Translate>
                            }
                        />
                    )) : (
                        <NoData
                            size='small'
                            type='data'
                            subTitle={
                                <Translate>You don't have any messages. ...yet!</Translate>
                            }
                        />
                    )
                )}
            </Tab>
        </Tabs>
    );
};

MiniMessages.propTypes = {
    setHasConversation: PropTypes.func,
};

export default MiniMessages;
