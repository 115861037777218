import { supportEmail } from '../helpers';

import identityHubSpotUser from './identityHubSpotUser';

const toggleHubspotChat = e => {
    const id = 'hs-script-loader';
    const element = e.target;
    const className = 'loading';
    element.classList.remove(className);
    element.classList.add(className);

    const callback = () => {
        const hs = window?.HubSpotConversations;
        const hsWidget = hs?.widget;

        const removeClassName = () => element.classList.remove(className);

        if (hsWidget) {
            hs.on('widgetClosed', removeClassName);
            hs.on('widgetLoaded', removeClassName);

            if (document.documentElement.classList.contains('hs-messages-widget-open')) {
                hsWidget.close();
                element.classList.remove(className);
            } else {
                identityHubSpotUser();
                hsWidget.load({ widgetOpen: true });
                hsWidget.open();
            }
        } else {
            element.classList.remove(className);
            window.location.href = `mailto:${supportEmail}`;
        }
    };

    if (document.getElementById(id)) {
        callback();
    } else {
        const script = document.createElement('script');
        script.async = true;
        script.defer = true;
        script.id = id;
        script.type = 'text/javascript';
        script.src = 'https://js.hs-scripts.com/6787260.js';
        script.onload = () => setTimeout(() => {
            callback();
        }, 500);
        script.onerror = callback;
        document.head.appendChild(script);
    }
};

export default toggleHubspotChat;
