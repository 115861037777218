import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import useDevice from '../../hooks/useDevice';

import './index.scss';

const MobileStack = props => {
    const {
        children,
        enabled = true,
        scrollIntoViewIfActive,
    } = props;

    const { isMobile } = useDevice();
    const ref = useRef(null);

    useEffect(() => {
        if (scrollIntoViewIfActive && isMobile) {
            const navigation = ref?.current?.querySelectorAll('.navigation-holder > *');
            !!navigation?.length && Array.from(navigation).forEach(element => {
                if (element.classList.contains('active')) {
                    element.scrollIntoView({ block: 'end', inline: 'center' });
                }
            });
        }
    }, [ref.current, isMobile]); // eslint-disable-line

    return enabled ? (
        <div
            ref={ref}
            className='mobile-stack-holder'
        >
            {children}
        </div>
    ) : children;
};

MobileStack.propTypes = {
    enabled: PropTypes.bool,
    children: PropTypes.any,
    scrollIntoViewIfActive: PropTypes.bool,
};

export default MobileStack;
