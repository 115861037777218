import Translate from '../components/translate';

const campaignStatuses = {
    draft: {
        id: 1,
        key: 'draft',
        name: <Translate>Draft</Translate>,
        color: 'grey',
    },
    pending: {
        id: 2,
        key: 'pending',
        name: <Translate>Pending</Translate>,
        color: 'warning',
    },
    rejected: {
        id: 4,
        key: 'rejected',
        name: <Translate>Rejected</Translate>,
        color: 'error',
    },
    stopped: {
        id: 5,
        key: 'stopped',
        name: <Translate>Canceled</Translate>,
        color: 'error',
    },
    scheduled: {
        id: 6,
        key: 'scheduled',
        name: <Translate>Scheduled</Translate>,
        color: 'success',
    },
    running: {
        id: 7,
        key: 'running',
        name: <Translate>Running</Translate>,
        color: 'success',
    },
    finished: {
        id: 8,
        key: 'finished',
        name: <Translate>Finished</Translate>,
        color: 'default',
    },
    expired: {
        id: 9,
        key: 'expired',
        name: <Translate>Expired</Translate>,
        color: 'grey',
    },
    stoppedByWs: {
        id: 10,
        key: 'stoppedByWs',
        name: <Translate>Stopped by WS</Translate>,
        color: 'error',
    },
    pendingCancellation: {
        id: 11,
        key: 'pendingCancellation',
        name: <Translate>Pending Cancellation</Translate>,
        color: 'warning',
    },
};

export default campaignStatuses;
