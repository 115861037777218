import PropTypes from 'prop-types';

import { popperConfig } from '../../props';

export const tooltipProps = {
    closed: PropTypes.bool,
    active: PropTypes.bool,
    content: PropTypes.any,
    children: PropTypes.any,
    position: PropTypes.oneOf(popperConfig.position),
    className: PropTypes.string,
    closeOnBlur: PropTypes.bool,
};
