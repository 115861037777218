import { safeREL, conditionalSpread, toProps } from 'clyne-core';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import checkRouteMatchByURL from '../../utils/checkRouteMatchByURL';

const To = props => {
    const {
        href,
        onTap,
        title,
        onClick,
        children,
        url = '',
        className,
        target = toProps.target[0],
    } = props;

    const location = useLocation();

    return onClick ? (
        <div
            title={title}
            onClick={e => {
                typeof onTap === 'function' && onTap(e);
                typeof onClick === 'function' && onClick(e);
            }}
            className={classNames(
                'cursor-pointer',
                className,
            )}
        >
            {children}
        </div>
    ) : (
        href ? (
            <a
                href={href}
                title={title}
                target={target}
                {...conditionalSpread({
                    rel: safeREL,
                }, target !== '_self')}
                className={className}
                onClick={e => typeof onTap === 'function' && onTap(e)}
            >
                {children}
            </a>
        ) : (
            <NavLink
                title={title}
                target={target}
                to={Array.isArray(url) ? url[0] : url}
                onClick={e => typeof onTap === 'function' && onTap(e)}
                className={({ isActive }) => classNames(
                    className,
                    {
                        active: isActive || checkRouteMatchByURL(url, location.pathname),
                    }
                )}
            >
                {children}
            </NavLink>
        )
    );
};

To.propTypes = {
    url: PropTypes.any,
    href: PropTypes.any,
    title: PropTypes.string,
    onTap: PropTypes.func,
    target: PropTypes.oneOf(toProps.target),
    onClick: PropTypes.func,
    children: PropTypes.any,
    className: PropTypes.string,
};

export default To;
