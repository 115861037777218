import { useRecoilValue, useSetRecoilState } from 'recoil';
import { conditionalSpread } from 'clyne-core';
import React, { useRef, useState } from 'react';

import Button from '../button';
import Popover from '../popover';
import Translate from '../translate';
import Favourites from '../favourites';
import MiniMessages from '../miniMessages';
import Notifications from '../notifications';

import features from '../../configs/features';
import { NOTIFICATIONS } from '../../constants/apiKeys';
import { notificationKeys, userRoles } from '../../constants/construct';

import { successStatus } from '../../helpers';

import useDevice from '../../hooks/useDevice';

import connectionService from '../../services/connectionService';

import { dynamicDataState, dynamicDataTriggerState, favouritesState, userRoleState, userState, workspaceIdState } from '../../state';

const popoverProps = {
    sticky: true,
    position: 'bottom-end',
    offsetBounding: [0, 25],
    maxItems: 3,
    itemHeight: 86,
};

const popoverButtonProps = {
    size: 'default',
    color: 'accent',
    cornerRadius: 'smooth',
};

const HeaderSignedInActions = props => {
    const {
        notificationsSelectedTab,
        setNotificationsSelectedTab,
    } = props;

    const user = useRecoilValue(userState);
    const userRole = useRecoilValue(userRoleState);
    const favourites = useRecoilValue(favouritesState);
    const workspaceId = useRecoilValue(workspaceIdState);
    const dynamicData = useRecoilValue(dynamicDataState);
    const setDynamicDataTrigger = useSetRecoilState(dynamicDataTriggerState);

    const miniNotificationsScrollerRef = useRef(null);

    const [hasConversation, setHasConversation] = useState(false);
    const [readAllNotificationsLoading, setReadAllNotificationsLoading] = useState(false);
    const [notificationsAreRead, setNotificationsAreRead] = useState({
        [notificationKeys.notifications]: false,
        [notificationKeys.workspaceNotifications]: false,
    });

    const unitedNotificationsCount = (dynamicData[notificationKeys.notifications]?.count || 0) + (dynamicData[notificationKeys.workspaceNotifications]?.count || 0);

    const {
        isMobile,
    } = useDevice();

    const miniNotificationsData = {
        [notificationKeys.notifications]: {
            title: user?.givenName,
        },
        ...conditionalSpread({
            [notificationKeys.workspaceNotifications]: {
                title: user?.workspace?.name,
            },
        }, userRole === userRoles.partner),
    };

    const selectedMiniNotificationsId = Object.keys(miniNotificationsData).indexOf(notificationsSelectedTab);

    const handleReadAllNotifications = () => {
        setReadAllNotificationsLoading(true);
        const url = `${selectedMiniNotificationsId ? `/workspaces/${workspaceId}` : ''}${NOTIFICATIONS}/read/all`;
        connectionService.putJson(url).subscribe(res => {
            if (successStatus(res)) {
                setNotificationsAreRead(val => ({
                    ...val,
                    [notificationsSelectedTab]: true,
                }));
            }
            setReadAllNotificationsLoading(false);
        });
    };

    const notificationsSharedProps = {
        notificationsAreRead,
        miniNotificationsData,
        setNotificationsSelectedTab,
        selectedMiniNotificationsId,
        miniNotificationsScrollerRef,
    };

    const mobileContent = [
        {
            icon: 'icon-a-heart',
            badge: favourites?.length,
            maxItems: 2,
            itemHeight: 303,
            ...conditionalSpread({
                module: {
                    header: {
                        title: <Translate>Favorites</Translate>,
                        subTitle: (
                            <Translate replaceMap={{ '_COUNT_': favourites?.length }}>You have _COUNT_ favorite screens</Translate>
                        ),
                    },
                },
            }, !!favourites?.length),
            children: (
                <Favourites />
            ),
        },
        ...conditionalSpread([
            {
                icon: 'icon-a-email',
                badge: dynamicData.messages.count,
                itemHeight: 100,
                width: 400,
                position: 'bottom',
                module: {
                    header: {
                        title: <Translate>Messages</Translate>,
                        subTitle: (
                            dynamicData.messages.count ? (
                                <Translate replaceMap={{ '_COUNT_': dynamicData.messages.count }}>You have _COUNT_ unread messages</Translate>
                            ) : (
                                hasConversation ? (
                                    <Translate>You don't have any unread messages</Translate>
                                ) : (
                                    <Translate>You don't have any messages</Translate>
                                )
                            )
                        ),
                        ...conditionalSpread({
                            cta: {
                                to: '/messages',
                                tooltip: <Translate>View All Messages</Translate>,
                                icon: {
                                    type: 'icon-scale',
                                },
                            },
                        }, hasConversation),
                    },
                },
                children: (
                    <MiniMessages setHasConversation={setHasConversation} />
                ),
            },
        ], features.messages),
        {
            icon: 'icon-a-bell',
            width: 400,
            maxItems: 5,
            badge: unitedNotificationsCount,
            onOpen: () => setDynamicDataTrigger(val => val + 1),
            scrollerRef: val => miniNotificationsScrollerRef.current = val,
            module: {
                header: {
                    title: <Translate>Notifications</Translate>,
                    subTitle: (
                        unitedNotificationsCount ? (
                            <Translate replaceMap={{ '_COUNT_': unitedNotificationsCount }}>You have _COUNT_ unread notifications</Translate>
                        ) : (
                            <Translate>You don't have any unread notifications</Translate>
                        )
                    ),
                    ...conditionalSpread({
                        cta: {
                            loading: readAllNotificationsLoading,
                            onClick: () => handleReadAllNotifications(),
                            tooltip: <Translate replaceMap={{ '_COUNT_': dynamicData?.[notificationsSelectedTab]?.count }}>Mark _COUNT_ as read</Translate>,
                            icon: {
                                type: 'icon-a-checkbox-square',
                            },
                        },
                    }, dynamicData?.[notificationsSelectedTab]?.count),
                    ...conditionalSpread({
                        children: (
                            <Notifications
                                body={false}
                                {...notificationsSharedProps}
                            />
                        ),
                    }, userRole === userRoles.partner),
                },
            },
            children: (
                <Notifications
                    head={false}
                    {...notificationsSharedProps}
                />
            ),
        },
    ];

    return (
        <>
            {mobileContent.map((item, index) => (
                <Popover
                    key={index}
                    {...popoverProps}
                    width={item.width}
                    onOpen={item.onOpen}
                    module={item?.module}
                    content={item.children}
                    maxItems={item.maxItems}
                    {...conditionalSpread({
                        itemHeight: item.itemHeight,
                    }, !!item.itemHeight)}
                    {...conditionalSpread({
                        scrollerRef: item.scrollerRef,
                    }, !!item.scrollerRef)}
                >
                    <Button
                        badge={{
                            badge: item.badge,
                            ...conditionalSpread({
                                size: 'small',
                                transform: false,
                            }, isMobile),
                        }}
                        hero='white'
                        {...conditionalSpread(popoverButtonProps, !isMobile)}
                        icon={{
                            type: item.icon,
                        }}
                    />
                </Popover>
            ))}
        </>
    );
};

export default HeaderSignedInActions;
