import { conditionalSpread } from 'clyne-core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import To from '../to';
import Icon from '../icon';
import Button from '../button';
import Popover from '../popover';

import translate from '../../utils/translate';

import useCopy from '../../hooks/useCopy';

import './index.scss';

const MenuItem = props => {
    const {
        url,
        name,
        icon,
        flag,
        badge,
        slash,
        danger,
        avatar,
        onClick,
        loading,
        forward,
        actions,
        disabled,
        currency,
        selected,
        dataToCopy,
        highlighted,
    } = props;

    const {
        canCopy,
        handleCopy,
    } = useCopy();

    return (
        <div
            onClick={e => {
                !!onClick && onClick(e);
                !!dataToCopy && handleCopy(dataToCopy);
            }}
            className={classNames(
                `menu-item`,
                {
                    danger,
                    selected,
                    highlighted,
                    disabled: disabled || dataToCopy ? !canCopy(dataToCopy) : false,
                },
            )}
        >
            <ul className='mi-content'>
                {(icon || flag || currency || !!avatar) && (
                    <li
                        className={classNames(
                            'f-fit',
                            {
                                slash,
                            }
                        )}
                    >
                        {(icon || flag || currency) && (
                            <Icon
                                size={22}
                                flag={flag}
                                currency={currency}
                                {...conditionalSpread({
                                    type: loading ? 'icon-a-loader' : icon,
                                }, typeof icon === 'string')}
                                {...icon}
                            />
                        )}
                        {avatar && (
                            <div className='mi-avatar-holder'>
                                {avatar}
                            </div>
                        )}
                    </li>
                )}
                <li className='f-auto'>
                    <div className='text-ellipsis'>
                        {translate(name)}
                    </div>
                </li>
                {(!!forward || !!badge || !!selected || !!actions?.length) && (
                    <li
                        className='f-fit'
                        {...conditionalSpread({
                            onClick: e => e.stopPropagation(),
                        }, !!actions?.length)}
                    >
                        {!!actions?.length && (
                            <Popover menu={actions}>
                                <Button
                                    type='button'
                                    color='accent'
                                    size='small'
                                    icon={{
                                        size: 20,
                                        type: 'icon-a-more-horiz',
                                    }}
                                />
                            </Popover>
                        )}
                        {!!badge && (
                            <Icon
                                size={14}
                                type='icon-a-target'
                            />
                        )}
                        {!!selected && (
                            <Icon
                                size={22}
                                type='icon-a-check'
                            />
                        )}
                        {!!forward && (
                            <Icon type='icon-a-chevron-right' />
                        )}
                    </li>
                )}
            </ul>
            {!!url && (
                <To
                    url={url}
                    className='absolutely-splash'
                />
            )}
        </div>
    );
};

MenuItem.propTypes = {
    url: PropTypes.string,
    name: PropTypes.any,
    icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    flag: PropTypes.string,
    badge: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    slash: PropTypes.bool,
    avatar: PropTypes.any,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
    forward: PropTypes.bool,
    actions: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        icon: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
        ]),
        name: PropTypes.any,
        onClick: PropTypes.func,
    })),
    currency: PropTypes.string,
    selected: PropTypes.bool,
    dataToCopy: PropTypes.any,
    highlighted: PropTypes.bool,
};

export default MenuItem;
