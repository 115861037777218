import React from 'react';
import { createPortal } from 'react-dom';
import { ReactNotifications } from 'react-notifications-component';

import './index.scss';

const Toaster = () => {
    return createPortal((
        <ReactNotifications />
    ), document.body);
};

export default Toaster;
