import React, { useState, useEffect, forwardRef } from 'react';
import classNames from 'classnames';

import Icon from '../icon';
import Collapse from '../collapse';
import Translate from '../translate';
import Information from '../information';

import translate from '../../utils/translate';

import usePrice from '../../hooks/usePrice';

import { inputConfig, inputProps } from './props';

import { inputValue, requiredFormatter } from '../../helpers';

import './index.scss';

const Input = forwardRef(function Input(props, ref) {
    const {
        min,
        max,
        icon,
        name,
        left,
        right,
        label,
        error,
        onBlur,
        loading,
        onKeyUp,
        onFocus,
        onPaste,
        onClick,
        pattern,
        disabled,
        onChange,
        readOnly,
        required,
        inputRef,
        children,
        skeleton,
        autoFocus,
        maxLength,
        className,
        onKeyDown,
        value = '',
        placeholder,
        persistValue,
        postfix = '',
        autoComplete,
        type = inputConfig.type[0],
        size = inputConfig.size[3],
        cornerRadius = inputConfig.cornerRadius[1],
        ...restProps
    } = props;

    const isPrice = type === 'price';

    const [localValue, setLocalValue] = useState(value);
    const [showPrice, setShowPrice] = useState(isPrice);
    const [modifiedType, setModifiedType] = useState(isPrice ? 'text' : type);

    const isDate = type === 'date';
    const isTime = type === 'time';
    const isSearch = type === 'search';
    const isDropdown = type === 'select';
    const isPassword = type === 'password';

    const isInput = !isDate && !isDropdown;

    const hasLeftActions = isDate || icon || isSearch || isTime;
    const hasRightActions = isDropdown || isPassword;

    useEffect(() => {
        (!persistValue && isInput && localValue !== value) && setLocalValue(value);
    }, [value, localValue, isInput, persistValue]);

    const handleChange = e => {
        if (type === 'price' ? /^\d*?\d*$/.test(inputValue(e)) : (!pattern || !e.target.value || pattern.test(e.target.value))) {
            typeof onChange === 'function' && onChange(e);
            isInput && setLocalValue(inputValue(e));
        }
    };

    const handleFocus = e => {
        typeof onFocus === 'function' && onFocus(e);
        isPrice && setShowPrice(false);
    };

    const handleBlur = e => {
        typeof onBlur === 'function' && onBlur(e);
        isPrice && setShowPrice(true);
    };

    const sharedProps = {
        className: classNames(
            'input-element',
            {
                'data-hj-allow': type !== 'password',
            }
        ),
        'aria-label': translate(placeholder),
    };

    const placeLabel = requiredFormatter((translate(placeholder) || label), required);
    const formattedValue = usePrice(localValue, showPrice, postfix);

    return (
        <div
            className={classNames(
                className,
                'input-holder',
                `s-${size}`,
                `cr-${cornerRadius}`,
                {
                    error,
                    disabled,
                    'read-only': readOnly,
                    'centered': maxLength === 1,
                    'pointer-events-none': loading || skeleton,
                    'placeholder': !value && !children && value !== 0,
                }
            )}
        >
            {skeleton ? (
                <div className='input-element skeleton-blink' />
            ) : (
                <>
                    {label && (
                        <div className='input-label text-ellipsis'>
                            {requiredFormatter(label, required)}
                        </div>
                    )}
                    <div
                        ref={ref}
                        onClick={onClick}
                        className={classNames(
                            `input-element-holder`,
                            {
                                'cursor-pointer': onClick && !readOnly,
                            }
                        )}
                    >
                        {(hasLeftActions || left) && (
                            <div className='input-actions input-left-actions'>
                                {hasLeftActions && (
                                    <Icon type={icon || (isTime ? 'icon-a-time' : isDate ? 'icon-a-calendar' : 'icon-a-search')} />
                                )}
                                {left && (
                                    <div className='input-action'>
                                        {left}
                                    </div>
                                )}
                            </div>
                        )}
                        {(hasRightActions || right) && (
                            <div className='input-actions input-right-actions'>
                                {isPassword && (
                                    <Icon
                                        size={18}
                                        type={modifiedType === 'password' ? 'icon-a-eye' : 'icon-a-eye-slash'}
                                        onClick={() => setModifiedType(val => val === 'password' ? 'text' : 'password')}
                                    />
                                )}
                                {isDropdown && (
                                    <Icon
                                        size={20}
                                        className='dropdown-chevron'
                                        type={loading ? 'icon-a-loader' : 'icon-a-chevron-down'}
                                    />
                                )}
                                {right && (
                                    <div className='input-action'>
                                        {right}
                                    </div>
                                )}
                            </div>
                        )}
                        {(isInput && !children) ? (
                            <input
                                min={min}
                                max={max}
                                name={name}
                                ref={inputRef}
                                onPaste={onPaste}
                                onKeyUp={onKeyUp}
                                type={modifiedType}
                                readOnly={readOnly}
                                onBlur={handleBlur}
                                autoFocus={autoFocus}
                                onKeyDown={onKeyDown}
                                onFocus={handleFocus}
                                value={formattedValue}
                                onChange={handleChange}
                                placeholder={translate(placeLabel)}
                                maxLength={maxLength}
                                autoComplete={autoComplete}
                                {...sharedProps}
                                {...restProps}
                            />
                        ) : (
                            <div {...sharedProps}>
                                <div className='text-ellipsis'>
                                    {children || value || translate(placeLabel)}
                                </div>
                            </div>
                        )}
                    </div>
                    <Collapse open={!!error?.length}>
                        <Information appearance='inline'>
                            <Translate>{error}</Translate>
                        </Information>
                    </Collapse>
                </>
            )}
        </div>
    );
});

Input.propTypes = inputProps;

export default Input;
