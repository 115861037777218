import { getMetadata } from 'video-metadata-thumbnails';
import React from 'react';

import addToast from './addToast';

import Translate from '../components/translate';

import { fileUploadSupportedFormats } from '../constants/construct';

import { getImageMetadata } from '../helpers';

import getImageThumbnail from './getImageThumbnail';
import getVideoThumbnail from './getVideoThumbnail';

const preprocessMediaFile = file => {
    const type = file.type.split('/')[0];

    const name = encodeURI(file.name);

    const nameParts = file.name.split('.');

    const extension = nameParts?.length ? nameParts[nameParts.length - 1] : '';

    return new Promise((resolve, reject) => {
        if (fileUploadSupportedFormats.includes(extension.toLowerCase())) {
            try {
                if (type === 'video') {
                    getMetadata(file).then(({ height, width, duration }) => getVideoThumbnail(file, width, height).then(data => {
                        resolve({
                            name,
                            type,
                            width,
                            height,
                            duration,
                            extension,
                            size: file.size,
                            color: data.color,
                            thumbnail: data.blob,
                            thumbnailUrl: data.url,
                            blurHash: data.blurHash,
                            blobUrl: URL.createObjectURL(file),
                        });
                    }).catch(reject));
                }
                if (type === 'image') {
                    getImageMetadata(file).then(({ height, width }) => getImageThumbnail(file, width, height).then(data => {
                        resolve({
                            name,
                            type,
                            width,
                            height,
                            extension,
                            size: file.size,
                            color: data.color,
                            thumbnail: data.blob,
                            thumbnailUrl: data.url,
                            blurHash: data.blurHash,
                            blobUrl: URL.createObjectURL(file),
                        });
                    }).catch(reject));
                }
            } catch (e) {
                reject(e);
            }
        } else {
            addToast({
                type: 'error',
                title: <Translate>Unsupported Format</Translate>,
                message: <Translate>We are not supporting this format yet!</Translate>,
            });
            reject({
                code: 'UNSUPPORTED_FORMAT',
                message: 'Unsupported Format',
            });
        }
    });
};

export default preprocessMediaFile;
