const getTinyColor = async props => {
    const {
        dark,
        over,
        light,
        color,
    } = props;

    const amount = 5;

    return await import('tinycolor2').then(({ default: tinyColor }) => {
        const toRGB = color => {
            const { r, g, b } = tinyColor(color).toRgb();
            return `${r}, ${g}, ${b}`;
        };

        const lightness = color => parseInt(tinyColor(color).toHsl().l * 100);

        const setColor = lightness(color || 'white') > 60 ? (dark?.value ? tinyColor(dark?.value).darken(dark?.amount) : dark) : light;

        return ({
            [`--${over}`]: color,
            [`--${over}-hover`]: lightness(color) > 50 ? tinyColor(color).darken(amount).toString() : tinyColor(color).lighten(amount).toString(),
            [`--${over}-sc`]: setColor,
            [`--${over}-rgb`]: toRGB(color),
            [`--${over}-sc-rgb`]: toRGB(setColor),
        });
    });
};

export default getTinyColor;
