import PropTypes from 'prop-types';

import { uiKitCornerRadius, uiKitSizes } from '../../props';

export const inputConfig = {
    type: [
        'text',
        'tel',
        'date',
        'time',
        'email',
        'number',
        'search',
        'price',
        'select',
        'password',
    ],
    size: uiKitSizes,
    cornerRadius: uiKitCornerRadius,
};

export const inputProps = {
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.oneOf(inputConfig.type),
    icon: PropTypes.string,
    name: PropTypes.string,
    left: PropTypes.any,
    right: PropTypes.any,
    size: PropTypes.oneOf(inputConfig.size),
    value: PropTypes.any,
    label: PropTypes.any,
    error: PropTypes.any,
    onBlur: PropTypes.func,
    loading: PropTypes.bool,
    postfix: PropTypes.string,
    onKeyUp: PropTypes.func,
    onFocus: PropTypes.func,
    onPaste: PropTypes.func,
    onClick: PropTypes.func,
    pattern: PropTypes.any,
    children: PropTypes.any,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    inputRef: PropTypes.any,
    required: PropTypes.bool,
    skeleton: PropTypes.bool,
    maxLength: PropTypes.number,
    className: PropTypes.string,
    onKeyDown: PropTypes.func,
    autoFocus: PropTypes.bool,
    persistValue: PropTypes.bool,
    placeholder: PropTypes.any,
    autoComplete: PropTypes.string,
    cornerRadius: PropTypes.oneOf(inputConfig.cornerRadius),
};
