import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import React, { Suspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Loader from '../loader';
import HotJarData from '../hotJarData';

import lazyWithRetry from '../../utils/lazyWithRetry';

import { campaignDetailsState, reviewCampaignState, showAuthenticationModalState, signedInState } from '../../state';

const Modal = lazyWithRetry(() => import('../modal').then());
const ReviewCampaign = lazyWithRetry(() => import('../reviewCampaign').then());
const CampaignDetails = lazyWithRetry(() => import('../campaignDetails').then());
const Authentication = lazyWithRetry(() => import('../../containers/authentication').then());

const SharedComponents = () => {
    const signedIn = useRecoilValue(signedInState);
    const reviewCampaign = useRecoilValue(reviewCampaignState);
    const campaignDetails = useRecoilValue(campaignDetailsState);
    const resetReviewCampaign = useResetRecoilState(reviewCampaignState);
    const resetCampaignDetails = useResetRecoilState(campaignDetailsState);
    const [showAuthenticationModal, setShowAuthenticationModal] = useRecoilState(showAuthenticationModalState);

    const location = useLocation();

    useEffect(() => {
        !!reviewCampaign.id && resetReviewCampaign();
        !!campaignDetails.id && resetCampaignDetails();
    }, [location]); // eslint-disable-line

    return (
        <>
            {signedIn && (
                <>
                    <Suspense fallback={null}>
                        <ReviewCampaign
                            id={reviewCampaign.id}
                            mode={reviewCampaign.mode}
                            active={reviewCampaign.active}
                            onClose={(hasChange, declined) => {
                                (hasChange && typeof reviewCampaign.callBack === 'function') && reviewCampaign.callBack(declined);
                                resetReviewCampaign();
                            }}
                        />
                    </Suspense>
                    <Suspense fallback={null}>
                        <CampaignDetails
                            id={campaignDetails.id}
                            mode={campaignDetails.mode}
                            onClose={resetCampaignDetails}
                            active={campaignDetails.active}
                        />
                    </Suspense>
                </>
            )}
            <HotJarData />
            {showAuthenticationModal !== null && (
                <Suspense fallback={null}>
                    <Modal
                        fullCover
                        width={460}
                        secondaryButton={null}
                        active={showAuthenticationModal}
                        onClose={() => setShowAuthenticationModal(false)}
                    >
                        <Suspense fallback={<Loader placement='auth'/>}>
                            <Authentication
                                external
                                onAuth={() => setShowAuthenticationModal(false)}
                            />
                        </Suspense>
                    </Modal>
                </Suspense>
            )}
        </>
    );
};

export default SharedComponents;
