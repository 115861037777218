import React, { forwardRef } from 'react';
import classNames from 'classnames';

import Icon from '../icon';

import Input from '../input';

import { searchConfig, searchProps } from './props';

import './index.scss';

const Search = forwardRef(function Search(props, ref) {
    const {
        value,
        onBlur,
        onFocus,
        onChange,
        readOnly,
        autoFocus,
        onKeyDown,
        placeholder,
        size = searchConfig.size[0],
        appearance = searchConfig.appearance[0],
        cornerRadius = searchConfig.cornerRadius[0],
    } = props;

    const sharedProps = {
        value,
        onBlur,
        onFocus,
        onChange,
        readOnly,
        autoFocus,
        onKeyDown,
        type: 'search',
        'aria-label': placeholder,
        className: 'data-hj-allow',
    };

    return (
        <div
            ref={ref}
            className={classNames(
                `search-input-holder`,
                `a-${appearance}`,
                `s-${size}`,
                {
                    'filled': value,
                }
            )}
        >
            {(() => {
                switch (appearance) {
                    case 'extra':
                        return (
                            <>
                                <input
                                    {...sharedProps}
                                />
                                <label>
                                    <Icon type='icon-a-search' />
                                    <p>{placeholder}</p>
                                </label>
                            </>
                        );
                    case 'inline':
                        return (
                            <>
                                <div className='inline-search-holder'>
                                    <Icon type='icon-a-search' />
                                    <input
                                        placeholder={placeholder}
                                        {...sharedProps}
                                    />
                                </div>
                                <div className='inline-search-line' />
                            </>
                        );
                    default:
                        return (
                            <Input
                                size={size}
                                cornerRadius={cornerRadius}
                                placeholder={placeholder}
                                {...sharedProps}
                            />
                        );
                }
            })()}
        </div>
    );
});

Search.propTypes = searchProps;

export default Search;
