import languages from '../configs/languages';

const landingRouteURLs = [
    '/',
    ...languages.flatMap(language => `/${language.code}`),
    '/yerevan',
    '/j',
    '/d',
    '/e',
];

export default landingRouteURLs;
