import { Collapse as ReactCollapse } from '@kunukn/react-collapse';
import { conditionalSpread, rem } from 'clyne-core';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '../button';

import './index.scss';

const Collapse = props => {
    const {
        top,
        open,
        label,
        bottom,
        children,
        className,
        removeOnClose = true,
    } = props;

    const [isOpen, setIsOpen] = useState(open);

    useEffect(() => {
        setIsOpen(!!open);
    }, [open]);

    const childrenRenderer = (
        <div
            className={classNames(
                'r-collapse-children-holder',
                className,
            )}
            style={{
                ...conditionalSpread({
                    marginTop: typeof top === 'string' ? top : rem(top),
                }, top),
                ...conditionalSpread({
                    marginBottom: typeof bottom === 'string' ? bottom : rem(bottom),
                }, bottom),
            }}
        >
            {children}
        </div>
    );

    const contentRenderer = () => (
        <div className='collapse-css-transition-holder'>
            <ReactCollapse
                addState
                isOpen={!!isOpen}
            >
                {state => removeOnClose ? state !== 'collapsed' && (
                    childrenRenderer
                ) : childrenRenderer}
            </ReactCollapse>
        </div>
    );

    return label ? (
        <div className='collapse-holder'>
            <ul
                className='collapse-label-holder button-external-hover'
                onClick={() => setIsOpen(val => !val)}
            >
                <li className='text-ellipsis'>
                    {label}
                </li>
                <li>
                    <Button
                        color='accent'
                        size='small'
                        icon={{
                            size: 22,
                            type: isOpen ? 'icon-a-minus' : 'icon-a-plus',
                        }}
                    />
                </li>
            </ul>
            {contentRenderer()}
        </div>
    ) : (
        contentRenderer()
    );
};

Collapse.propTypes = {
    top: PropTypes.any,
    open: PropTypes.any,
    label: PropTypes.any,
    bottom: PropTypes.any,
    children: PropTypes.any,
    className: PropTypes.string,
    removeOnClose: PropTypes.bool,
};

export default Collapse;
