import React, { Suspense, useState } from 'react';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Input from '../input';
import BlurHash from '../blurHash';
import Translate from '../translate';

import translate from '../../utils/translate';
import lazyWithRetry from '../../utils/lazyWithRetry';

import { crState, modeState } from '../../state';

import useWindowResize from '../../hooks/useWindowResize';

import { isWhiteLabel } from '../../helpers';

import './index.scss';

const path = '/assets/media/landing';
const ComboLocationSearch = lazyWithRetry(() => import('../comboLocationSearch'));

const Intro = props => {
    const {
        location = 'landing',
    } = props;

    const cr = useRecoilValue(crState);
    const mode = useRecoilValue(modeState);

    const [loaded, setLoaded] = useState(false);

    const {
        screenWidth,
    } = useWindowResize();

    const suffix = isWhiteLabel ? 'WhiteLabel' : '';
    const middle = isWhiteLabel ? '' : (mode === 'dark' ? 'Dark' : 'Light');

    const mediaMapping = {
        desktop: {
            jpeg: [`${path}${middle}${suffix}.jpg`, `${path}${middle}@2x${suffix}.jpg`],
            webp: [`${path}${middle}${suffix}.webp`, `${path}${middle}@2x${suffix}.webp`],
            blurHash: isWhiteLabel ? 'L17-dD9W3E^L0X{J]%9s00GJwI$R' : mode === 'dark' ? 'L21zMrR:#Mnds=RTtAaxxuXFs*j^' : 'L9A-V++Y@ENi|4[U#5tQ^}x_wPv_',
        },
        mobile: {
            jpeg: [`${path}Mobile${middle}${suffix}.jpg`, `${path}Mobile${middle}@2x${suffix}.jpg`],
            webp: [`${path}Mobile${middle}${suffix}.webp`, `${path}Mobile${middle}@2x${suffix}.webp`],
            blurHash: isWhiteLabel ? 'L16*Xp00L3$*04^+q@bX00;4tSE1' : mode === 'dark' ? 'L314JLf,o~jCf?akndWbv]f3oHjZ' : 'LC9ug#EH-}wPv{P9TN,,{HEMxb$K',
        },
    };

    const isRetina = window.devicePixelRatio >= 2;

    const isJpeg = document.documentElement.classList.contains('no-webp');

    return (
        <div
            key={screenWidth}
            className={classNames(
                `intro-holder`,
                `l-${location}`,
                {
                    suffix,
                }
            )}
        >
            {location === 'landing' ? (
                <>
                    <div className='i-oes-tag'>
                        <h3>
                            {translate(cr?.meta?.description)}
                        </h3>
                        <h4>
                            <Translate>Digital Outdoor Advertising in Yerevan</Translate>
                        </h4>
                        <h5>
                            <Translate>Digital outdoor advertising on billboards</Translate>
                        </h5>
                    </div>
                    <BlurHash
                        active={!loaded}
                        hash={mediaMapping[document.documentElement.clientWidth < 577 ? 'mobile' : 'desktop'].blurHash}
                    />
                    <img
                        alt='Homepage Intro'
                        onLoad={() => setLoaded(true)}
                        src={mediaMapping[document.documentElement.clientWidth < 577 ? 'mobile' : 'desktop'][isJpeg ? 'jpeg' : 'webp'][isRetina ? 1 : 0]}
                        className={classNames(
                            'landing-splash-holder',
                            'absolute-splash',
                            {
                                'animate': loaded,
                            }
                        )}
                    />
                    <div className='landing-intro-content-holder'>
                        <svg
                            width='170'
                            height='170'
                            viewBox='0 0 170 170'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            className={classNames(
                                'landing-intro-mark',
                                {
                                    active: loaded
                                }
                            )}
                        >
                            <path d='M141.708 22.4225L141.71 22.4238C159.373 38.2579 169.5 61.091 169.5 85.0791V155.051C169.5 163.019 163.024 169.5 155.063 169.5C147.103 169.5 140.627 163.019 140.627 155.051V149.628C140.627 149.433 140.513 149.256 140.336 149.174C140.159 149.093 139.951 149.121 139.802 149.248C124.441 162.331 105.081 169.5 84.9986 169.5C38.4089 169.5 0.5 131.71 0.5 85.0791C0.5 38.4472 38.4101 0.5 84.9986 0.5C105.918 0.5 126.055 8.28322 141.708 22.4225ZM84.9986 140.587C115.67 140.587 140.627 115.698 140.627 85.0791C140.627 54.3835 115.585 29.413 84.9986 29.413C54.4101 29.413 29.5286 54.4635 29.5286 85.0791C29.5286 115.696 54.3252 140.587 84.9986 140.587Z' stroke='url(#paint0_linear_2772_1745)' strokeLinejoin='round' />
                            <defs>
                                <linearGradient id='paint0_linear_2772_1745' x1='-38.1633' y1='-18.3938' x2='252.398' y2='78.749' gradientUnits='userSpaceOnUse'>
                                    <stop stopColor='#85FF3C' />
                                    <stop offset='0.5' stopColor='#F6FF96' />
                                    <stop offset='1' stopColor='#FF7FE4' />
                                </linearGradient>
                            </defs>
                        </svg>
                        <h1>
                            <Translate>All AD Screens in one place</Translate>
                        </h1>
                        <h2>
                            <Translate>Grow your business with outdoor advertising from anywhere, anytime and on any budget</Translate>
                        </h2>
                        <div className='landing-intro-search-holder'>
                            <Suspense fallback={null}>
                                <ComboLocationSearch />
                            </Suspense>
                        </div>
                    </div>
                </>
            ) : (
                <ul className='intro-search-holder'>
                    <li className='span'>
                        <Translate>What can we help you with?</Translate>
                    </li>
                    <li>
                        <Input
                            type='search'
                            size='extra-big'
                            cornerRadius='full'
                            placeholder={translate('Search for articles')}
                        />
                    </li>
                </ul>
            )}
        </div>
    );
};

Intro.propTypes = {
    location: PropTypes.oneOf([
        'landing',
        'help',
    ]),
};

export default Intro;
