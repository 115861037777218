import { useRecoilValue } from 'recoil';
import { useEffect } from 'react';

import integrations from '../configs/integrations';

import { loadGoogleScriptState } from '../state';

const useLoadGoogleScript = () => {
    const loadGoogleScript = useRecoilValue(loadGoogleScriptState);

    useEffect(() => {
        if (loadGoogleScript) {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${integrations.google.maps.api.key}&libraries=places,geometry&callback=googleScriptsReady`;
            script.setAttribute('onload', `this.setAttribute('data-status', 'ready')`);
            document.head.appendChild(script);
        }
    }, [loadGoogleScript]);
};

export default useLoadGoogleScript;
