import { paginationConfig } from '../components/pagination/props';

const convertObjectToParamsArray = (input, action) => Object.keys(input).map(key => {
    const item = input[key];
    return action === 'get' ? key : [key, Array.isArray(item) ? item.join(',') : item];
});

const searchParamsConstructor = (query, input, action, formatter, withPagination = false) => {
    let searchParams = new URLSearchParams(query);
    const tmp = {};

    if (withPagination) {
        !searchParams.has('page') && searchParams.set('page', '1');
        !searchParams.has('limit') && searchParams.set('limit', paginationConfig.options[0].value.toString());
    }

    const items = Array.isArray(input) ? input : convertObjectToParamsArray(input, action);

    items.forEach(item => {
        if (action === 'set') {
            item[1] === '' ? searchParams.delete(item[0]) : searchParams.set(item[0], item[1]);
        } else if (searchParams.has(`${item}`)) {
            const value = searchParams.get(`${item}`);
            tmp[item] = typeof formatter === 'function' ? formatter(item, value) : value;
        }
    });

    return action === 'set' ? searchParams.toString() : tmp;
};

export default searchParamsConstructor;
