import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import React from 'react';

import Tab from '../tab';
import Tabs from '../tabs';
import MiniNotifications from '../miniNotifications';

import useDevice from '../../hooks/useDevice';

import { dynamicDataState } from '../../state';

const Notifications = props => {
    const {
        body = true,
        head = true,
        notificationsAreRead,
        miniNotificationsData,
        setNotificationsSelectedTab,
        selectedMiniNotificationsId,
        miniNotificationsScrollerRef,
    } = props;

    const dynamicData = useRecoilValue(dynamicDataState);

    const {
        isMobile,
    } = useDevice();

    return (
        <Tabs
            body={body}
            head={head}
            selected={selectedMiniNotificationsId}
            onChange={val => setNotificationsSelectedTab(Object.keys(miniNotificationsData)[val])}
        >
            {Object.keys(miniNotificationsData).map(key => (
                <Tab
                    key={key}
                    badge={dynamicData[key].count}
                    title={miniNotificationsData[key].title}
                >
                    <MiniNotifications
                        mode={key}
                        notificationsAreRead={notificationsAreRead}
                        scrollElement={isMobile ? '.popover-c' : miniNotificationsScrollerRef}
                    />
                </Tab>
            ))}
        </Tabs>
    );
};

Notifications.propTypes = {
    body: PropTypes.bool,
    head: PropTypes.bool,
    notificationsAreRead: PropTypes.object,
    miniNotificationsData: PropTypes.object,
    setNotificationsSelectedTab: PropTypes.func,
    selectedMiniNotificationsId: PropTypes.number,
    miniNotificationsScrollerRef: PropTypes.any,
};

export default Notifications;
