import React, { forwardRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import MobileStack from '../mobileStack';

import useDevice from '../../hooks/useDevice';

import './index.scss';

const Section = forwardRef(function Section(props, ref) {
    const {
        gap,
        left,
        right,
        title,
        useH1,
        columns,
        children,
        subTitle,
        className,
        mobileStack = true,
        appearance = 'bold',
    } = props;

    const { isMobile } = useDevice();

    return (
        <div
            ref={ref}
            className={classNames(
                `section-holder`,
                `a-${appearance}`,
            )}
        >
            {(title || left || right) && (
                <ul className='section-head'>
                    <li>
                        {left}
                        {!!title && (
                            <div className='section-titles-holder'>
                                {useH1 ? (
                                    <h1>{title}</h1>
                                ) : (
                                    <h2>{title}</h2>
                                )}
                                {!!subTitle && (
                                    <h3>
                                        {subTitle}
                                    </h3>
                                )}
                            </div>
                        )}
                    </li>
                    {!!right && (
                        <li>
                            {right}
                        </li>
                    )}
                </ul>
            )}
            <div className='section-body'>
                <MobileStack enabled={mobileStack}>
                    {columns ? (
                        <div
                            className={classNames(
                                `grid`,
                                className,
                                {
                                    [`gap-${gap}`]: gap,
                                    [`cols-${Array.isArray(columns) ? columns.join('-') : columns}`]: isMobile ? mobileStack : true,
                                }
                            )}
                        >
                            {children}
                        </div>
                    ) : (
                        children
                    )}
                </MobileStack>
            </div>
        </div>
    );
});

Section.propTypes = {
    gap: PropTypes.number,
    left: PropTypes.any,
    right: PropTypes.any,
    title: PropTypes.any,
    useH1: PropTypes.bool,
    columns: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.number),
    ]),
    children: PropTypes.any,
    subTitle: PropTypes.any,
    className: PropTypes.string,
    appearance: PropTypes.oneOf([
        'bold',
        'elegant'
    ]),
    mobileStack: PropTypes.bool,
};

export default Section;
