import { useEffect, useState } from 'react';

const useComponentRendered = () => {
    const [componentRendered, setComponentRendered] = useState(false);

    useEffect(() => {
        setComponentRendered(true);
    }, []);

    return componentRendered;
};

export default useComponentRendered;
