import { useEffect, useState } from 'react';
import { fromEvent } from 'rxjs';

const useElementResize = (ref, rerender) => {
    const [width, setWidth] = useState(ref?.current?.clientWidth || 0);
    const [height, setHeight] = useState(ref?.current?.clientHeight || 0);

    useEffect(() => {
        let targetObserver, subscription;

        if (ref.current) {
            ref.current.clientWidth && setWidth(ref.current.clientWidth);
            ref.current.clientHeight && setHeight(ref.current.clientHeight);
        }

        if (window.ResizeObserver) {
            targetObserver = new ResizeObserver(entries => {
                setWidth(entries[0].target.clientWidth);
                setHeight(entries[0].target.clientHeight);
            });

            if (ref.current) {
                targetObserver && targetObserver.observe(ref.current);
            }
        } else {
            subscription = fromEvent(window, 'resize').subscribe(() => {
                setWidth(ref.current.clientWidth);
                setHeight(ref.current.clientHeight);
            });
        }

        return () => {
            targetObserver && targetObserver.disconnect();
            subscription && subscription.unsubscribe();
        };
    }, [ref.current]); // eslint-disable-line

    useEffect(() => {
        if (ref.current && ref.current.clientWidth && ref.current.clientHeight) {
            setWidth(ref.current.clientWidth);
            setHeight(ref.current.clientHeight);
        }
    }, [ref.current, rerender]); // eslint-disable-line

    return { width, height };
};

export default useElementResize;
