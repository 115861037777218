import PropTypes from 'prop-types';

import { searchConfig } from '../search/props';

export const locationSearchProps = {
    size: PropTypes.oneOf(searchConfig.size),
    urlMode: PropTypes.bool,
    appearance: PropTypes.oneOf(['extra', 'compact', 'icon']),
    cornerRadius: PropTypes.oneOf(searchConfig.cornerRadius),
};
