import { catchError, distinctUntilKeyChanged, map, pluck, tap } from 'rxjs/operators';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { BehaviorSubject, from, of } from 'rxjs';
import { conditionalSpread } from 'clyne-core';

import { dynamicDataTriggerState, workspaceIdState } from '../state';

import { GEOLOCATION, PROFILE, URLS } from '../constants/apiKeys';

import { clearLS, getLSItem, setLSItem, geoLocationKey } from '../helpers';

import linkedScreens from '../tmp/linkedScreens';

import createAxios from '../utils/createAxios';
import handleSuccessResponse from '../utils/handleSuccessResponse';

import authService from './authService';

const constructFallbackGeolocations = dataToReturn => ({
    ...(dataToReturn || {}),
    data: {
        ...(dataToReturn?.data || {}),
        country_code: dataToReturn?.data?.country_code || 'AM',
        city: dataToReturn?.data?.city || 'Yerevan',
        latitude: dataToReturn?.data?.latitude || 40.1817,
        longitude: dataToReturn?.data?.longitude || 44.5099,
        currency: dataToReturn?.data?.currency || 'AMD',
        location: {
            ...(dataToReturn?.data?.location || {}),
            languages: dataToReturn?.data?.location?.languages || {
                hy: 'Armenian language',
            },
        },
        time_zone: {
            ...(dataToReturn?.data?.time_zone || {}),
            id: dataToReturn?.data?.time_zone?.id || 'Asia/Yerevan',
        },
    },
});

class ConnectionService {
    constructor() {
        this.callsParams = new BehaviorSubject({});
        this.connectors = {
            ...(getLSItem(geoLocationKey) ? { [GEOLOCATION]: of(getLSItem(geoLocationKey)) } : {})
        };
        this._user = {};
        this._lastRefresh = new Date().valueOf();
        this.axios = createAxios({
            validateStatus: function (status) {
                status === 401 && authService.signOut(true);
                return status >= 200 && status < 500;
            },
        });
        this.getJson(authService.isSignedIn.getValue() ? PROFILE : GEOLOCATION, null, true, false, false).subscribe(val => {
            const lsCurrency = getLSItem('currency');
            const lsLanguage = getLSItem('language');

            this.axios.defaults.headers.common['X-Currency'] = lsCurrency || val?.data?.currency;
            this.axios.defaults.headers.common['X-Language'] = lsLanguage || val?.data?.language;
            this.axios.defaults.headers.common['X-Timezone'] = val?.data?.timezone || Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'Asia/Yerevan';
        });
    }

    updateParams(name, newParams, margeParams) {
        const currentParams = this.callsParams.getValue();
        const params = margeParams ? { ...currentParams[name], ...newParams } : newParams;
        this.callsParams.next({
            ...currentParams,
            [name]: params
        });
        return params;
    }

    callsMiddleware(key, payload, res, method, useDefaultHandler = true) {
        const workspaceMode = !!getLSItem('workspaceMode');
        const lsCurrency = getLSItem('currency');
        const lsLanguage = getLSItem('language');
        const rememberMe = getLSItem('rememberMe');

        if (authService.isSignedIn.getValue() && rememberMe && this._lastRefresh < new Date().valueOf() - 10000) {
            authService._getRefreshToken();
            this._lastRefresh = new Date().valueOf();
        }

        if (method !== 'get') {
            setRecoil(dynamicDataTriggerState, getRecoil(dynamicDataTriggerState) + 1);
        }

        if (res.data?.status) {
            switch (URLS[key]) {
                case URLS[PROFILE]:
                    if (authService.isSignedIn.getValue()) {
                        this._user = payload || res?.data?.data || null;
                        this._user?.currency && setLSItem('currency', this._user.currency);
                    }
                    break;
                case URLS[GEOLOCATION]:
                    if (res?.data) {
                        const data = constructFallbackGeolocations(res.data);
                        const currency = data?.data?.currency;
                        setLSItem(geoLocationKey, data);
                        !getLSItem('currency') && currency && setLSItem('currency', currency);
                    }
                    break;
                default:
                    break;
            }
            this.axios.defaults.headers.common['X-Language'] = lsLanguage || 'en';
            this.axios.defaults.headers.common['X-Currency'] = [
                ...conditionalSpread([
                    this._user?.workspace?.currency,
                ], workspaceMode),
                this._user?.currency,
                lsCurrency,
                'AMD',
            ].filter(Boolean)[0];
        } else {
            (method !== 'get' && useDefaultHandler) && handleSuccessResponse(res, () => {
            });
        }
    }

    getJson(name, params = {}, save = true, margeParams = false, returnData = true) {
        const newParams = this.updateParams(name, params, margeParams);
        const saveKey = `${name}${newParams ? '_' + JSON.stringify(newParams) : ''}`;
        if (save && this.connectors[saveKey]) {
            return this.connectors[saveKey].pipe(
                map(data => (returnData && data?.data) || data)
            );
        }
        const connector = from(this.axios.get(URLS[name] || name,
            { params: { ...newParams } })
        ).pipe(
            tap(res => this.callsMiddleware(name, params, res, 'get')),
            map(res => {
                const dataToReturn = returnData ? res?.data?.data : res?.data;

                if (name === GEOLOCATION) {
                    return constructFallbackGeolocations(dataToReturn);
                } else if (name.includes('/screens')) {
                    const workspaceId = getRecoil(workspaceIdState);

                    const mapScreen = screen => {
                        const linked = linkedScreens.find(group => group.some(id => id === screen?.id));
                        return ({
                            ...screen,
                            comingSoon: workspaceId === screen.workspaceId ? false : screen.comingSoon,
                            ...conditionalSpread({
                                linked,
                            }, linked),
                        });
                    };

                    return dataToReturn?.constructor === Object ? ({
                        ...dataToReturn,
                        data: Array.isArray(dataToReturn.data) ? dataToReturn.data.map(mapScreen) : dataToReturn.data,
                    }) : Array.isArray(dataToReturn) ? dataToReturn.map(mapScreen) : dataToReturn;
                } else {
                    return dataToReturn;
                }
            }),
            catchError(val => of(val.response))
        );
        save && !this.connectors[saveKey] && (this.connectors[saveKey] = connector);
        return connector;
    }

    putJson(name, params, useDefaultHandler = true) {
        return from(this.axios.put(URLS[name] || name, params)).pipe(
            tap(res => this.callsMiddleware(name, params, res, 'put', useDefaultHandler))
        );
    }

    postJson(name, params = {}) {
        return from(this.axios.post(URLS[name] || name, params)).pipe(
            tap(res => this.callsMiddleware(name, params, res))
        );
    }

    deleteJson(name) {
        return from(this.axios.delete(name).then(res => res));
    }

    getParamsByName(name = null) {
        return this.callsParams.pipe(
            name && distinctUntilKeyChanged(name),
            name && pluck(name)
        );
    }

    resetAllConnectors(clearLocalStorage = true) {
        this.connectors = {
            ...(getLSItem(geoLocationKey) ? { [GEOLOCATION]: of(getLSItem(geoLocationKey)) } : {})
        };
        clearLocalStorage && clearLS();
    }

    getConnector(name) {
        return this.connectors[name] || this.getJson(name);
    }
}

export default new ConnectionService(); // eslint-disable-line
