import PropTypes from 'prop-types';

export const paginationConfig = {
    options: [
        {
            name: '10',
            value: 10,
        },
        {
            name: '30',
            value: 30,
        },
        {
            name: '50',
            value: 50,
        },
    ],
};

export const paginationProps = {
    total: PropTypes.number,
    perPage: PropTypes.number,
    setPerPage: PropTypes.func,
    currentPage: PropTypes.number,
    setCurrentPage: PropTypes.func,
};
