const integrations = {
    google: {
        maps: {
            api: {
                key: 'AIzaSyA4rjcOhX53j21VfxThXga4Md80QLmb11g',
            },
        },
    },
    hubSpot: {
        api: {
            endpoint: 'https://api.hsforms.com/submissions/v3/integration/submit/',
        },
        portal: 6787260,
        forms: {
            subscribe: 'e9aef57b-0481-40cb-bb5d-87cee9ed1900',
            partnership: '4c68391e-1b77-40be-bbf3-5cfe7be6d63b',
        },
    },
    streamChat: {
        api: {
            key: 'j4dc4g8ssjwa',
        },
    },
};

export default integrations;
