import { Store } from 'react-notifications-component';
import { conditionalSpread } from 'clyne-core';

import Translate from '../components/translate';

import { guid } from '../helpers';

import { toastConfig } from '../components/toaster/props';

const addToast = options => {
    const toast = {
        ...toastConfig.default,
        ...options,
        ...conditionalSpread({
            dismiss: {
                ...toastConfig.default.dismiss,
                ...options.dismiss,
            }
        }, options?.dismiss),
    };

    const notification = {
        id: guid(),
        ...toast,
        ...conditionalSpread({
            title: <Translate>{toastConfig.type[toast.type]?.title}</Translate>,
        }, !toast.title),
        type: toastConfig.type[toast.type]?.type || toastConfig.default.type,
        message: (
            <ul className='toast-contents-holder'>
                <li>
                    {toast?.message || toastConfig.type[toast.type]?.title}
                </li>
                {!!toast.cta && (
                    <li className='toast-contents-cta'>
                        {toast.cta}
                    </li>
                )}
            </ul>
        ),
    };

    if (document.querySelector('.rnc__base')) {
        Store.addNotification(notification);
    } else {
        setTimeout(() => {
            document.dispatchEvent(new CustomEvent('loadToaster', {
                detail: notification,
            }));
        }, 0);
    }

    return notification;
};

export default addToast;
