import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

import { dummyAtom, languageState } from '../state';

import connectionService from '../services/connectionService';

import recursiveFindAndReplaceObjectValuesByKey from '../utils/recursiveFindAndReplaceObjectValuesByKey';

const useConnector = (name, initialValue = [], params, saveData, condition = true, method = 'replace', dependency, isTranslatable = false, atom = dummyAtom) => {
    const setAtomValue = useSetRecoilState(atom);
    const [data, setData] = useState(initialValue);
    const [rawData, setRawData] = useState(initialValue);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [initialLoading, setInitialLoading] = useState(true);

    const language = useRecoilValue(languageState);

    useEffect(() => {
        language && isTranslatable && setData(val => recursiveFindAndReplaceObjectValuesByKey(val, 'name'));
    }, [language, isTranslatable]);

    useEffect(() => {
        setLoading(true);
        const subscription = !!condition && connectionService.getJson(name, params, saveData, false, false).subscribe(val => {
            setLoading(false);
            setInitialLoading(false);
            const translatedResponse = isTranslatable && val?.data ? { ...val, data: recursiveFindAndReplaceObjectValuesByKey(val?.data, 'name') } : val;
            setData(oldVal => method === 'replace' ? translatedResponse?.data : [...(oldVal || []), ...(translatedResponse?.data || [])]);
            setRawData(translatedResponse);
            (!total || total !== val?.total) && setTotal(val?.total || 0);
        });
        return () => {
            !!condition && subscription && subscription.unsubscribe();
            setLoading(false);
            setInitialLoading(false);
        };
    }, [condition, dependency]); // eslint-disable-line

    const hasAtom = JSON.stringify(dummyAtom) !== JSON.stringify(atom);

    useEffect(() => {
        hasAtom && setAtomValue(data);
    }, [hasAtom, data]); // eslint-disable-line

    return {
        data,
        total,
        setData,
        rawData,
        loading,
        initialLoading,
    };
};

export default useConnector;
