import { useEffect, useState } from 'react';

const useWindowResize = () => {
    const width = Math.min(window.innerWidth, window.screen.width);
    const height = Math.min(window.innerHeight, window.screen.height);

    const [screenWidth, setScreenWidth] = useState(width);
    const [screenHeight, setScreenHeight] = useState(height);

    useEffect(() => {
        const resize = () => {
            setScreenWidth(Math.min(window.innerWidth, window.screen.width));
            setScreenHeight(Math.min(window.innerHeight, window.screen.height));
        };

        window.addEventListener('resize', resize);

        return () => {
            window.removeEventListener('resize', resize);
        };
    }, []);

    return {
        screenWidth,
        screenHeight,
    };
};

export default useWindowResize;
