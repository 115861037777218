import classNames from 'classnames';
import React, { forwardRef } from 'react';

import To from '../to';
import Icon from '../icon';
import Tooltip from '../tooltip';

import translate from '../../utils/translate';

import { tagConfig, tagProps } from './props';

import './index.scss';

const Tag = forwardRef(function Tag(props, ref) {
    const {
        url,
        name,
        icon,
        times,
        title,
        target,
        tooltip,
        onClick,
        onMouseEnter,
        onMouseLeave,
        size = tagConfig.size[0],
        color = tagConfig.color[0],
        appearance = tagConfig.appearance[0],
        cornerRadius = tagConfig.cornerRadius[0],
        itemsDirection = tagConfig.itemsDirection[0],
    } = props;

    const contentRenderer = () => (
        <>
            {!!icon && (
                <Icon
                    size={icon.size}
                    type={icon.type}
                />
            )}
            <span className='text-ellipsis'>{name}</span>
            {!!times && (
                <small>x{times}</small>
            )}
            {!!tooltip && (
                <Tooltip
                    position='top'
                    content={translate(tooltip)}
                >
                    <span className='absolute-splash' />
                </Tooltip>
            )}
        </>
    );

    const sharedProps = {
        ref,
        title,
        onMouseEnter,
        onMouseLeave,
        [url ? 'onTap' : 'onClick']: onClick,
        className: classNames(
            `tag-holder`,
            `s-${size}`,
            `c-${color}`,
            `a-${appearance}`,
            `cr-${cornerRadius}`,
            `id-${itemsDirection}`,
            {
                'clickable': url || onClick,
            }
        ),
    };

    return (
        url ? (
            <To
                url={url}
                target={target}
                onTap={onClick}
                {...sharedProps}
            >
                {contentRenderer()}
            </To>
        ) : (
            <div {...sharedProps}>
                {contentRenderer()}
            </div>
        )
    );
});

Tag.propTypes = tagProps;

export default Tag;
