import { conditionalSpread, fakeArray } from 'clyne-core';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../icon';

import { signedInState } from '../../state';

import useRedirectToSignIn from '../../hooks/useRedirectToSignIn';

import './index.scss';

const Rating = props => {
    const {
        rate,
        label,
        onRate,
        type = 'fill',
        appearance = 'single',
    } = props;

    const signedIn = useRecoilValue(signedInState);

    const setShouldRedirect = useRedirectToSignIn();

    const [hoveredRating, setHoveredRating] = useState(0);

    const rateBar = count => fakeArray(count).map((_, index) => (
        <Icon
            size={14}
            key={index}
            type={type === 'fill' ? 'icon-a-star-fill' : 'icon-star'}
            {...conditionalSpread({
                onMouseEnter: () => setHoveredRating(index + 1),
            }, !!onRate)}
        />
    ));

    const rateToShow = hoveredRating || (rate || 0);

    return appearance === 'single' ? (
        <div className='short-rating-holder'>
            {rateBar(1)}
            <span>
                {rate || 0}
            </span>
        </div>
    ) : (
        <ul className='rating-bar-holder'>
            <li>
                <div
                    className={classNames(
                        'rating-bar',
                        {
                            'cursor-pointer': !!onRate,
                        }
                    )}
                    {...conditionalSpread({
                        onClick: () => {
                            if (signedIn) {
                                onRate(hoveredRating);
                            } else {
                                setShouldRedirect(true);
                            }
                        },
                        onMouseLeave: () => setHoveredRating(0),
                    }, !!onRate)}
                >
                    {rateBar(5)}
                </div>
                {(!!rate || !!onRate) && (
                    <div
                        className='rating-bar a-filled pointer-events-none'
                        style={{ maxWidth: rateToShow !== 5 ? `${20 * rateToShow}%` : '100%' }}
                    >
                        {rateBar(5)}
                    </div>
                )}
            </li>
            {label && (
                <li>
                    <div className='text-ellipsis'>
                        {label}
                    </div>
                </li>
            )}
        </ul>
    );
};

Rating.propTypes = {
    rate: PropTypes.number,
    label: PropTypes.any,
    type: PropTypes.oneOf([
        'fill',
        'outline',
    ]),
    onRate: PropTypes.func,
    appearance: PropTypes.oneOf([
        'single',
        'bar',
    ]),
};

export default Rating;
