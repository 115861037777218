import { conditionalSpread, fakeArray } from 'clyne-core';
import { useInView } from 'react-intersection-observer';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';

import Card from '../../components/card';
import Price from '../../components/price';
import Intro from '../../components/intro';
import Image from '../../components/image';
import Section from '../../components/section';
import Translate from '../../components/translate';
import MobileStack from '../../components/mobileStack';
import ScreenFeatured from '../../components/screenFeatured';
import OnBoardingGuide from '../../components/onBoardingGuide';
import IconLabelInsetCard from '../../components/iconLabelInsetCard';

import { genericState, languageState } from '../../state';

import { averageCalculator, beautifyPrice, isWhiteLabel } from '../../helpers';

import useDevice from '../../hooks/useDevice';
import useConnector from '../../hooks/useConnector';

import translate from '../../utils/translate';

import { homeSkeleton, placementShortcuts } from './data';

import { LANDING, TARGETING } from '../../constants/apiKeys';
import interestsDataMapping from '../../constants/interestsDataMapping';

import crossroads from './media/crossroads.jpg';
import highPlayback from './media/high-playback.jpg';
import highResolution from './media/high-resolution.jpg';
import lowBudget from './media/low-budget.jpg';
import shoppingCenter from './media/shopping-center.jpg';
import street from './media/street.jpg';
import taxi from './media/taxi.jpg';
import crossroads2x from './media/crossroads@2x.jpg';
import highPlayback2x from './media/high-playback@2x.jpg';
import highResolution2x from './media/high-resolution@2x.jpg';
import lowBudget2x from './media/low-budget@2x.jpg';
import shoppingCenter2x from './media/shopping-center@2x.jpg';
import street2x from './media/street@2x.jpg';
import taxi2x from './media/taxi@2x.jpg';
import crossroadsWEBP from './media/crossroads.webp';
import highPlaybackWEBP from './media/high-playback.webp';
import highResolutionWEBP from './media/high-resolution.webp';
import lowBudgetWEBP from './media/low-budget.webp';
import shoppingCenterWEBP from './media/shopping-center.webp';
import streetWEBP from './media/street.webp';
import taxiWEBP from './media/taxi.webp';
import crossroads2xWEBP from './media/crossroads@2x.webp';
import highPlayback2xWEBP from './media/high-playback@2x.webp';
import highResolution2xWEBP from './media/high-resolution@2x.webp';
import lowBudget2xWEBP from './media/low-budget@2x.webp';
import shoppingCenter2xWEBP from './media/shopping-center@2x.webp';
import street2xWEBP from './media/street@2x.webp';
import taxi2xWEBP from './media/taxi@2x.webp';

const shortcutsImageMapping = [
    {
        id: 1,
        image: {
            source: highPlayback,
            source2x: highPlayback2x,
            sourceWEBP: highPlaybackWEBP,
            source2xWEBP: highPlayback2xWEBP,
        },
        blurHash: 'LHD]^.xnD~V@_NxBIAWCaK$xxHS5',
    },
    {
        id: 2,
        image: {
            source: lowBudget,
            source2x: lowBudget2x,
            sourceWEBP: lowBudgetWEBP,
            source2xWEBP: lowBudget2xWEBP,
        },
        blurHash: 'L5B;Ey-tM3yC0]xuHwkD8$4.?Hrs',
    },
    {
        id: 3,
        image: {
            source: highResolution,
            source2x: highResolution2x,
            sourceWEBP: highResolutionWEBP,
            source2xWEBP: highResolution2xWEBP,
        },
        blurHash: 'LmD1HfWCtSahyZogkXj[pKWBjuj]',
    },
];

const Home = () => {
    const [shortcuts, setShortcuts] = useState(null);
    const [priceRanges, setPriceRanges] = useState(null);

    const generic = useRecoilValue(genericState);
    const language = useRecoilValue(languageState);

    const {
        isMobile,
    } = useDevice();

    const resolutions = generic?.resolutions;

    const [inViewRef, inView] = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    const { data } = useConnector(LANDING, undefined, undefined, undefined, inView, undefined, undefined, true);

    const { data: targetingData } = useConnector(TARGETING, undefined, undefined, undefined, inView);

    const topPlaces = data?.topPlaces?.filter(screen => screen?.placeTypeId !== 3);
    const lowBudgets = data?.lowBudgets;
    const highPlaybacks = data?.highPlaybacks;

    useEffect(() => {
        if (data?.priceRanges?.length) {
            setPriceRanges(data?.priceRanges);
        }
    }, [data?.priceRanges]);

    useEffect(() => {
        if (highPlaybacks && lowBudgets && resolutions && priceRanges) {
            const featuredResolutions = resolutions.filter(resolution => resolution.featured && resolution);
            const featuredResolutionsNames = featuredResolutions.map(resolution => resolution.name).join(', ');
            setShortcuts([
                {
                    id: 1,
                    url: '/s?highPlaybacks=1',
                    name: translate('High Playback Screens'),
                    description: translate('Screens with most play per day rate'),
                    ...highPlaybacks,
                },
                {
                    id: 2,
                    url: `/s?priceMin=${priceRanges[0]?.min || 0}&priceMax=${priceRanges[0]?.max || 0}`,
                    name: translate('Low Budget Screens'),
                    description: translate('Handpicked for you, because we value your money!'),
                    ...lowBudgets,
                },
                {
                    id: 3,
                    url: `/s?resolution=${featuredResolutions.map(resolution => resolution.id).join(',')}`,
                    name: translate('High Resolution screens'),
                    description: translate('_NAMES_ Screens', { '_NAMES_': featuredResolutionsNames }),
                    rating: averageCalculator([highPlaybacks.rating, lowBudgets.rating]),
                    screensCount: averageCalculator([highPlaybacks.screensCount, lowBudgets.screensCount]),
                    averagePrice: averageCalculator([highPlaybacks.averagePrice, lowBudgets.averagePrice]),
                },
            ]);
        }
    }, [highPlaybacks, lowBudgets, resolutions, priceRanges]);

    const topCards = [
        {
            title: <Translate>Discover Advelit</Translate>,
            icon: 'icon-a-megaphone',
            url: '/s',
            text: <Translate>LED, TV Screens & Tablets in Gyms, Taxis and Malls — strategic places to connect with your audiences.</Translate>,
        },
        {
            title: <Translate>LED Billboards</Translate>,
            icon: 'icon-a-screen',
            url: '/s?typeId=1',
            text: <Translate>Deliver an eye-catching large-scale viewing experience.</Translate>,
        },
        {
            title: <Translate>Taxis</Translate>,
            icon: 'icon-taxi',
            url: '/s?location=7',
            text: <Translate>Tap into the power of taxi ad screens. Compelling video content. Unmissable marketing.</Translate>,
        },
        {
            title: <Translate>Gyms</Translate>,
            icon: 'icon-dumbell-outline',
            url: '/s?location=8',
            text: <Translate>A perfect setting for health and fitness brands to connect with active consumers.</Translate>,
        },
        ...conditionalSpread([
            {
                title: <Translate>Shopping Malls</Translate>,
                icon: 'icon-shopping-bag-outline',
                url: '/s?location=2',
                text: <Translate>Significant application in big buildings like shopping malls, departmental and retail stores.</Translate>,
            },
        ], isMobile),
    ];

    const topPlaceTypeCards = [
        {
            name: translate('Taxi'),
            id: 7,
            image: {
                source: taxi,
                source2x: taxi2x,
                sourceWEBP: taxiWEBP,
                source2xWEBP: taxi2xWEBP,
            },
            blurHash: 'LCF5mh9ZDk9Y.Q5SxFRO*|T0~Uw|',
        },
        {
            name: translate('Shopping Center'),
            id: 2,
            image: {
                source: shoppingCenter,
                source2x: shoppingCenter2x,
                sourceWEBP: shoppingCenterWEBP,
                source2xWEBP: shoppingCenter2xWEBP,
            },
            blurHash: 'LLEfQeDitlIU~XMwx^ROvexuK7r?',
        },
        {
            name: translate('Crossroads'),
            id: 6,
            image: {
                source: crossroads,
                source2x: crossroads2x,
                sourceWEBP: crossroadsWEBP,
                source2xWEBP: crossroads2xWEBP,
            },
            blurHash: 'LNIXgX-pKKyD~C%2o~yDKO-;-Wsp',
        },
        {
            name: translate('Street'),
            id: 4,
            image: {
                source: street,
                source2x: street2x,
                sourceWEBP: streetWEBP,
                source2xWEBP: street2xWEBP,
            },
            blurHash: 'LjG+|3t:M{xv%%NfMwxaD%V?s+ax',
        },
    ];

    return (
        <div className='page-layout'>
            <Intro />
            <MobileStack>
                <div
                    className={classNames(
                        'grid',
                        'home-top-cards',
                        `cols-${topCards.length}`,
                    )}
                >
                    {topCards.map((card, index) => (
                        <Card
                            key={index}
                            url={card.url}
                            icon={card.icon}
                            text={card.text}
                            title={card.title}
                            className='home-intro-card'
                        />
                    ))}
                </div>
            </MobileStack>
            {!isWhiteLabel && (
                <OnBoardingGuide key={language?.code} />
            )}
            <div
                ref={inViewRef}
                className='page-layout'
            >
                {!isWhiteLabel && (
                    <Section
                        title={
                            <Translate>Target by Interests</Translate>
                        }
                        gap={2}
                        columns={isMobile ? targetingData?.interests?.filter(item => !!interestsDataMapping?.[item.key]?.icon)?.length : 6}
                        className='target-by-interests-section'
                    >
                        {targetingData?.interests ? targetingData?.interests?.filter(item => !!interestsDataMapping?.[item.key]?.icon).map(item => (
                            <Card
                                padding={0}
                                key={item.key}
                                appearance='clean'
                                url={`/s?targetingInterests=${item.id}`}
                            >
                                <IconLabelInsetCard
                                    icon={interestsDataMapping[item.key].icon}
                                    url={`/s?targetingInterests=${item.id}`}
                                >
                                    {interestsDataMapping[item.key].label}
                                </IconLabelInsetCard>
                            </Card>
                        )) : (
                            fakeArray(homeSkeleton.interests).map((_, index) => (
                                <Card
                                    skeleton
                                    key={index}
                                    padding={0}
                                    appearance='clean'
                                >
                                    <IconLabelInsetCard
                                        skeleton
                                    />
                                </Card>
                            ))
                        )}
                    </Section>
                )}
                <Section
                    title={(
                        <Translate>Top Place Types</Translate>
                    )}
                    columns={topPlaceTypeCards.length}
                >
                    {topPlaceTypeCards.map(card => {
                        const data = topPlaces?.find(item => item.placeTypeId === card.id);

                        return (
                            <ScreenFeatured
                                key={card.id}
                                name={card.name}
                                image={card.image}
                                blurHash={card.blurHash}
                                to={`/s?location=${card.id}`}
                                {...(data ? {
                                    rating: data.rating,
                                    count: data.screensCount,
                                    price: beautifyPrice(data.averagePrice),
                                } : {
                                    skeleton: true,
                                })}
                            />
                        );
                    })}
                </Section>
                <Section
                    title={
                        <Translate>Price ranges</Translate>
                    }
                    columns={priceRanges?.length || homeSkeleton.priceRanges}
                >
                    {priceRanges ? priceRanges.map((range, index) => (
                        <Card
                            key={index}
                            appearance='minimal'
                            url={`/s?priceMin=${range.min === range.max ? priceRanges[index - 1].max : range.min}&priceMax=${range.max}`}
                            text={
                                <>
                                    <Price number={beautifyPrice(range.min === range.max ? priceRanges[index - 1].max : range.min)} />
                                    {' - '}
                                    <Price number={beautifyPrice(range.max)} />
                                </>
                            }
                            title={(() => {
                                switch (range.name) {
                                    case 'low':
                                        return translate('Starter');
                                    case 'mid-plus':
                                        return translate('Agency');
                                    case 'mid':
                                        return translate('Professional');
                                    case 'high':
                                        return translate('Expert');
                                    default:
                                        return null;
                                }
                            })()}
                        />
                    )) : (
                        fakeArray(homeSkeleton.priceRanges).map((_, index) => (
                            <Card
                                skeleton
                                key={index}
                                appearance='minimal'
                            />
                        ))
                    )}
                </Section>
                <Section
                    title={
                        <Translate>Shortcuts that we love</Translate>
                    }
                    columns={shortcuts?.length || homeSkeleton.shortcuts}
                >
                    {(shortcuts ? shortcuts : fakeArray(homeSkeleton.shortcuts)).map((shortcut, index) => {
                        const mappedImage = shortcutsImageMapping.find((s, i) => shortcuts ? s.id === shortcut?.id : i === index);

                        return (
                            <ScreenFeatured
                                key={index}
                                blurHash={mappedImage?.blurHash}
                                {...shortcuts ? ({
                                    to: shortcut.url,
                                    name: shortcut.name,
                                    rating: shortcut.rating,
                                    image: mappedImage?.image,
                                    count: shortcut.screensCount,
                                    description: shortcut.description,
                                    price: beautifyPrice(shortcut.averagePrice),
                                }) : ({
                                    skeleton: true,
                                    description: '',
                                })}
                            />
                        );
                    })}
                </Section>
            </div>
            <Section
                mobileStack={false}
                columns={placementShortcuts.columns}
            >
                {placementShortcuts.data.map((shortcut, index) => (
                    <Image
                        key={index}
                        maxHeight={430}
                        cta={shortcut.cta}
                        initialHeight={430}
                        source={shortcut.source}
                        blurHash={shortcut.blurHash}
                        source2x={shortcut.source2x}
                        sourceWEBP={shortcut.sourceWEBP}
                        source2xWEBP={shortcut.source2xWEBP}
                    />
                ))}
            </Section>
        </div>
    );
};

export default Home;
