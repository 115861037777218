import PropTypes from 'prop-types';

import { inputConfig } from '../input/props';

import { knobAbleProps, popperConfig } from '../../props';

export const dropDownProps = {
    icon: PropTypes.string,
    mode: PropTypes.oneOf([
        'single',
        'radio',
        'multiple',
    ]),
    data: PropTypes.array,
    size: PropTypes.oneOf(inputConfig.size),
    width: PropTypes.number,
    error: PropTypes.any,
    label: PropTypes.string,
    search: PropTypes.bool,
    onClear: PropTypes.func,
    onClose: PropTypes.func,
    readOnly: PropTypes.bool,
    skeleton: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    maxItems: PropTypes.number,
    position: PropTypes.oneOf(popperConfig.position),
    onChange: PropTypes.func,
    renderer: PropTypes.func,
    passState: PropTypes.bool,
    clearable: PropTypes.bool,
    checkmark: PropTypes.bool,
    fetchFrom: PropTypes.string,
    appearance: PropTypes.oneOf(knobAbleProps),
    selfSizing: PropTypes.bool,
    placeholder: PropTypes.any,
    cornerRadius: PropTypes.oneOf(inputConfig.cornerRadius),
    labelSelector: PropTypes.string,
    valueSelector: PropTypes.string,
    labelRenderer: PropTypes.func,
    initialLoading: PropTypes.bool,
    defaultSelected: PropTypes.any,
    selectDeselectAll: PropTypes.bool,
    selectedClipFallback: PropTypes.func,
};
