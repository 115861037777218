import translate from '../../utils/translate';

import { guid } from '../../helpers';

import indoor from './media/indoor.jpg';
import indoor2x from './media/indoor@2x.jpg';
import outdoor from './media/outdoor.jpg';
import outdoor2x from './media/outdoor@2x.jpg';
import indoorWEBP from './media/indoor.webp';
import indoor2xWEBP from './media/indoor@2x.webp';
import outdoorWEBP from './media/outdoor.webp';
import outdoor2xWEBP from './media/outdoor@2x.webp';

export const placementShortcuts = {
    columns: [7, 5],
    data: [
        {
            id: guid(),
            source: outdoor,
            source2x: outdoor2x,
            sourceWEBP: outdoorWEBP,
            source2xWEBP: outdoor2xWEBP,
            blurHash: 'LgFPjME2I=oz?wIpNckW?cNHj[kD',
            cta: {
                to: '/s?placement=2',
                name: translate('Outdoor Screens'),
            }
        },
        {
            id: guid(),
            source: indoor,
            source2x: indoor2x,
            sourceWEBP: indoorWEBP,
            source2xWEBP: indoor2xWEBP,
            blurHash: 'LSH_GVM|74OF~SM|rWWBkPRjvfsm',
            cta: {
                to: '/s?placement=1',
                name: translate('Indoor Screens'),
            }
        },
    ],
};

export const homeSkeleton = {
    topPlaces: 4,
    shortcuts: 3,
    interests: 24,
    screenTypes: 4,
    priceRanges: 4,
};
