import axios from 'axios';

const onboardUser = (props = {}) => {
    const {
        name,
        icon,
        attachments,
    } = props;

    if (process.env.NODE_ENV !== 'development') {
        axios.post('https://www.advelit.com/.netlify/functions/onboarding', {
            attachments,
            username: name,
            icon_emoji: icon,
        }, {
            withCredentials: true,
        }).catch(() => {
        });
    }
};

export default onboardUser;
