import { useRecoilValue, useSetRecoilState } from 'recoil';
import { fakeArray } from 'clyne-core';
import PropTypes from 'prop-types';
import React from 'react';

import To from '../to';
import Tag from '../tag';
import Icon from '../icon';
import Checkbox from '../checkbox';
import LazyImage from '../lazyImage';
import Translate from '../translate';
import GetScreensData from '../getScreensData';
import HorizontalScroll from '../horizontalScroll';

import { getScreenThumbnail, idFormatter, screenURL, urlToModalHandler } from '../../helpers';

import { crState, screenPreviewModalState, selectedVirtualSlotsState } from '../../state';

import './index.scss';

const LinkedScreen = props => {
    const {
        linked,
        onCheckboxChange,
    } = props;

    const cr = useRecoilValue(crState);
    const selectedVirtualSlots = useRecoilValue(selectedVirtualSlotsState);
    const setScreenPreviewModal = useSetRecoilState(screenPreviewModalState);

    const supportModalPreview = cr.slug === 'searchForScreens';

    const onUrlClick = (e, data) => !!supportModalPreview && urlToModalHandler(e, () => setScreenPreviewModal({
        id: data.id,
        name: data.name,
        linked: data.linked,
        comingSoon: data.comingSoon,
        pendingForReview: data.pendingForReview,
    }));

    return (
        <GetScreensData
            ids={linked}
            fetchOn='mount'
        >
            {({ data }) => {
                const skeleton = !data?.length;

                return (
                    <div className='linked-screen-notice-holder'>
                        <div className='linked-screen-notice-head'>
                            <div className='linked-screen-notice-head-icon'>
                                <Icon
                                    size={36}
                                    type='icon-linked'
                                />
                            </div>
                            <div className='linked-screen-notice-headings'>
                                <div className='linked-screen-notice-title'>
                                    <Translate replaceMap={{ _COUNT_: linked?.length }}>This is a linked screen, by selecting one you will get the whole collection of _COUNT_ screens.</Translate>
                                </div>
                                <div className='linked-screen-notice-sub-title'>
                                    <Translate>The collection of screen includes:</Translate>
                                </div>
                            </div>
                        </div>
                        <div className='v-divider' />
                        <HorizontalScroll>
                            <ul className='linked-screen-includes'>
                                {(skeleton ? fakeArray(linked?.length) : data?.sort((a, b) => a?.id - b?.id))?.map((screen, index) => (
                                    <li key={index}>
                                        <div className='linked-screen-item'>
                                            <div className='linked-screen-item-image'>
                                                {skeleton ? (
                                                    <div className='skeleton-wave b-40' />
                                                ) : (
                                                    <LazyImage
                                                        skeleton
                                                        width={40}
                                                        height={40}
                                                        source={getScreenThumbnail(screen?.images)}
                                                    />
                                                )}
                                            </div>
                                            <div className='linked-screen-meta'>
                                                {skeleton ? (
                                                    <div className='skeleton-wave' />
                                                ) : (
                                                    <Tag
                                                        target='_blank'
                                                        size='extra-small'
                                                        color='complimentary'
                                                        name={idFormatter(screen?.id)}
                                                        times={selectedVirtualSlots?.[screen?.id] ?? 0}
                                                    />
                                                )}
                                                <div className='linked-screen-title'>
                                                    {skeleton ? (
                                                        <div className='skeleton-wave' />
                                                    ) : (
                                                        <To
                                                            target='_blank'
                                                            url={screenURL(screen?.id)}
                                                            onTap={e => onUrlClick(e, screen)}
                                                        >
                                                            {screen?.name}
                                                        </To>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </HorizontalScroll>
                        <div className='v-divider' />
                        <div className='linked-screen-footnote-holder'>
                            {!!onCheckboxChange && (
                                <Checkbox
                                    onChange={onCheckboxChange}
                                    label={<Translate>Don't show again</Translate>}
                                />
                            )}
                            <div className='linked-screen-footnote'>
                                <Translate>The price is shown for only 1 screen</Translate>
                            </div>
                        </div>
                    </div>
                );
            }}
        </GetScreensData>
    );
};

LinkedScreen.propTypes = {
    linked: PropTypes.arrayOf(PropTypes.number),
    onCheckboxChange: PropTypes.func,
};

export default LinkedScreen;
