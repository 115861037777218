import PropTypes from 'prop-types';
import React from 'react';

import './index.scss';

const Radio = props => {
    const {
        name,
        label,
        checked,
        onChange,
        readOnly,
    } = props;

    return (
        <label className='radio-holder'>
            <input
                name={name}
                type='radio'
                checked={checked}
                onChange={onChange}
                readOnly={readOnly || !onChange}
            />
            <small />
            {label && (
                <p>
                    {label}
                </p>
            )}
        </label>
    );
};

Radio.propTypes = {
    name: PropTypes.any,
    label: PropTypes.any,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
};

export default Radio;
