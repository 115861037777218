import { conditionalSpread, rem } from 'clyne-core';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '../button';
import LazyImage from '../lazyImage';

import useDevice from '../../hooks/useDevice';

import './index.scss';

const Image = props => {
    const {
        cta,
        source,
        source2x,
        blurHash,
        maxHeight,
        sourceWEBP,
        source2xWEBP,
        initialHeight,
    } = props;

    const {
        isMobile,
    } = useDevice();

    return (
        <div
            className='image-holder button-external-hover'
            {...conditionalSpread({
                style: {
                    ...conditionalSpread({
                        maxHeight: rem(maxHeight),
                    }, maxHeight && !isMobile),
                    ...conditionalSpread({
                        minHeight: rem(initialHeight),
                    }, initialHeight),
                },
            }, (maxHeight || initialHeight))}
        >
            <LazyImage
                alt={cta?.name}
                source={source}
                source2x={source2x}
                blurHash={blurHash}
                sourceWEBP={sourceWEBP}
                source2xWEBP={source2xWEBP}
            />
            {!!cta && (
                <>
                    <NavLink
                        to={cta.to}
                        aria-label={cta.name}
                        className='absolutely-splash'
                    />
                    <div className='image-cta-holder'>
                        <Button
                            shadow
                            to={cta.to}
                            color='contrast'
                            size='extra-big'
                            flexibility='fit'
                            itemsDirection='end'
                            icon={{
                                type: 'icon-a-arrow-right',
                            }}
                        >
                            {cta.name}
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

Image.propTypes = {
    cta: PropTypes.object,
    source: PropTypes.string,
    source2x: PropTypes.string,
    blurHash: PropTypes.string,
    maxHeight: PropTypes.number,
    sourceWEBP: PropTypes.string,
    source2xWEBP: PropTypes.string,
    initialHeight: PropTypes.number,
};

export default Image;
