import Translate from '../components/translate';

const invoiceHoldStatuses = {
    hold: {
        id: 1,
        key: 'hold',
        name: <Translate>Hold</Translate>,
        color: 'grey',
    },
    held: {
        id: 2,
        key: 'held',
        name: <Translate>Held</Translate>,
        color: 'warning',
    },
    released: {
        id: 3,
        key: 'released',
        name: <Translate>Released</Translate>,
        color: 'brand',
    },
    na: {
        id: 0,
        key: 'na',
        name: <Translate>N/A</Translate>,
        color: 'grey',
    },
};

export default invoiceHoldStatuses;
