export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const REFRESH = '/refresh';
export const REGISTER = '/register';
export const CHECK_EMAIL = '/auth/check-email';
export const LOGIN_VERIFY = '/auth/challenge';
export const RECOVER_PASSWORD = '/recoverPassword';
export const RESEND_RECOVER_PASSWORD_EMAIL = '/resendRecoverPasswordEmail';
export const RESEND_SIGNUP_VERIFY_EMAIL = '/resendSignUpVerifyEmail';
export const RESET_PASSWORD = '/resetPassword';
export const CHANGE_PASSWORD = '/changePassword';
export const PROFILE = '/profile';
export const GENERIC = '/generic';
export const GEOLOCATION = '/geolocation';
export const SCREENS = '/screens';
export const HISTOGRAM = '/histogram';
export const FAVOURITES = '/favourites';
export const CREATE_CAMPAIGN = '/createCampaign';
export const GET_CAMPAIGNS = '/getCampaigns';
export const CAMPAIGNS = '/campaigns';
export const SCREEN_RESOLUTIONS = '/screenResolutions';
export const NOTIFICATIONS = '/notifications';
export const PAYMENT_BOUND_CARDS = '/paymentBoundCards';
export const PAYMENT_INIT = '/initPayment';
export const COUNTRIES = '/countries';
export const BILLING_INFORMATION = '/billingInformation';
export const LANDING = '/landing';
export const ADD_SCREEN = '/screen';
export const TIMEZONES = '/timezones';
export const TARGETING = '/targeting';

export const URLS = {
    [LOGIN]: '/auth/login',
    [REFRESH]: '/token/refresh',
    [LOGOUT]: '/users/me/logout',
    [CHANGE_PASSWORD]: '/users/me/change-password',
    [REGISTER]: '/auth/register',
    [RECOVER_PASSWORD]: '/auth/reset-password',
    [RESET_PASSWORD]: '/auth/reset-password-with-token',
    [RESEND_RECOVER_PASSWORD_EMAIL]: '/auth/resend-email/password-reset',
    [RESEND_SIGNUP_VERIFY_EMAIL]: '/auth/resend-email/email-verification',
    [PROFILE]: '/users/me',
    [BILLING_INFORMATION]: '/users/me/billing-details',
    [GENERIC]: '/generic',
    [GEOLOCATION]: '/geolocation',
    [SCREENS]: '/screens',
    [CREATE_CAMPAIGN]: '/campaign',
    [GET_CAMPAIGNS]: '/campaigns',
    [CAMPAIGNS]: '/campaigns',
    [HISTOGRAM]: `${SCREENS}/histogram`,
    [FAVOURITES]: `${SCREENS}/favourites`,
    [SCREEN_RESOLUTIONS]: '/screen-resolutions',
    [NOTIFICATIONS]: '/notifications',
    [PAYMENT_BOUND_CARDS]: '/payment/bind',
    [PAYMENT_INIT]: '/payment/init',
    [LANDING]: '/landing',
    [COUNTRIES]: '/generic/countries',
    [ADD_SCREEN]: '/screen',
    [TIMEZONES]: '/generic/timezones',
    [TARGETING]: '/generic/targeting',
    [LOGIN_VERIFY]: '/auth/challenge',
    [CHECK_EMAIL]: '/auth/check-email',
};
