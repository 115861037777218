import PropTypes from 'prop-types';
import React from 'react';

import Modal from '../modal';

import './index.scss';

const Alert = props => {
    const {
        text,
        show,
        onClose,
        description,
        type = 'success',
    } = props;

    return (
        <Modal
            active={show}
            position='center'
            onClose={onClose}
            secondaryButton={null}
            primaryButton={{
                onClick: onClose,
                color: 'accent',
            }}
            width={668}
        >
            <div className='alert-content'>
                <div className='message-content-holder'>
                    {type === 'success' && (
                        <div className='animate-success-icon a-icon success'>
                            <div className='success-circular-line-left' />
                            <span className='success-line-tip' />
                            <span className='success-line-long' />
                            <div className='success-ring' />
                            <div className='success-fix' />
                            <div className='success-circular-line-right' />
                        </div>
                    )}
                    <div className='t-text'>
                        {text}
                    </div>
                    {description && (
                        <div className='t-description'>
                            {description}
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

Alert.propTypes = {
    text: PropTypes.any,
    type: PropTypes.string,
    show: PropTypes.bool,
    onClose: PropTypes.func,
    description: PropTypes.any,
};

export default Alert;
