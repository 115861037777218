import PropTypes from 'prop-types';

const Tab = props => {
    const {
        children,
    } = props;

    return children;
};

Tab.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.any,
    badge: PropTypes.any,
    children: PropTypes.any,
};

export default Tab;
