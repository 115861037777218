import PropTypes from 'prop-types';

export const noDataConfig = {
    size: [
        'small',
        'medium',
        'big',
    ],
    type: [
        'data',
        'search',
        'screen',
        'campaign',
        'invoices',
        'favorite',
        'messages',
        'insights',
        'notification',
        'device-stats',
        'transactions',
        'availableScreens',
    ],
};

export const noDataProps = {
    cta: PropTypes.any,
    size: PropTypes.oneOf(noDataConfig.size),
    type: PropTypes.oneOf(noDataConfig.type),
    title: PropTypes.any,
    mapRef: PropTypes.object,
    subTitle: PropTypes.any,
    setNoDataCTALoading: PropTypes.func,
};
