import getImageThumbnail from './getImageThumbnail';

const getVideoThumbnail = (file, width, height, seekTo = 3) => new Promise((resolve, reject) => {
    let videoPlayer = document.createElement('video');

    videoPlayer.setAttribute('src', URL.createObjectURL(file));

    videoPlayer.load();

    videoPlayer.addEventListener('error', reject);

    videoPlayer.addEventListener('loadedmetadata', () => {
        if (videoPlayer.duration < seekTo) {
            reject('video is too short.');
            return;
        }

        videoPlayer.currentTime = seekTo;

        videoPlayer.loop = true;
        videoPlayer.muted = true;
        videoPlayer.autoplay = true;
        videoPlayer.playsInline = true;

        setTimeout(() => {
            videoPlayer.play().then(() => {
                setTimeout(() => {
                    const canvas = document.createElement('canvas');
                    canvas.width = videoPlayer.videoWidth;
                    canvas.height = videoPlayer.videoHeight;

                    const ctx = canvas.getContext('2d');

                    ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);

                    videoPlayer.pause();

                    videoPlayer = undefined;

                    ctx.canvas.toBlob(blob => {
                        if (blob) {
                            getImageThumbnail(blob, width, height).then(resolve).catch(reject);
                        } else {
                            reject();
                        }
                    }, 'image/jpeg', 1);
                }, 200);
            }).catch(reject);
        }, 0);
    });
});

export default getVideoThumbnail;
