import { default as ReactTextTransition, presets } from 'react-text-transition';
import React, { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './index.scss';

const TextTransition = props => {
    const {
        text,
        noStyle,
        delay = 0,
        flexibility = 'full',
    } = props;

    const animatedTextUpdateDeps = typeof text === 'string' ? text : JSON.stringify(text?.props || {});

    const animatedText = useMemo(() => text, [animatedTextUpdateDeps]); // eslint-disable-line

    return !!text && (
        <ReactTextTransition
            inline
            delay={delay}
            springConfig={presets.default}
            className={classNames(
                'text-transition-c',
                `f-${flexibility}`,
                {
                    'text-transition-holder': !noStyle,
                }
            )}
        >
            {animatedText}
        </ReactTextTransition>
    );
};

TextTransition.propTypes = {
    text: PropTypes.any,
    delay: PropTypes.number,
    noStyle: PropTypes.bool,
    flexibility: PropTypes.oneOf([
        'full',
        'fit',
    ]),
};

export default TextTransition;
