import { useSetRecoilState } from 'recoil';
import { useEffect } from 'react';

import { appStateChangedState } from '../state';

const useAppState = () => {
    const setAppStateChanged = useSetRecoilState(appStateChangedState);

    useEffect(() => {
        const handler = () => setAppStateChanged(val => val + 1);

        window.addEventListener('focus', handler);

        return () => {
            window.removeEventListener('focus', handler);
        };
    }, []); // eslint-disable-line
};

export default useAppState;
