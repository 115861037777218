import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Tooltip from '../tooltip';

import './index.scss';

const Checkbox = props => {
    const {
        label,
        linked,
        locked,
        tooltip,
        checked,
        onChange,
        readOnly,
        intermediate,
        align = 'default',
    } = props;

    return (
        <Tooltip content={tooltip}>
            <label
                className={classNames(
                    `checkbox-holder`,
                    `a-${align}`,
                    {
                        locked,
                        intermediate,
                    }
                )}
            >
                <input
                    type='checkbox'
                    checked={checked}
                    onChange={onChange}
                    readOnly={readOnly || !onChange || locked}
                />
                <small>
                <span
                    className={locked ? 'icon-a-lock' : linked ? 'icon-linked' : 'icon-a-check'}
                />
                </small>
                {label && (
                    <div className='ch-label'>
                        {label}
                    </div>
                )}
            </label>
        </Tooltip>
    );
};

Checkbox.propTypes = {
    label: PropTypes.any,
    align: PropTypes.oneOf([
        'default',
        'reverse',
    ]),
    locked: PropTypes.bool,
    linked: PropTypes.bool,
    tooltip: PropTypes.any,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    intermediate: PropTypes.bool,
};

export default Checkbox;
