import React, { Suspense, useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Flex from '../flex';
import Loader from '../loader';
import Translate from '../translate';

import useConnector from '../../hooks/useConnector';

import days from '../../constants/days';

import lazyWithRetry from '../../utils/lazyWithRetry';

import './index.scss';

const ScreenPopularTimesChart = lazyWithRetry(() => import('../screenPopularTimesChart'));

const chartSize = {
    width: 600,
    height: 180,
};

const ScreenPopularTimesChartFallback = () => {
    return (
        <>
            <div style={chartSize} />
            <Loader absolute />
        </>
    );
};

const ScreenPopularTimes = props => {
    const {
        id,
        name,
    } = props;

    const [weekday, setWeekday] = useState(1);

    const { data, loading } = useConnector(`screens/${id}/peak-hours?weekday=${weekday}`, [], {}, false, !!id, 'replace', weekday);

    const categorizeData = data => {
        const maxPercentage = Math.max(...data.map(item => item.percentage));
        const minPercentage = Math.min(...data.map(item => item.percentage));
        const range = maxPercentage - minPercentage;

        return data.map(item => {
            let color;
            let category;

            if (item.percentage === maxPercentage && data.filter(d => d.percentage === maxPercentage).length === 1) {
                category = 'Peak';
                color = 'var(--error)';
            } else if (item.percentage > minPercentage + range * 0.5) {
                category = 'High';
                color = '#07bf48';
            } else {
                category = 'Medium';
                color = '#4EDF9B';
            }

            return {
                ...item,
                color,
                category,
            };
        });
    };

    const formattedData = useMemo(() => categorizeData(data), [data]);

    return (
        <div className='screen-popular-times-holder'>
            <h2>
                <Translate>Popular Times</Translate>
            </h2>
            <h3>
                <Translate replaceMap={{ _NAME_: name }}>See the highest and lowest traffic times at _NAME_</Translate>
            </h3>
            <Flex>
                <div className='screen-popular-times-weekdays'>
                    {days.map((day, index) => (
                        <button
                            key={index}
                            onClick={() => setWeekday(index + 1)}
                            className={classNames(
                                {
                                    active: weekday === index + 1,
                                }
                            )}
                        >
                            {day}
                        </button>
                    ))}
                    <small />
                </div>
            </Flex>
            <div className='screen-popular-times-chart'>
                <Suspense fallback={<ScreenPopularTimesChartFallback />}>
                    <ScreenPopularTimesChart
                        data={formattedData}
                        width={chartSize.width}
                        height={chartSize.height}
                    />
                </Suspense>
                {loading && (
                    <Loader absolute />
                )}
            </div>
        </div>
    );
};

ScreenPopularTimes.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
};

export default ScreenPopularTimes;
