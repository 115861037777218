import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { loadToasterState } from '../state';

const useLoadToaster = () => {
    const setLoadToaster = useSetRecoilState(loadToasterState);

    useEffect(() => {
        const handler = e => {
            setLoadToaster(true);
            setTimeout(() => {
                import('../utils/addToast').then(({ default: addToast }) => addToast(e.detail));
            }, 0);
        };

        document.addEventListener('loadToaster', handler);

        return () => {
            document.removeEventListener('loadToaster', handler);
        };
    }, []); // eslint-disable-line
};

export default useLoadToaster;
