import PropTypes from 'prop-types';

import { uiKitCornerRadius, uiKitSizes } from '../../props';

export const searchConfig = {
    appearance: [
        'default',
        'inline',
        'extra',
    ],
    size: uiKitSizes,
    cornerRadius: uiKitCornerRadius,
};

export const searchProps = {
    size: PropTypes.oneOf(searchConfig.size),
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    autoFocus: PropTypes.bool,
    onKeyDown: PropTypes.func,
    appearance: PropTypes.oneOf(searchConfig.appearance),
    placeholder: PropTypes.any,
    cornerRadius: PropTypes.oneOf(searchConfig.cornerRadius),
};
