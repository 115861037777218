import React, { cloneElement, useEffect, useState } from 'react';
import { conditionalSpread } from 'clyne-core';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import classNames from 'classnames';

import { popperConfig } from '../../props';

import { tooltipProps } from './props';

import './index.scss';

const Tooltip = props => {
    const {
        closed,
        active,
        content,
        children,
        className,
        closeOnBlur = true,
        position = popperConfig.position[9],
    } = props;

    const [openedState, setOpenedState] = useState(false);

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);

    useEffect(() => {
        setOpenedState(active);
    }, [active]);

    useEffect(() => {
        closed && setOpenedState(false);
    }, [closed]);

    const options = {
        placement: position,
        modifiers: popperConfig.modifiers(),
    };

    const { styles, attributes } = usePopper(referenceElement, popperElement, options);

    return (
        <>
            <>
                {cloneElement(children, {
                    ref: setReferenceElement,
                    onMouseEnter: () => setOpenedState(true),
                    onFocus: () => setOpenedState(true),
                    onMouseLeave: () => setOpenedState(false),
                    ...conditionalSpread({
                        onBlur: () => setOpenedState(false),
                    }, closeOnBlur),
                })}
            </>
            {(openedState && content) && createPortal((
                <div
                    ref={setPopperElement}
                    style={styles.popper}
                    {...attributes.popper}
                    onMouseEnter={() => setOpenedState(true)}
                    onMouseLeave={e => {
                        if (!e?.target?.querySelector('[style*="grabbing"]')) {
                            setOpenedState(false);
                        }
                    }}
                    onClick={e => e.stopPropagation()}
                    className={classNames(
                        `tooltip-holder`,
                        `p-${position}`,
                        className,
                        {
                            'pointer-events-none': typeof content?.props?.children === 'string',
                        }
                    )}
                >
                    <div className='tooltip'>
                        {content}
                    </div>
                </div>
            ), document.body)}
        </>
    );
};

Tooltip.propTypes = tooltipProps;

export default Tooltip;
