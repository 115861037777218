import { lazy } from 'react';

const key = 'pageHasBeenForceRefreshed';

const lazyWithRetry = componentImport => lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(window.localStorage.getItem(key) || 'false');

    try {
        const component = await componentImport();

        window.localStorage.setItem(key, 'false');

        return component;
    } catch (e) {
        if (!pageHasAlreadyBeenForceRefreshed) {
            window.localStorage.setItem(key, 'true');
            return window.location.reload();
        }
        throw e;
    }
});

export default lazyWithRetry;
