import React from 'react';
import classNames from 'classnames';

import Icon from '../icon';
import Button from '../button';
import AvailableScreens from '../availableScreens';

import translate from '../../utils/translate';

import { noDataConfig, noDataProps } from './props';

import './index.scss';

const iconMapping = {
    data: 'icon-slider-vertical-fill',
    search: 'icon-a-search-active',
    screen: 'icon-a-monitor-active',
    campaign: 'icon-a-megaphone-active',
    favorite: 'icon-a-heart-fill',
    messages: 'icon-a-email-active',
    notification: 'icon-a-bell-active',
    invoices: 'icon-a-invoice',
    insights: 'icon-a-eye',
    transactions: 'icon-a-credit-card',
    'device-stats': 'icon-a-processor',
};

const NoData = props => {
    const {
        cta,
        title,
        mapRef,
        subTitle,
        setNoDataCTALoading,
        size = noDataConfig.size[2],
        type = noDataConfig.type[0],
    } = props;

    return (
        <div
            className={classNames(
                `no-data-holder`,
                `s-${size}`,
            )}
        >
            {(() => {
                switch (type) {
                    case 'data':
                    case 'search':
                    case 'screen':
                    case 'campaign':
                    case 'favorite':
                    case 'messages':
                    case 'invoices':
                    case 'insights':
                    case 'device-stats':
                    case 'transactions':
                    case 'notification':
                        return (
                            <div className='no-data-artwork-holder'>
                                <div className='no-data-artwork'>
                                    <div className='no-data-artwork-shape-1'>
                                        <Icon
                                            type={iconMapping[type]}
                                        />
                                    </div>
                                    <div className='no-data-artwork-shape-2'>
                                        <small />
                                        <i />
                                        <i />
                                    </div>
                                    <small />
                                    <small />
                                    <small />
                                    <small />
                                    <small />
                                </div>
                            </div>
                        );
                    case 'availableScreens':
                        return (
                            <AvailableScreens
                                mapRef={mapRef}
                                setNoDataCTALoading={setNoDataCTALoading}
                            />
                        );
                    default:
                        return null;
                }
            })()}
            {!!title && (
                <div className='nd-title-holder'>
                    {translate(title)}
                </div>
            )}
            {!!subTitle && (
                <div className='nd-sub-title-holder'>
                    {translate(subTitle)}
                </div>
            )}
            {!!cta && (
                <div className='nd-cta-holder'>
                    {Array.isArray(cta) ? cta.map((cta, index) => (
                        <Button
                            size='big'
                            flexibility='fit'
                            key={cta.color || index}
                            {...cta}
                        />
                    )) : (
                        <Button
                            size='big'
                            flexibility='fit'
                            {...cta}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

NoData.propTypes = noDataProps;

export default NoData;
