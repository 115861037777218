import { useRecoilState, useRecoilValue } from 'recoil';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../icon';
import Tooltip from '../tooltip';
import Translate from '../translate';

import { favouritesState, signedInState } from '../../state';

import { getLSItem, removeLSItem, setLSItem } from '../../helpers';

import useRedirectToSignIn from '../../hooks/useRedirectToSignIn';

import connectionService from '../../services/connectionService';

import './index.scss';

const Favorite = props => {
    const {
        id,
        inline,
        tooltip,
        size = 'small',
    } = props;

    const signedIn = useRecoilValue(signedInState);
    const [favourites, setFavourites] = useRecoilState(favouritesState);

    const url = `/screens/${id}/favourite`;

    const setShouldRedirect = useRedirectToSignIn();
    const [favorite, setFavorite] = useState(false);

    useEffect(() => {
        setFavorite(Array.isArray(favourites) && favourites.includes(id));
    }, [favourites]); // eslint-disable-line

    const handleClick = () => {
        const action = status => {
            if (status === 201) {
                setFavorite(!favorite);
                setFavourites(val => favorite ? val.filter(a => a !== id) : [...val, id]);
            }
        };
        if (signedIn) {
            favorite ? connectionService.deleteJson(url).subscribe(res => action(res.status)) : connectionService.postJson(url).subscribe(res => action(res.status));
        } else {
            setLSItem('addToFavoriteAfterSignIn', id);
            setShouldRedirect(true);
        }
    };

    useEffect(() => {
        const idToAdd = getLSItem('addToFavoriteAfterSignIn');
        if (Number(idToAdd) === id && signedIn) {
            removeLSItem('addToFavoriteAfterSignIn');
            handleClick();
        }
    }, [id, signedIn]); // eslint-disable-line

    const iconSharedProps = {
        size: size === 'big' ? 24 : 18,
    };

    const content = (
        <ul
            className={classNames(
                `favorite-holder`,
                `s-${size}`,
                {
                    inline,
                    'active': favorite && signedIn,
                }
            )}
            onClick={() => handleClick()}
        >
            <li className='absolutely-splash'>
                <Icon
                    type='icon-a-heart-fill'
                    {...iconSharedProps}
                />
            </li>
            <li className='absolutely-splash'>
                <Icon
                    type='icon-a-heart-fill'
                    {...iconSharedProps}
                />
            </li>
        </ul>
    );

    return tooltip ? (
        <Tooltip
            content={favorite ? (
                <Translate>Remove from Favorites</Translate>
            ) : (
                <Translate>Add to Favorites</Translate>
            )}
            position='top'
        >
            {content}
        </Tooltip>
    ) : content;
};

Favorite.propTypes = {
    id: PropTypes.number,
    inline: PropTypes.bool,
    tooltip: PropTypes.bool,
    size: PropTypes.oneOf([
        'small',
        'big',
    ]),
};

export default Favorite;
