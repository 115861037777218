import { useEffect } from 'react';

import useDevice from '../hooks/useDevice';

import { isLightHouse } from '../helpers';

const useViewportMaximumScale = () => {
    const {
        isMobile,
    } = useDevice();

    useEffect(() => {
        if (isMobile && !isLightHouse()) {
            const meta = document.querySelector('*[name="viewport"]');
            meta.setAttribute('content', `${meta.getAttribute('content')}, maximum-scale=1`);
        }
    }, [isMobile]);
};

export default useViewportMaximumScale;
