import React, { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { conditionalSpread, rem } from 'clyne-core';
import { createPortal } from 'react-dom';
import { FocusOn } from 'react-focus-on';
import classNames from 'classnames';

import Icon from '../icon';
import Button from '../button';
import Loader from '../loader';
import Translate from '../translate';

import useDevice from '../../hooks/useDevice';

import { modalProps, modalConfig } from './props';

import './index.scss';

const sharedProps = {
    size: 'medium',
    flexibility: 'fit',
};

const Modal = props => {
    const {
        title,
        width,
        active,
        onOpen,
        message,
        onClose,
        subTitle,
        children,
        fullCover,
        primaryButton,
        secondaryButton,
        topDivider = true,
        closeOnBackdropClick = true,
        alignHeader = modalConfig.alignHeader[0],
    } = props;

    const { isMobile } = useDevice();

    const [triggerSubmit, setTriggerSubmit] = useState(false);

    const primaryButtonRef = useRef(null);

    useEffect(() => {
        (!!active && typeof onOpen === 'function') && onOpen();
    }, [active, onOpen]);

    const handleClose = () => typeof onClose === 'function' && onClose();

    useEffect(() => {
        triggerSubmit && primaryButtonRef?.current?.click();
    }, [triggerSubmit]); // eslint-disable-line

    useEffect(() => {
        const handler = e => {
            const element = e.target;
            if (element.tagName.toLowerCase() === 'input' && e.key === 'Enter') {
                setTriggerSubmit(true);
            }
            if (e.key === 'Escape') {
                handleClose();
            }
        };

        if (active) {
            window.addEventListener('keydown', handler);
        } else {
            window.removeEventListener('keydown', handler);
        }
    }, [active]); // eslint-disable-line

    const closeButton = !!onClose && (
        <Button
            forceFocus
            size='small'
            color='grayscale'
            onClick={handleClose}
            icon={{
                size: 22,
                type: 'icon-a-cross',
            }}
        />
    );

    const maxWidth = message ? (message?.width || 520) : width;

    return createPortal((
        <AnimatePresence>
            {!!active && (
                <FocusOn
                    enabled={isMobile}
                    className='modal-focus-on'
                >
                    <div
                        className={classNames(
                            'modal-holder',
                            'p-center',
                            `ah-${alignHeader}`,
                            {
                                'full-cover': fullCover,
                            }
                        )}
                    >
                        <motion.div
                            key='modalBackdrop'
                            className='modal-backdrop'
                            onClick={() => closeOnBackdropClick && handleClose()}
                            initial={{
                                opacity: 0,
                            }}
                            animate={{
                                opacity: 1,
                            }}
                            exit={{
                                opacity: 0,
                                transition: {
                                    delay: 0.2,
                                },
                            }}
                        />
                        <motion.div
                            key='modal'
                            className={classNames(
                                'modal',
                                {
                                    'f-full': maxWidth,
                                }
                            )}
                            {...conditionalSpread({
                                style: {
                                    maxWidth: rem(maxWidth),
                                },
                            }, !!maxWidth)}
                            initial={{
                                opacity: 0,
                                transform: 'scale(0.9)',
                            }}
                            animate={{
                                opacity: 1,
                                transform: 'scale(1)',
                                transition: {
                                    delay: 0.15,
                                },
                            }}
                            exit={{
                                opacity: 0,
                                transform: 'scale(0.9)',
                            }}
                        >
                            <div className='modal-contents-holder'>
                                {title ? (
                                    <>
                                        <ul
                                            className={classNames(
                                                'modal-head',
                                                {
                                                    'pt': !topDivider,
                                                }
                                            )}
                                        >
                                            {alignHeader === 'center' && (
                                                <li />
                                            )}
                                            <li>
                                                <div className='modal-head-title'>
                                                    {title}
                                                </div>
                                                {!!subTitle && (
                                                    <div className='modal-head-sub-title'>
                                                        {subTitle}
                                                    </div>
                                                )}
                                            </li>
                                            <li>
                                                {closeButton}
                                            </li>
                                        </ul>
                                        {topDivider && (
                                            <div className='v-divider' />
                                        )}
                                    </>
                                ) : (
                                    <div className='modal-close-holder'>
                                        {closeButton}
                                    </div>
                                )}
                                <div
                                    className='modal-content'
                                >
                                    {message ? (
                                        message.loading ? (
                                            <div className='modal-message-loader'>
                                                <Loader absolute />
                                            </div>
                                        ) : (
                                            <>
                                                <ul
                                                    className={classNames(
                                                        `modal-message-holder`,
                                                        `c-${modalConfig.message.color[message.type]}`,
                                                        {
                                                            [`d-${message.direction}`]: message.direction,
                                                        }
                                                    )}
                                                >
                                                    <li>
                                                        <Icon
                                                            size={isMobile ? 36 : 44}
                                                            type={modalConfig.message.icon[message.type]}
                                                        />
                                                    </li>
                                                    <li>
                                                        {!!message.title && (
                                                            <div className='modal-message-title'>
                                                                {message.title}
                                                            </div>
                                                        )}
                                                        <div>
                                                            {message.children}
                                                        </div>
                                                    </li>
                                                </ul>
                                                {message.node && (
                                                    <div className='mm-node-holder'>
                                                        {message.node}
                                                    </div>
                                                )}
                                            </>
                                        )
                                    ) : (
                                        children
                                    )}
                                </div>
                                {(secondaryButton !== null || primaryButton) && (
                                    <>
                                        <div className='v-divider' />
                                        <ul className='modal-footer'>
                                            <li>
                                                {secondaryButton !== null && (
                                                    <Button
                                                        forceFocus
                                                        onClick={handleClose}
                                                        color='grayscale'
                                                        {...secondaryButton}
                                                        {...sharedProps}
                                                    >
                                                        {secondaryButton?.children || (
                                                            <Translate>Cancel</Translate>
                                                        )}
                                                    </Button>
                                                )}
                                                {primaryButton && (
                                                    <Button
                                                        forceFocus
                                                        ref={primaryButtonRef}
                                                        {...conditionalSpread({
                                                            hero: modalConfig.message.color[message?.type],
                                                        }, message?.type)}
                                                        {...primaryButton}
                                                        {...sharedProps}
                                                    >
                                                        {primaryButton?.children ?? (
                                                            <Translate>Okay</Translate>
                                                        )}
                                                    </Button>
                                                )}
                                            </li>
                                        </ul>
                                    </>
                                )}
                            </div>
                        </motion.div>
                    </div>
                </FocusOn>
            )}
        </AnimatePresence>
    ), document.body);
};

Modal.propTypes = modalProps;

export default Modal;
