import PropTypes from 'prop-types';
import { useState } from 'react';

import useConnector from '../../hooks/useConnector';

import { SCREENS, URLS } from '../../constants/apiKeys';

import { removeDuplicatesFromArray } from '../../helpers';

import './index.scss';

const GetScreensData = props => {
    const {
        ids,
        children,
        fetchOn = 'hover',
    } = props;

    const [getData, setGetData] = useState(fetchOn === 'mount');

    const { data } = useConnector(`${URLS[SCREENS]}/${removeDuplicatesFromArray([...(ids || [])])?.sort((a, b) => a - b)?.join(',')}`, [], {}, false, (!!ids?.length && getData));

    return fetchOn === 'mount' ? children({
        data,
        skeleton: !data?.length,
    }) : (
        <div
            className='get-screens-data-holder'
            onMouseEnter={() => setGetData(true)}
        >
            {children({
                data,
                skeleton: !data?.length,
            })}
        </div>
    );
};

GetScreensData.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.number),
    fetchOn: PropTypes.oneOf([
        'hover',
        'mount',
    ]),
    children: PropTypes.func,
};

export default GetScreensData;
