import { isObject } from 'clyne-core';

import translate from './translate';

const recursiveFindAndReplaceObjectValuesByKey = (data, key, keepOriginal = true) => {
    if (Array.isArray(data)) {
        return data.map(val => isObject(val) ? recursiveFindAndReplaceObjectValuesByKey(val, key = 'name') : val);
    } else if (isObject(data)) {
        return Object.keys(data).reduce((acc, itemKey) => {
            const originalKey = `__${itemKey}`;
            return {
                ...acc,
                ...(itemKey === key && typeof data[itemKey] === 'string' && keepOriginal && !data[originalKey] ? { [originalKey]: data[itemKey] } : {}),
                [itemKey]: itemKey === key && typeof data[itemKey] === 'string' ? translate(data[originalKey] || data[itemKey]) : isObject(data[itemKey]) ? recursiveFindAndReplaceObjectValuesByKey(data[itemKey], key) : data[itemKey]
            };
        }, {});
    }
    return data;
};

export default recursiveFindAndReplaceObjectValuesByKey;
