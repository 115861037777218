import { conditionalSpread } from 'clyne-core';
import axios from 'axios';

import api from '../configs/api';

import whiteLabel from '../constants/whiteLabel';

const createAxios = (options = {}) => {
    const createdAxios = axios.create({
        baseURL: api.mainEndpoint,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            ...conditionalSpread({
                'X-Workspace': whiteLabel?.workspacesId,
            }, whiteLabel?.workspacesId),
        },
        ...options,
    });

    createdAxios.interceptors.response.use(response => response, error => {
        return error;
    });

    return {
        ...createdAxios,
        CancelToken: axios.CancelToken,
    };
};

export default createAxios;
