import { Autoplay, FreeMode, Keyboard, Mousewheel, Navigation, Pagination, Thumbs } from 'swiper/modules';
import { Fragment, memo, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useRecoilValue } from 'recoil';

import Button from '../button';
import LazyImage from '../lazyImage';
import Translate from '../translate';
import BlurSplash from '../blurSplash';

import useDevice from '../../hooks/useDevice';
import { languageState, modeState } from '../../state';

import translate from '../../utils/translate';

import { onboardingGuideContent } from './data';

import './index.scss';

const OnBoardingGuide = memo(function OnBoardingGuide() {
    const mode = useRecoilValue(modeState);
    const language = useRecoilValue(languageState);

    const swiperRefLocal = useRef(null);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [inViewRef, inView] = useInView({ threshold: 0, triggerOnce: true, });

    const {
        isMobile,
    } = useDevice();

    const slides = [
        {
            title: translate('Select Screens!'),
        },
        {
            title: translate('Select Dates!'),
        },
        {
            title: translate('Upload Media!'),
        },
        {
            title: translate('Add Payment!'),
        },
        {
            title: translate('Wait for Review!'),
        },
    ];

    useEffect(() => {
        if (inView) {
            swiperRefLocal?.current?.swiper?.autoplay?.start();
        } else {
            swiperRefLocal?.current?.swiper?.autoplay?.stop();
        }
    }, [inView]);

    return (
        <ul className='home-onboarding-holder'>
            <li>
                <BlurSplash />
                <h2 className={language?.code}>
                    <Translate>Run campaign on screens in just 5 steps</Translate>{` 😱`}
                </h2>
                <div className='hog-swiper-holder hog-text-swiper'>
                    <Swiper
                        grabCursor
                        mousewheel={{
                            forceToAxis: true,
                        }}
                        navigation
                        resizeObserver
                        slideToClickedSlide
                        spaceBetween={0}
                        onSwiper={setThumbsSwiper}
                        freeMode={true}
                        watchSlidesProgress={true}
                        longSwipesRatio={0.1}
                        slidesPerView='auto'
                        modules={[FreeMode, Navigation, Keyboard, Thumbs, Mousewheel]}
                        keyboard={{
                            enabled: true
                        }}
                        pagination={{
                            clickable: true
                        }}
                    >
                        {slides.map((slide, index) => (
                            <SwiperSlide
                                key={index}
                                className='hog-title-holder'
                            >
                                <small>{index + 1}</small>
                                <p>
                                    {slide.title.split(' ').map((line, index) => (
                                        <Fragment key={index}>
                                            {!!index && (
                                                <br />
                                            )}
                                            {line}{` `}
                                        </Fragment>
                                    ))}
                                </p>
                            </SwiperSlide>
                        ))}
                        {!isMobile && (
                            <SwiperSlide
                                className='hog-title-holder'
                            >
                                <Button
                                    flexibility='fit'
                                    size='extra-big'
                                    to='/s'
                                    tabIndex={-1}
                                    icon={{
                                        type: 'icon-a-megaphone',
                                    }}
                                >
                                    <Translate>Create Campaign Now</Translate>
                                </Button>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
                <div
                    ref={inViewRef}
                    className='hog-swiper-holder'
                >
                    <Swiper
                        thumbs={{ swiper: thumbsSwiper }}
                        grabCursor
                        navigation
                        mousewheel={{
                            forceToAxis: true,
                        }}
                        centeredSlides
                        resizeObserver
                        slideToClickedSlide
                        autoplay={{
                            delay: 3000,
                            pauseOnMouseEnter: true,
                            disableOnInteraction: false,
                        }}
                        spaceBetween={40}
                        speed={500}
                        ref={swiperRefLocal}
                        slidesPerView={1}
                        modules={[Autoplay, FreeMode, Navigation, Keyboard, Thumbs, Pagination, Mousewheel]}
                        longSwipesRatio={0.1}
                        keyboard={{
                            enabled: true
                        }}
                        pagination={{
                            clickable: true
                        }}
                    >
                        {slides.map((slide, index) => {
                            const content = onboardingGuideContent?.[language?.code] || onboardingGuideContent.en;
                            const modeToUse = mode === 'automatic' ? 'light' : mode;
                            const sources = Object.values(content[modeToUse][isMobile ? 'mobile' : 'desktop'])[index];

                            return (
                                <SwiperSlide key={index}>
                                    <div className='home-onboarding-item-holder'>
                                        <div className='absolute-splash'>
                                            <LazyImage
                                                source={sources[0]}
                                                source2x={sources[1]}
                                                sourceWEBP={sources[2]}
                                                source2xWEBP={sources[3]}
                                                blurHash={Object.values(onboardingGuideContent.blurHashes[modeToUse][isMobile ? 'mobile' : 'desktop'])[index]}
                                            />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </li>
        </ul>
    );
});

export default OnBoardingGuide;
