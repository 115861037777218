import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './index.scss';

const Badge = props => {
    const {
        badge,
        animate,
        size = 'big',
        transform = true,
        position = 'absolute',
        appearance = 'default',
    } = props;

    return (
        <div
            className={classNames(
                `badge`,
                `s-${size}`,
                `p-${position}`,
                `a-${appearance}`,
                {
                    transform,
                    'active': !!badge,
                    'has-content': typeof badge !== 'boolean',
                }
            )}
        >
            <div
                className={classNames(
                    `badge-content`,
                    {
                        animate,
                    }
                )}
            >
                {badge}
            </div>
        </div>
    );
};

Badge.propTypes = {
    size: PropTypes.oneOf([
        'big',
        'small',
    ]),
    badge: PropTypes.any,
    animate: PropTypes.bool,
    position: PropTypes.oneOf([
        'absolute',
        'static',
    ]),
    transform: PropTypes.bool,
    appearance: PropTypes.oneOf([
        'default',
        'light',
    ]),
};

export default Badge;
