const features = {
    help: {
        enabled: false,
    },
    userVerification: {
        enabled: false,
    },
    notificationSettings: {
        enabled: false,
    },
    attachmentInMessages: {
        enabled: false,
    },
    messages: process.env.NODE_ENV !== 'production',
    adb: {
        port: 5555,
    },
    slotType: {
        enabled: false,
    },
};

export default features;
