import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { conditionalSpread } from 'clyne-core';
import classNames from 'classnames';

import Logo from '../logo';
import Guide from '../guide';
import Badge from '../badge';
import Button from '../button';
import Translate from '../translate';
import LocationSearch from '../locationSearch';
import HeaderSignedInActions from '../headerSignedInActions';

import { setLSItem, getLSItem, removeLSItem } from '../../helpers';

import useDevice from '../../hooks/useDevice';

import languages from '../../configs/languages';

import { crState, dynamicDataState, genericState, headerModeState, languageState, signedInState, userModeState, userRoleState, userState, wizardState, workspaceModeState } from '../../state';

import { userRoles, notificationKeys, appearanceIcons } from '../../constants/construct';

import translationService from '../../services/translationService';

import translate from '../../utils/translate';
import getNavigation from '../../utils/getNavigation';
import getRoutesBySlug from '../../utils/getRoutesBySlug';

import { headerAuthentication, headerLocationButton, headerNavigation, headerUserProfile } from './data';

import './index.scss';

const Header = () => {
    const cr = useRecoilValue(crState);
    const wizard = useRecoilValue(wizardState);
    const generic = useRecoilValue(genericState);
    const language = useRecoilValue(languageState);
    const signedIn = useRecoilValue(signedInState);
    const userRole = useRecoilValue(userRoleState);
    const [user, setUser] = useRecoilState(userState);
    const dynamicData = useRecoilValue(dynamicDataState);
    const [userMode, setUserMode] = useRecoilState(userModeState);
    const [headerMode, setHeaderMode] = useRecoilState(headerModeState);
    const [workspaceMode, setWorkspaceMode] = useRecoilState(workspaceModeState);

    const {
        search,
        appearance,
    } = (cr?.header || {});

    const { isMobile } = useDevice();

    const [navigation, setNavigation] = useState(getRoutesBySlug(getNavigation(null, 'guest')));
    const [scrolled, setScrolled] = useState(false);
    const [mobileNavigationOpened, setMobileNavigationOpened] = useState(false);
    const [notificationsSelectedTab, setNotificationsSelectedTab] = useState(notificationKeys.notifications);

    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        const partnerRoutes = getRoutesBySlug(getNavigation(userRole, 'partner'));
        setTimeout(() => {
            !!partnerRoutes && setWorkspaceMode(!!partnerRoutes?.some(route => route.subMenu ? route.subMenu.some(r => r.slug === cr?.slug) : route.slug === cr?.slug));
        }, 0);
    }, [cr, userRole]); // eslint-disable-line

    useEffect(() => {
        setMobileNavigationOpened(false);
        setNavigation(val => val.map(item => ({ ...item, opened: false })));
    }, [pathname]);

    useEffect(() => {
        (userRole === userRoles.partner && workspaceMode) ? (
            setLSItem('workspaceMode', true)
        ) : (
            removeLSItem('workspaceMode')
        );
    }, [workspaceMode, userRole]); // eslint-disable-line

    useEffect(() => {
        setLSItem('userMode', userMode);
    }, [userMode]); // eslint-disable-line

    useEffect(() => {
        const handler = () => {
            setScrolled(window.scrollY >= 20);
        };

        window.addEventListener('scroll', handler);

        return () => {
            window.removeEventListener('scroll', handler);
        };
    }, []);

    useEffect(() => {
        const type = signedIn ? ((userRole === userRoles.partner && workspaceMode) ? 'partner' : 'user') : 'guest';
        setHeaderMode(type);
        setNotificationsSelectedTab(type === 'partner' ? notificationKeys.workspaceNotifications : notificationKeys.notifications);
        setNavigation(getRoutesBySlug(getNavigation(userRole, type)));
    }, [signedIn, userRole, workspaceMode]); // eslint-disable-line

    useEffect(() => {
        const attribute = 'header-appearance';
        document.documentElement.setAttribute(attribute, appearance);

        return () => {
            document.documentElement.removeAttribute(attribute);
        };
    }, [appearance]);

    const firstTimePartner = userRole === userRoles.partner && !user?.onboarding?.firstTimePartner;

    const selectedCurrency = generic?.currencies?.find(val => val.code === getLSItem('currency')) || generic?.currencies?.[0];
    const selectedLanguage = language || languages.find(language => language.default);

    const locationConfig = [
        {
            name: <Translate>Language</Translate>,
            flag: selectedLanguage.flag,
            children: languages.map((item) => ({
                name: item.name,
                flag: item.flag,
                selected: selectedLanguage.code === item.code,
                onClick: () => translationService.setLanguage(item.code),
            })),
        },
        ...conditionalSpread([
            {
                name: <Translate>Currency</Translate>,
                currency: selectedCurrency?.symbol,
                children: generic?.currencies?.map((item) => ({
                    name: item.name,
                    selected: selectedCurrency.code === item.code,
                    onClick: () => {
                        setLSItem('currency', item.code);
                        window.location.reload();
                    },
                    currency: item.symbol,
                })),
            },
            { divider: true },
        ], !signedIn),
        {
            name: <Translate>Appearance</Translate>,
            ...conditionalSpread({
                icon: appearanceIcons?.[userMode],
            }, !!appearanceIcons?.[userMode]),
            children: [
                {
                    name: <Translate>Follow System</Translate>,
                    onClick: () => setUserMode('automatic'),
                    icon: appearanceIcons.automatic,
                    selected: userMode === 'automatic',
                },
                {
                    name: <Translate>Light</Translate>,
                    onClick: () => setUserMode('light'),
                    icon: appearanceIcons.light,
                    selected: userMode === 'light',
                },
                {
                    name: <Translate>Dark</Translate>,
                    onClick: () => setUserMode('dark'),
                    icon: appearanceIcons.dark,
                    selected: userMode === 'dark',
                },
            ]
        },
    ];

    const unitedNotificationsCount = (dynamicData[notificationKeys.notifications]?.count || 0) + (dynamicData[notificationKeys.workspaceNotifications]?.count || 0);

    const sharedProps = { // eslint-disable-line
        user,
        setUser,
        userRole,
        isMobile,
        navigate,
        pathname,
        navigation,
        workspaceMode,
        setNavigation,
        locationConfig,
        selectedLanguage,
        firstTimePartner,
        setWorkspaceMode,
        setMobileNavigationOpened,
    };

    const nav = useMemo(() => headerNavigation(sharedProps), [sharedProps]); // eslint-disable-line

    const wizardIndex = (wizard?.meta?.routes?.findIndex(route => route?.url === cr?.url) || 0) + 1;
    const wizardCount = (wizard?.meta?.routes?.length || 0) + 1;

    return (
        <header
            className={classNames(
                `a-${(isMobile && appearance === 'compact') ? 'default' : appearance}`,
                {
                    scrolled,
                    [`m-${headerMode}`]: !wizard?.meta?.header,
                    'mobile-navigation-opened': mobileNavigationOpened && isMobile,
                }
            )}
        >
            <Guide padding={false}>
                {wizard?.meta?.header ? (
                    <ul className='header-wizard-c'>
                        {isMobile && (
                            <li />
                        )}
                        <li>
                            <h2 className='user-select'>{wizard?.meta?.name}</h2>
                        </li>
                        {!isMobile && (
                            <>
                                <li>
                                    <ul
                                        className='header-wizard-nav'
                                        style={{
                                            '--index': wizardIndex,
                                            '--count': wizardCount,
                                        }}
                                    >
                                        {wizard?.meta?.routes?.map((route, index) => (
                                            <li
                                                key={route.url}
                                                tabIndex={index}
                                                className={classNames({
                                                    'active': Array.isArray(cr.url) ? cr.url.includes(route.url) : route.url === cr.url,
                                                })}
                                            >
                                                <div className='hwn-indicator' />
                                                <p>{translate(route.name)}</p>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                                <li>
                                    <div className='divider' />
                                </li>
                            </>
                        )}
                        <li>
                            <Button
                                color='accent'
                                size={isMobile ? 'default' : 'medium'}
                                to={wizard?.meta?.returnUrl}
                                icon={{
                                    type: 'icon-a-cross',
                                }}
                            />
                        </li>
                    </ul>
                ) : (
                    <ul className='header-c'>
                        {isMobile && (
                            <li>
                                {signedIn ? headerUserProfile(sharedProps) : headerLocationButton(sharedProps)}
                            </li>
                        )}
                        <li onClick={() => setMobileNavigationOpened(false)}>
                            <Logo />
                            {(appearance === 'logo' && !isMobile) && (
                                <>
                                    <div className='header-secondary-divider' />
                                    {headerLocationButton(sharedProps)}
                                </>
                            )}
                            {(search && appearance !== 'logo' && headerMode !== 'partner' && !isMobile) && (
                                <div className='h-location-s-holder'>
                                    <LocationSearch
                                        urlMode
                                        size='big'
                                    />
                                </div>
                            )}
                        </li>
                        {appearance !== 'logo' && (
                            <li>
                                {isMobile ? (
                                    <button
                                        aria-label={mobileNavigationOpened ? translate('Close menu') : translate('Open menu')}
                                        onClick={() => setMobileNavigationOpened(val => !val)}
                                        className={classNames(
                                            `mobile-navigation-toggle`,
                                            {
                                                'active': mobileNavigationOpened,
                                            }
                                        )}
                                    >
                                        <Badge
                                            transform={false}
                                            {...conditionalSpread({
                                                badge: unitedNotificationsCount + dynamicData.messages.count,
                                            }, !mobileNavigationOpened && signedIn)}
                                        />
                                    </button>
                                ) : (
                                    <>
                                        {nav}
                                        <div className='divider' />
                                        {signedIn ? (
                                            <>
                                                {!isMobile && (
                                                    <div className='header-signed-in-actions'>
                                                        <HeaderSignedInActions
                                                            notificationsSelectedTab={notificationsSelectedTab}
                                                            setNotificationsSelectedTab={setNotificationsSelectedTab}
                                                        />
                                                    </div>
                                                )}
                                                <div className='divider' />
                                                {headerUserProfile(sharedProps)}
                                            </>
                                        ) : (
                                            <>
                                                {headerAuthentication(sharedProps)}
                                                <div className='header-uneven-gap'>
                                                    {headerLocationButton(sharedProps)}
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </li>
                        )}
                    </ul>
                )}
                {isMobile && (
                    <ul
                        className={classNames(
                            `mobile-navigation-holder`,
                            {
                                'active': mobileNavigationOpened,
                            }
                        )}
                    >
                        <li className='mn-divider' />
                        <li>
                            {nav}
                        </li>
                        <li className='mn-divider' />
                        <li>
                            {signedIn ? (
                                <ul className='mn-footer-holder'>
                                    <li>
                                        <HeaderSignedInActions
                                            notificationsSelectedTab={notificationsSelectedTab}
                                            setNotificationsSelectedTab={setNotificationsSelectedTab}
                                        />
                                    </li>
                                    <li>
                                        {headerUserProfile(sharedProps)}
                                    </li>
                                </ul>
                            ) : headerAuthentication(sharedProps)}
                        </li>
                    </ul>
                )}
            </Guide>
        </header>
    );
};

export default Header;
