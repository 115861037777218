import { useEffect, useState } from 'react';

const useFileDragAndDrop = (ref, handleUpload) => {
    const [dragged, setDragged] = useState(false);

    const handleDrag = e => {
        e.preventDefault();
        e.stopPropagation();
        setDragged(true);
    };

    const handleDragIn = e => {
        e.preventDefault();
        e.stopPropagation();
        setDragged(true);
    };

    const handleDragOut = e => {
        e.preventDefault();
        e.stopPropagation();
        setDragged(false);
    };

    const handleDrop = e => {
        e.preventDefault();
        e.stopPropagation();
        setDragged(false);
        (e.dataTransfer.files && e.dataTransfer.files.length > 0) && handleUpload(e.dataTransfer.files[0]);
    };

    useEffect(() => {
        const element = ref?.current;
        if (element) {
            element.addEventListener('dragenter', handleDragIn);
            element.addEventListener('dragleave', handleDragOut);
            element.addEventListener('dragover', handleDrag);
            element.addEventListener('drop', handleDrop);
        }
        return () => {
            if (element) {
                element.removeEventListener('dragenter', handleDragIn);
                element.removeEventListener('dragleave', handleDragOut);
                element.removeEventListener('dragover', handleDrag);
                element.removeEventListener('drop', handleDrop);
            }
        };
    }, [ref.current]); // eslint-disable-line

    return { dragged };
};

export default useFileDragAndDrop;
