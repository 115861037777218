import React from 'react';

import Translate from '../../components/translate';

import invoiceStatuses from '../../constants/invoiceStatuses';
import transactionConstants from '../../constants/transaction';
import campaignStatuses from '../../constants/campaignStatuses';
import invoiceHoldStatuses from '../../constants/invoiceHoldStatuses';

import translate from '../../utils/translate';
import lazyWithRetry from '../../utils/lazyWithRetry';

const Report = lazyWithRetry(() => import('../report').then());

const booleanDropdown = [
    {
        id: 1,
        name: 'Yes',
    },
    {
        id: 2,
        name: 'No',
    },
];

const filters = {
    screens: [
        {
            keys: ['start', 'end'],
            start: new Date().setDate(1),
            end: new Date(),
            component: 'DatePickerInput',
            type: 'dateRangePicker',
        },
    ],
    campaigns: [
        {
            keys: ['start', 'end'],
            start: new Date().setDate(1),
            end: new Date(),
            component: 'DatePickerInput',
            type: 'dateRangePicker',
        },
        {
            keys: ['status'],
            component: 'DropDown',
            placeholder: translate('Campaign Status'),
            data: Object.values(campaignStatuses),
        },
    ],
    devices: [
        {
            keys: ['workspaceName'],
            component: 'Input',
            superAdminOnly: true,
            placeholder: translate('Workspace'),
        },
        {
            keys: ['workspaceId'],
            component: 'Input',
            superAdminOnly: true,
            placeholder: translate('Workspace ID'),
        },
        {
            keys: ['screenId'],
            component: 'Input',
            placeholder: translate('Screen ID'),
        }
    ],
    invoices: [
        {
            keys: ['invoiceId'],
            component: 'Input',
            placeholder: translate('Invoice ID'),
        },
        {
            keys: ['userId'],
            component: 'Input',
            placeholder: translate('User ID'),
        },
        {
            keys: ['statusId'],
            component: 'DropDown',
            placeholder: translate('Status'),
            data: Object.values(invoiceStatuses),
        },
        {
            keys: ['holdStatusId'],
            component: 'DropDown',
            placeholder: translate('Hold Status'),
            data: Object.values(invoiceHoldStatuses),
        },
        {
            keys: ['selfWorkspaceOnly'],
            component: 'DropDown',
            placeholder: translate('Self Workspace'),
            data: booleanDropdown,
        },
        {
            keys: ['isLocked'],
            component: 'DropDown',
            placeholder: translate('Locked Transaction'),
            data: booleanDropdown,
        },
        {
            keys: ['verified'],
            component: 'DropDown',
            placeholder: translate('Verified Invoice'),
            data: booleanDropdown,
        }
    ],
    transactions: [
        {
            keys: ['userId'],
            component: 'Input',
            placeholder: translate('User ID'),
        },
        {
            keys: ['transactionId'],
            component: 'Input',
            placeholder: translate('Transaction ID'),
        },
        {
            keys: ['paymentMethodId'],
            component: 'Input',
            placeholder: translate('Payment Method ID'),
        },
        {
            keys: ['gatewayPaymentId'],
            component: 'Input',
            placeholder: translate('Gateway Payment ID'),
        },
        {
            keys: ['reference'],
            component: 'Input',
            placeholder: translate('Reference'),
        },
        {
            keys: ['uid'],
            component: 'Input',
            placeholder: translate('UID'),
        },
        {
            keys: ['type'],
            component: 'DropDown',
            placeholder: translate('Type'),
            data: Object.values(transactionConstants.type),
        },
        {
            keys: ['status'],
            component: 'DropDown',
            placeholder: translate('Status'),
            data: Object.values(transactionConstants.status),
        },
        {
            keys: ['isAuthorisation'],
            component: 'DropDown',
            placeholder: translate('Is Authorisation'),
            data: booleanDropdown,
        },
    ]
};

const noData = {
    nothing: {
        title: translate('There is no data for this... yet!'),
        subTitle: translate('We are doing everything possible to provide you the detailed report.'),
    },
    filtered: {
        title: translate('No data found for your request!'),
        subTitle: translate('Try changing or resetting filters.'),
    },
};

export const reportsRoutes = [
    {
        name: translate('Report by Screens'),
        slug: 'wpReportsScreens',
        icon: 'icon-a-stack',
        subTitle: <Translate>See detailed report based on your screens</Translate>,
        component: (
            <Report
                dataKey='screens'
                keysName='screens'
                filters={filters.screens}
                noData={{
                    type: 'screen',
                    ...noData,
                }}
            />
        ),
        url: '/reports/screens',
        guide: {
            rubber: true,
        },
    },
    {
        name: translate('Report by Campaigns'),
        icon: 'icon-a-megaphone',
        slug: 'wpReportsCampaigns',
        subTitle: <Translate>See detailed report based on campaigns running on your screens</Translate>,
        component: (
            <Report
                dataKey='campaigns'
                keysName='campaigns'
                filters={filters.campaigns}
                noData={{
                    type: 'campaign',
                    ...noData,
                }}
            />
        ),
        url: '/reports/campaigns',
        guide: {
            rubber: true,
        },
    },
    {
        name: translate('Invoices'),
        icon: 'icon-a-invoice',
        slug: 'wpReportsInvoices',
        subTitle: <Translate>Overview of invoices</Translate>,
        superAdminOnly: true,
        component: (
            <Report
                superAdmin
                dataKey='invoices'
                keysName='invoices'
                filters={filters.invoices}
                noData={{
                    type: 'invoices',
                    ...noData,
                }}
            />
        ),
        url: '/reports/invoices',
        guide: {
            rubber: true,
        },
    },
    {
        name: translate('Transactions'),
        icon: 'icon-a-credit-card',
        slug: 'wpReportsTransactions',
        subTitle: <Translate>Overview of transactions</Translate>,
        superAdminOnly: true,
        component: (
            <Report
                superAdmin
                dataKey='transactions'
                keysName='transactions'
                filters={filters.transactions}
                noData={{
                    type: 'transactions',
                    ...noData,
                }}
            />
        ),
        url: '/reports/transactions',
        guide: {
            rubber: true,
        },
    },
    {
        name: translate('Device Stats Submissions'),
        icon: 'icon-a-processor',
        slug: 'wpReportsDevices',
        subTitle: <Translate>Overview of device stats submission data</Translate>,
        superAdminOnly: true,
        component: (
            <Report
                superAdmin
                dataKey='device-stats'
                keysName='devices'
                filters={filters.devices}
                noData={{
                    type: 'device-stats',
                    ...noData,
                }}
            />
        ),
        url: '/reports/device-stats',
        guide: {
            rubber: true,
        },
    },
];
