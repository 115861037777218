import PropTypes from 'prop-types';

const messageTypes = {
    'delete': {
        color: 'error',
        icon: 'icon-a-trash',
    },
    'un-sync': {
        color: 'warning',
        icon: 'icon-a-cloud-cross',
    },
    'sync': {
        color: 'success',
        icon: 'icon-a-cloud',
    },
    'hide': {
        color: 'warning',
        icon: 'icon-a-eye-slash',
    },
    'stopCampaign': {
        color: 'warning',
        icon: 'icon-a-cross-circle',
    },
    'massAction': {
        color: 'success',
        icon: 'icon-a-stack',
    },
    'copyCampaign': {
        color: 'brand',
        icon: 'icon-a-info',
    },
    'runCommand': {
        color: 'brand',
        icon: 'icon-terminal',
    },
    'refundPrepaid': {
        color: 'brand',
        icon: 'icon-a-credit-card',
    },
    'linkedScreen': {
        color: 'success',
        icon: 'icon-linked',
    },
};

export const modalConfig = {
    position: [
        'top',
        'center',
    ],
    appearance: [
        'compact',
        'clean',
    ],
    alignHeader: [
        'center',
        'start',
    ],
    message: {
        type: Object.keys(messageTypes),
        icon: Object.fromEntries(Object.entries(messageTypes).map(([type, value]) => [type, value.icon])),
        color: Object.fromEntries(Object.entries(messageTypes).map(([type, value]) => [type, value.color])),
    },
};

export const modalProps = {
    title: PropTypes.any,
    width: PropTypes.number,
    active: PropTypes.bool,
    onOpen: PropTypes.func,
    message: PropTypes.shape({
        type: PropTypes.oneOf(modalConfig.message.type),
        node: PropTypes.any,
        title: PropTypes.any,
        children: PropTypes.any,
    }),
    onClose: PropTypes.func,
    subTitle: PropTypes.any,
    children: PropTypes.any,
    position: PropTypes.oneOf(modalConfig.position),
    fullCover: PropTypes.bool,
    appearance: PropTypes.oneOf(modalConfig.appearance),
    topDivider: PropTypes.bool,
    alignHeader: PropTypes.oneOf(modalConfig.alignHeader),
    primaryButton: PropTypes.object,
    secondaryButton: PropTypes.object,
    closeOnBackdropClick: PropTypes.bool,
};
