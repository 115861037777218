import { Keyboard, Mousewheel, Navigation, Pagination } from 'swiper/modules';
import { ArrowLeft01Icon, ArrowRight01Icon } from '@hugeicons/react';
import { conditionalSpread, rem, toProps } from 'clyne-core';
import { InView } from 'react-intersection-observer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { memo, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import To from '../to';

import { iconSize } from '../../helpers';

import './index.scss';

const Slider = memo(function Slider(props) {
    const {
        to,
        alt,
        data,
        width,
        height,
        absolute,
        onItemClick,
        borderRadius = 12,
        touchDeviceBehaviour,
    } = props;

    const nextButtonRef = useRef(null);
    const prevButtonRef = useRef(null);
    const paginationContainerRef = useRef(null);

    const sharedStyles = {
        ...conditionalSpread({
            top: 0,
            left: 0,
        }, absolute),
        position: absolute ? 'absolute' : 'relative',
        width: isNaN(width) ? width : rem(width),
        height: isNaN(height) ? height : rem(height),
        borderRadius: isNaN(borderRadius) ? borderRadius : rem(borderRadius),
    };

    const [canMountSlider, setCanMountSlider] = useState(false);
    const [canUnMountFirstImage, setCanUnMountFirstImage] = useState(false);

    const slideContentRenderer = (item, props, index) => (
        <>
            <img
                alt={alt}
                loading='lazy'
                src={item.thumbnail || item.src}
                style={{
                    ...sharedStyles,
                    objectFit: 'cover',
                    backgroundColor: item.color,
                }}
                {...(props || {})}
            />
            {!!onItemClick && (
                <div
                    className='absolute-splash cursor-pointer'
                    onClick={() => onItemClick(item, index)}
                />
            )}
            {!!to?.url && (
                <To
                    url={to.url}
                    onTap={to.onTap}
                    title={to.title}
                    target={to.target}
                    className='absolute-splash'
                />
            )}
        </>
    );

    return !!data?.length && (
        <InView
            triggerOnce
            threshold={0.8}
            onChange={inView => {
                if (inView && touchDeviceBehaviour) {
                    setCanMountSlider(true);
                }
            }}
        >
            {({ ref, inView }) => (
                <div
                    ref={ref}
                    style={sharedStyles}
                    onMouseEnter={() => setCanMountSlider(true)}
                    className='slider-component-holder slider-navigation-buttons-hover'
                >
                    {!canUnMountFirstImage && slideContentRenderer(data[0], {}, 0)}
                    {(canMountSlider && data.length > 1) && (
                        <Swiper
                            grabCursor
                            centeredSlides
                            resizeObserver
                            mousewheel={{
                                forceToAxis: true,
                            }}
                            slideToClickedSlide
                            navigation={{
                                prevEl: prevButtonRef.current,
                                nextEl: nextButtonRef.current,
                            }}
                            className={classNames(
                                {
                                    active: canUnMountFirstImage,
                                }
                            )}
                            slidesPerView='auto'
                            style={sharedStyles}
                            spaceBetween={borderRadius}
                            modules={[Pagination, Navigation, Keyboard, Mousewheel]}
                            longSwipesRatio={0.1}
                            keyboard={{
                                enabled: true,
                            }}
                            pagination={{
                                clickable: true,
                                bulletClass: 'bullet',
                                modifierClass: 'slider-c-',
                                bulletActiveClass: 'active',
                                el: paginationContainerRef.current,
                            }}
                        >
                            {data.map((item, index) => (
                                <SwiperSlide key={index}>
                                    {(index ? inView : true) && slideContentRenderer(item, conditionalSpread({
                                        onLoad: () => setTimeout(() => {
                                            setCanUnMountFirstImage(true);
                                        }, 50),
                                    }, !index), index)}
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )}
                    {touchDeviceBehaviour && (
                        <div
                            className='absolute-splash'
                            style={{
                                zIndex: 1,
                            }}
                        >
                            {!!to?.url && (
                                <To
                                    url={to.url}
                                    onTap={to.onTap}
                                    title={to.title}
                                    target={to.target}
                                    className='absolute-splash'
                                />
                            )}
                        </div>
                    )}
                    {data.length > 1 && (
                        <>
                            <div
                                ref={paginationContainerRef}
                                className='slider-c-bullets'
                            >
                                {data.map((item, index) => (
                                    <span
                                        key={index}
                                        className={classNames(
                                            'bullet',
                                            {
                                                active: !index,
                                            }
                                        )}
                                    />
                                ))}
                            </div>
                            {inView && (
                                <div className='slider-navigation-buttons'>
                                    <button
                                        ref={prevButtonRef}
                                        disabled
                                    >
                                        <ArrowLeft01Icon
                                            type='standard'
                                            style={iconSize(18)}
                                        />
                                    </button>
                                    <button ref={nextButtonRef}>
                                        <ArrowRight01Icon
                                            type='standard'
                                            style={iconSize(18)}
                                        />
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
        </InView>
    );
});

Slider.propTypes = {
    to: PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
        onTap: PropTypes.func,
        target: PropTypes.oneOf(toProps.target),
    }),
    alt: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
        src: PropTypes.string,
        color: PropTypes.string,
        thumbnail: PropTypes.string,
    })),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    absolute: PropTypes.bool,
    onItemClick: PropTypes.func,
    borderRadius: PropTypes.number,
    touchDeviceBehaviour: PropTypes.bool,
};

export default Slider;
