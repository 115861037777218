import 'react-app-polyfill/stable';
import 'allsettled-polyfill';

import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import React from 'react';

import App from './App';

import './assets/scss/main.scss';

if (process.env.NODE_ENV !== 'development' && process.env.REACT_APP_API_ENV === 'production') {
    Sentry.init({
        enableTracing: true,
        tracesSampleRate: 1.0,
        attachStacktrace: true,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        environment: process.env.NODE_ENV,
        dsn: process.env.REACT_APP_SENTRY_DSN,
    });
}

const root = createRoot(document.getElementById('root'));

root.render(<App />);
