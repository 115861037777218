import { useEffect } from 'react';

const useModernizr = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = `${process.env.PUBLIC_URL}/assets/js/modernizr-custom.js`;
        script.defer = true;
        document.body.appendChild(script);
    }, []);
};

export default useModernizr;
