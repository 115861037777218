import React, { forwardRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../icon';

import { requiredFormatter } from '../../helpers';

import './index.scss';

const Knob = forwardRef(function Knob(props, ref) {
    const {
        label,
        badge,
        value,
        error,
        onClick,
        onClear,
        required,
        clearable,
        className,
        endDivider,
        placeholder,
        highlighted,
        popoverOpened,
        startDivider = true,
    } = props;

    const [hover, setHover] = useState(false);

    const dividerRenderer = condition => condition && (
        <div
            className={classNames(
                `divider`,
                {
                    'active': !hover && !popoverOpened,
                }
            )}
        />
    );

    return (
        <>
            {dividerRenderer(startDivider)}
            <div
                ref={ref}
                onClick={onClick}
                className={classNames(
                    `knob-holder`,
                    className,
                    {
                        error,
                        highlighted,
                        'active': clearable,
                        'np': !label,
                    }
                )}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {label && (
                    <div className='knob-label'>
                        <span className='text-ellipsis'>
                            {requiredFormatter(label, required)}
                        </span>
                        {!!badge && (
                            <small>{badge}</small>
                        )}
                    </div>
                )}
                <ul className='knob-content'>
                    <li className='text-ellipsis'>
                        {value || placeholder}
                    </li>
                    {(clearable && !!onClear) && (
                        <li>
                            <button
                                onClick={e => {
                                    onClear();
                                    e.stopPropagation();
                                }}
                            >
                                <Icon
                                    size={16}
                                    type='icon-a-cross'
                                />
                            </button>
                        </li>
                    )}
                </ul>
            </div>
            {dividerRenderer(endDivider)}
        </>
    );
});

Knob.propTypes = {
    label: PropTypes.any,
    badge: PropTypes.any,
    value: PropTypes.any,
    error: PropTypes.any,
    onClick: PropTypes.func,
    onClear: PropTypes.func,
    required: PropTypes.bool,
    clearable: PropTypes.bool,
    className: PropTypes.string,
    endDivider: PropTypes.bool,
    highlighted: PropTypes.bool,
    placeholder: PropTypes.any,
    startDivider: PropTypes.bool,
    popoverOpened: PropTypes.bool,
};

export default Knob;
