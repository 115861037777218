import Translate from '../components/translate';

const invoiceStatuses = {
    draft: {
        id: 1,
        key: 'draft',
        name: <Translate>Draft</Translate>,
        color: 'grey',
    },
    review: {
        id: 2,
        key: 'review',
        name: <Translate>Review</Translate>,
        color: 'grey',
    },
    pending: {
        id: 3,
        key: 'pending',
        name: <Translate>Pending</Translate>,
        color: 'warning',
    },
    partial: {
        id: 4,
        key: 'partial',
        name: <Translate>Partial</Translate>,
        color: 'success',
    },
    paid: {
        id: 5,
        key: 'paid',
        name: <Translate>Paid</Translate>,
        color: 'success',
    },
    overdue: {
        id: 6,
        key: 'overdue',
        name: <Translate>Overdue</Translate>,
        color: 'error',
    },
    void: {
        id: 7,
        key: 'void',
        name: <Translate>Void</Translate>,
        color: 'grey',
    },
    refunded: {
        id: 8,
        key: 'refunded',
        name: <Translate>Refunded</Translate>,
        color: 'success',
    },
    pendingReview: {
        id: 9,
        key: 'pendingReview',
        name: <Translate>Pending Review</Translate>,
        color: 'grey',
    },
    closed: {
        id: 10,
        key: 'closed',
        name: <Translate>Closed</Translate>,
        color: 'grey',
    },
};

export default invoiceStatuses;
