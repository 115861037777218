import { fallbackRoute, routes } from '../routes';

import cleanURLFromLanguage from './cleanURLFromLanguage';

const getRouteFromURL = browserPathname => {
    let tmp = fallbackRoute;
    const pathname = cleanURLFromLanguage(browserPathname[browserPathname.length - 1] === '/' ? browserPathname.slice(0, -1) : browserPathname);

    const checkDynamicRoute = (url, route) => {
        if (url === pathname) {
            tmp = route;
        } else if (url.includes(':')) {
            const slicedUrl = url.slice(0, (url.indexOf('/:') === -1 ? url.length : url.indexOf('/:')));
            if (pathname.includes(slicedUrl)) {
                tmp = route;
            }
        }
    };

    routes.forEach(route => {
        const url = route.virtualUrl || route.url;
        checkDynamicRoute(url, route);
        if (Array.isArray(url)) {
            if (route.subRoutes) {
                route.subRoutes.forEach(subRoute => checkDynamicRoute(subRoute.url, subRoute));
            } else {
                url.forEach(url => checkDynamicRoute(url, route));
            }
        }
    });

    return tmp || fallbackRoute;
};

export default getRouteFromURL;
