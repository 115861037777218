export const inputValue = e => e?.target?.value;

export const isLightHouse = () => navigator.userAgent.indexOf('Speed Insights') > -1 || navigator.userAgent.indexOf('Chrome-Lighthouse') > -1;

export const hash = () => Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);

export const guid = () => hash() + hash() + '-' + hash() + '-' + hash() + '-' + hash() + '-' + hash() + hash() + hash();

export const minuteToTimeConverter = (number = 0) => {
    const addZero = num => num > 9 ? num : `0${num}`;
    const hours = Math.trunc(number / 60);
    const minutes = (number % 60);
    return `${addZero(hours)}:${addZero(minutes)}`;
};

export const per = (size, divider) => `${(size / divider) * 100}%`;

export const removeDuplicatesFromArray = arr => {
    const set = new Set(arr.map(val => JSON.stringify(val)));

    return Array.from(set).map(val => JSON.parse(val));
};

export const sendGtagEvent = value => {
    if (typeof window.gtag === 'function') {
        window.gtag('event', value, {});
    }
};

export const sendFBEvent = (action, params = {}) => {
    if (typeof window.fbq === 'function') {
        window.fbq('track', action, params);
    }
};

export const sortScreensList = arr => {
    try {
        return Object.values(Object.groupBy(arr, item => item.workspaceId)).map(group => {
            return group.sort((a, b) => (a.internalOrder || a.id) - (b.internalOrder || b.id));
        }).sort((a, b) => a.length - b.length).flatMap(item => item);
    } catch (error) {
        return [];
    }
};

export const getCookie = name => document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';

export const getIntlLocales = props => {
    const {
        user,
        preciseLocation,
    } = props || {};

    const [language, countryCode] = `${new Intl.NumberFormat().resolvedOptions().locale || ''}`.split('-');

    return [
        preciseLocation?.location?.languages?.[0]?.code || Object.keys(preciseLocation?.location?.languages || {})?.[0] || language || 'en',
        user?.countryId || preciseLocation?.country_code || countryCode || 'US',
    ].filter(Boolean).join('-');
};

export const getCurrentCurrency = props => {
    const {
        user,
        workspaceMode,
        preciseLocation,
    } = props || {};

    return workspaceMode ? user?.workspace?.currency : (user?.currency || getLSItem('currency') || preciseLocation?.currency);
};

export const getCurrencySymbol = props => {
    const {
        user,
        workspaceMode,
        preciseLocation,
    } = props || {};

    const currency = getCurrentCurrency({
        user,
        workspaceMode,
        preciseLocation,
    });

    const currencySymbols = {
        AMD: {
            symbol: '֏',
            suffix: true,
        },
        EUR: {
            symbol: '€',
            suffix: true,
        },
        GEL: {
            symbol: '₾',
            suffix: true,
        },
        RUB: {
            symbol: '₽',
            suffix: true,
        },
        USD: {
            symbol: '$',
            prefix: true,
        },
    };

    return currencySymbols[currency];
};

export const setCookie = (name, value, days = 365) => {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + ';domain=.advelit.com; path=/';
};

export const requiredFormatter = (str, required) => required ? `${str} *` : str;

export const constructScreenImagesForSlider = images => Array.isArray(images) ? images.sort((a, b) => b.featured - a.featured).map(image => ({
    src: image.url,
    thumbnail: image.thumbnailUrl,
    color: image.color,
})) : [];

export const restrictDragVertically = props => {
    const {
        transform,
        draggingNodeRect,
        scrollableAncestorRects
    } = props;

    const firstScrollableAncestorRect = scrollableAncestorRects[0];

    if (!draggingNodeRect || !firstScrollableAncestorRect) {
        return transform;
    }

    const value = {
        ...transform
    };

    const headers = document.querySelector('.timeline-headers');

    const extraTop = headers?.clientHeight || 0;

    if (draggingNodeRect.top + transform.y <= (firstScrollableAncestorRect.top + extraTop)) {
        value.y = firstScrollableAncestorRect.top - draggingNodeRect.top + (extraTop ? extraTop + 7 : 0);
    } else if (draggingNodeRect.bottom + transform.y >= firstScrollableAncestorRect.top + firstScrollableAncestorRect.height) {
        value.y = firstScrollableAncestorRect.top + firstScrollableAncestorRect.height - draggingNodeRect.bottom;
    }

    return value;
};

export const sumFromArrayOfObjects = (arr, key) => arr?.reduce((a, b) => a + (b?.[key] || 0), 0);

export const urlConstruct = props => `${props.pathname}${props.search ? `?${props.search.replace('?', '')}` : ''}${props.hash || ''}`;

export const urlToModalHandler = (e, callback) => {
    if (!e.metaKey && !e.shiftKey && !e.altKey) {
        e.preventDefault();
        callback();
    }
};

export const timeToMinuteConverter = (time = '00:00') => {
    const timeArr = time.split(':');
    return timeArr[0] * 60 + parseInt(timeArr[1]);
};

export const timezoneOffsetHumanizer = offset => `${offset > 0 ? '+' : '-'}${Math.abs(offset) < 10 ? '0' : ''}${Math.ceil(Math.abs(offset))}:${({ 0: '00', 25: '15', 50: '30', 75: '45' })[Math.abs(offset) % 1 * 100]}`;

export const gcdCalculator = (a, b) => {
    if (b > a) {
        let temp = a;
        a = b;
        b = temp;
    }
    while (b !== 0) {
        let m = a % b;
        a = b;
        b = m;
    }
    return a;
};

export const ratioCalculator = (width, height) => {
    const gcd = gcdCalculator(width, height);
    const numerator = width / gcd;
    const denominator = height / gcd;
    return parseInt(`${numerator}${denominator}`);
};

export const ratioStyle = (ratio, width, height) => ratio.height <= ratio.width ? ({
    width,
    height: (ratio.height / ratio.width) * width,
}) : ({
    height,
    width: (ratio.width / ratio.height) * height,
});

export const getClosestNumber = (array, closestTo) => {
    let closest = Math.max.apply(null, array);
    array.forEach(number => {
        if (number >= closestTo && number < closest) {
            closest = number;
        }
    });
    return closest;
};

export const validatePriceInput = val => val?.toString()?.replace(/[^0-9.]+/g, '') === val?.toString();

export const validateEmail = str => {
    if (!str) {
        return false;
    }

    const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const result = regExp.exec(`${str}`.trim().toLowerCase());
    return !!result && result[0] !== null;
};

export const centerMapByScreens = (map, screens) => {
    const bounds = new window.google.maps.LatLngBounds();
    screens.forEach(screen => {
        const position = new window.google.maps.LatLng(screen.latitude, screen.longitude);
        bounds.extend(position);
    });
    map.fitBounds(bounds);
};

export const toArray = input => Array.isArray(input) ? input : [input];

export const getImageMetadata = file => {
    return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = () => {
            const img = new Image();
            img.onload = e => {
                const { height, width } = e.target;
                resolve({ height, width });
            };
            img.onerror = err => {
                reject(err);
            };
            img.src = fr.result.toString();
        };
        fr.readAsDataURL(file);
    });
};

export const setMetaImage = (image, setMetas) => {
    if (image) {
        setMetas(val => ({
            ...val,
            image: {
                ...val.image,
                source: image,
            }
        }));
        const img = new Image();
        img.addEventListener('load', function () {
            setMetas(val => ({
                ...val,
                image: {
                    ...val.image,
                    width: this.naturalWidth,
                    height: this.naturalHeight,
                },
            }));
        });
        img.src = image;
    }
};

export const goToHub = (start, continueTo = window.location.href) => `${process.env.REACT_APP_HUB_URL}?startAt=${encodeURI(start)}&continueTo=${encodeURI(continueTo)}`;

export const getDateRangeOverlaps = (aStart, aEnd, bStart, bEnd) => {
    const a_start = new Date(aStart);
    const a_end = new Date(aEnd);
    const b_start = new Date(bStart);
    const b_end = new Date(bEnd);

    if (a_start <= b_start && b_start <= a_end) return true;
    if (a_start <= b_end && b_end <= a_end) return true;
    return b_start <= a_start && a_end <= b_end;
};

export const getCampaignDatesOverlap = (campaignData, startKey = 'start', endKey = 'end', groupKey = 'group') => {
    const dataDates = campaignData.filter(val => val[groupKey]).map(val => [
        new Date(val[startKey]),
        new Date(val[endKey]),
        val[groupKey],
    ]);
    const resultingArr = dataDates.map(([start1, end1, groupKey1], idx1) => {
        return dataDates.some(
            ([start2, end2, groupKey2], idx2) => idx2 !== idx1 && groupKey1 === groupKey2 && getDateRangeOverlaps(start1, end1, start2, end2)
        );
    });

    return campaignData.filter((_, idx) => resultingArr[idx]);
};

export const getFileNameFromSource = val => val ? `${val}`?.split('?')?.[0]?.split('/')?.pop() : '';

export const putWorkspaceConfigFormatter = obj => ({
    ...obj,
    avatar: getFileNameFromSource(obj.avatarURL || obj.avatar),
    configs: {
        ...(obj.configs || {}),
        playback: {
            ...(obj.configs.playback || {}),
            splashFileName: getFileNameFromSource(obj.configs.playback.splashFileName),
            splashFileThumbnailName: getFileNameFromSource(obj.configs.playback.splashFileThumbnailName),
            blurHash: (typeof obj.configs.playback.blurHash === 'string') ? {
                color: obj.configs.playback.color || '',
                blurHash: obj.configs.playback.blurHash || '',
            } : obj.configs.playback.blurHash,
        },
    },
});

export const deleteObjectKey = (obj, key) => {
    if (key in obj) {
        delete obj[key];
        return obj;
    }
    return obj;
};

export const shuffleArray = arr => arr.reduce((acc, val, i) => {
    const j = Math.floor(Math.random() * (i));
    [acc[i], acc[j]] = [acc[j], acc[i]];
    return acc;
}, [...arr]);

export const datesDiffByDays = (startDate, endDate) => Math.round(Math.abs(new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24));

export const toSentenceCase = camelCase => {
    if (typeof camelCase === 'string' && camelCase) {
        const result = camelCase.replace(/([A-Z])/g, ' $1');
        return result[0].toUpperCase() + result.substring(1).toLowerCase();
    }
    return '';
};

export const setImmediateInterval = (func, interval) => {
    func();
    return setInterval(func, interval);
};

export const capitalizeFirstLetter = value => typeof value === 'string' ? `${value[0]?.toUpperCase()}${value?.slice(1).toLowerCase()}` : value;

export const versionToNum = version => {
    if (version === '1.0') {
        return 100;
    }

    return (typeof version === 'string' || typeof version === 'number') ? parseFloat(`${version}`.replaceAll('.', '')) : version;
};

export const averageCalculator = arr => Math.round(arr.reduce((a, b) => (a + b)) / arr.length);

export const beautifyPrice = number => {
    const input = `${Math.round(number)}`;
    const formatter = (slicer, filler) => {
        return `${input.slice(0, slicer)}${Array(filler || input.slice(slicer).length).fill(0).join('')}`;
    };
    const output = () => {
        switch (true) {
            case input.length <= 2:
                return number;
            case input.length > 2 && input.length < 6:
                return formatter(2);
            case input.length === 6:
                return formatter(3);
            default:
                return formatter(input.length - 4, 4);
        }
    };

    return parseFloat(output());
};

export const geoLocationKey = '/geolocationV3';

export const setLSItem = (key, value) => {
    const valueFormatter = () => {
        switch (key) {
            case geoLocationKey:
                return JSON.stringify(value);
            case 'authorized':
                return Number.isInteger(value) ? ((value - 60) * 1000) : 0;
            default:
                return value;
        }
    };

    window.localStorage.setItem(key, valueFormatter());
};

export const getLSItem = key => {
    const value = window.localStorage.getItem(key);
    if (!value) {
        return key === 'droppedFrom' ? '/' : null;
    }
    switch (key) {
        case 'rememberMe':
        case geoLocationKey:
            return JSON.parse(value);
        case 'authorized':
        case 'next_email_sent_time':
            return parseInt(value);
        default:
            return value;
    }
};

export const removeLSItem = key => window.localStorage.removeItem(key);

export const clearLS = () => {
    const whiteList = [
        'userMode',
        'currency',
        'deviceId',
        'droppedFrom',
        geoLocationKey,
        'internalPersonal',
        'lastSearchedQuery',
        'dontGoYetModalManual',
        'pageHasBeenForceRefreshed',
    ];

    Object.keys(window.localStorage).forEach(key => {
        if (!whiteList.includes(key) && !key.includes('onboarded-')) {
            removeLSItem(key);
        }
    });
};

export const createOverlay = ({ container, pane, position }) => {
    const maps = window.google.maps;

    class Overlay extends maps.OverlayView {
        constructor(container, pane, position) {
            super();
            this.container = container;
            this.pane = pane;
            this.position = position;
        }

        onAdd = () => {
            // Add the element to the pane.
            const pane = this.getPanes()[this.pane];
            pane?.classList.add('google-map-markers-overlay');
            pane?.appendChild(this.container);
        };

        draw = () => {
            const projection = this.getProjection();
            const point = projection.fromLatLngToDivPixel(this.position);
            if (point === null) return;
            this.container.style.transform = `translate(${point.x}px, ${point.y}px)`;
        };

        onRemove = () => {
            if (this.container.parentNode !== null) {
                this.container.parentNode.removeChild(this.container);
            }
        };
    }

    return new Overlay(container, pane, position);
};

export const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const findDeep = (data, indexStack, index = 0) => (index < indexStack.length ? findDeep(data[indexStack[index]].children, indexStack, index + 1) : data);

export const getLocationType = (locationID, generic) => generic?.locationTypes?.find(locationType => locationType.id === locationID);

export const getScreenImage = images => {
    const featuredImage = images?.find(image => image.featured && image);
    return featuredImage?.url || images?.[0]?.url;
};

export const getScreenThumbnail = images => {
    const featuredImage = images?.find(image => image.featured && image);
    return featuredImage?.thumbnailUrl || images?.[0]?.thumbnailUrl;
};

export const roundDownToNearestMinute = durationMs => {
    const oneMinuteMs = 60 * 1000;

    return Math.floor(durationMs / oneMinuteMs) * oneMinuteMs;
};

export const getScreenOrientation = (orientationId, generic) => generic?.orientations?.find(orientation => orientation.id === orientationId);

export const getScreenType = (screenTypeId, generic, placeTypeId) => {
    const setIcon = id => {
        if (placeTypeId === 7 && id === 5) {
            return 'icon-taxi';
        }
        switch (id) {
            case 1:
                return 'icon-a-screen';
            case 2:
                return 'icon-a-monitor';
            case 3:
                return 'icon-a-slider-horizontal';
            case 4:
                return 'icon-taxi';
            case 5:
                return 'icon-tablet';
            case 7:
                return 'icon-a-monitor';
            default:
                return '';
        }
    };

    if (generic?.screenTypes?.length) {
        const data = generic.screenTypes.map(type => ({
            ...type,
            icon: setIcon(type.id),
            color: type.id === 4 || (placeTypeId === 7 && type.id === 5) ? 'taxi' : 'brand',
        }));

        return data.find(screenType => screenType.id === screenTypeId);
    }
};

export const versionCheck = version => version && version !== 0 && version !== '0';

export const screenURL = id => `/screen/${id}`;

export const successStatus = res => (res.status === 200 || res.status === 201) && (!res.data || res.data.status === undefined || res.data.status);

export const groupByIntersection = objects => {
    const groups = [];

    for (const object of objects) {
        let added = false;

        for (const group of groups) {
            for (const groupObject of group) {
                if ((object.start >= groupObject.start && object.start <= groupObject.end) || (object.end >= groupObject.start && object.end <= groupObject.end)) {
                    if (!group.includes(object)) {
                        group.push(object);
                    }
                    added = true;
                    break;
                }
            }

            if (added) {
                break;
            }
        }

        if (!added) {
            for (const otherObject of objects) {
                if (otherObject !== object && ((object.start >= otherObject.start && object.start <= otherObject.end) || (object.end >= otherObject.start && object.end <= otherObject.end))) {
                    if (!groups.includes(otherObject)) {
                        groups.push([object, otherObject]);
                    }
                    added = true;
                    break;
                }
            }

            if (!added) {
                groups.push([object]);
            }
        }
    }

    return groups;
};

export const groupScreens = screens => {
    const initial = {
        main: {},
        additional: {},
    };

    return Array.isArray(screens) ? screens.reduce((acc, screen) => {
        const sizeKey = screen.orientationId === 1 ? 'pxWidth' : 'pxHeight';
        const ratio = ratioCalculator(screen.pxWidth, screen.pxHeight);
        if (!acc.main[screen.orientationId] || acc.main[screen.orientationId][sizeKey] < screen[sizeKey]) {
            acc.main[screen.orientationId] = {
                orientationId: screen.orientationId,
                pxWidth: screen.pxWidth,
                pxHeight: screen.pxHeight,
                groupKey: screen.orientationId,
                rank: 1,
                ratio,
                screenIds: [...(acc.main[screen.orientationId]?.screenIds || []), screen.id],
            };
        } else {
            acc.main[screen.orientationId].screenIds.push(screen.id);
        }
        if (!acc.additional[ratio] || acc.additional[ratio][sizeKey] < screen[sizeKey]) {
            acc.additional[ratio] = {
                orientationId: screen.orientationId,
                pxWidth: screen.pxWidth,
                pxHeight: screen.pxHeight,
                groupKey: ratio,
                rank: 2,
                ratio,
                screenIds: [...(acc.additional[ratio]?.screenIds || []), screen.id],
            };
        } else {
            acc.additional[ratio].screenIds.push(screen.id);
        }
        return acc;
    }, initial) : initial;
};

export const idFormatter = id => id ? `#${id}` : '';

export const subDomain = window.location.host.split('.')[0];

export const isWhiteLabel = !['www', 'kit', 'local', 'advelit'].includes(subDomain);

export const supportEmail = `support${isWhiteLabel ? `-${subDomain}` : ''}@advelit.com`;

export const hasAnHourPassed = datetimeString => {
    try {
        const givenDate = new Date(datetimeString);

        const now = new Date();

        const differenceInMs = now - givenDate;

        const differenceInHours = differenceInMs / (1000 * 60 * 60);

        return differenceInHours >= 1;
    } catch (error) {
        return true;
    }
};

export const getTranslation = (text = '') => {
    const regex = /<(\w{2})>(.*?)<\/\1>/g;
    const language = getLSItem('language') || 'en';
    let translations = {};
    let match;

    while ((match = regex.exec(text)) !== null) {
        const lang = match[1];
        translations[lang] = match[2];
    }

    if (translations[language]) {
        return translations[language];
    }

    if (translations['en']) {
        return translations['en'];
    }

    return text;
};

export const transliterateArmenian = (text) => {
    const translitMap = {
        'ու': 'u', 'և': 'ev',
        'Ա': 'A', 'Բ': 'B', 'Գ': 'G', 'Դ': 'D', 'Ե': 'E', 'Զ': 'Z', 'Է': 'E', 'Ը': 'Y', 'Թ': 'T',
        'Ժ': 'Zh', 'Ի': 'I', 'Լ': 'L', 'Խ': 'Kh', 'Ծ': 'Ts', 'Կ': 'K', 'Հ': 'H', 'Ձ': 'Dz', 'Ղ': 'Gh',
        'Ճ': 'Ch', 'Մ': 'M', 'Յ': 'Y', 'Ն': 'N', 'Շ': 'Sh', 'Ո': 'Vo', 'Չ': 'Ch', 'Պ': 'P', 'Ջ': 'J',
        'Ռ': 'R', 'Ս': 'S', 'Վ': 'V', 'Տ': 'T', 'Ր': 'R', 'Ց': 'Ts', 'ՈՒ': 'U', 'Փ': 'P', 'Ք': 'Q',
        'ԵՎ': 'Ev', 'Օ': 'O', 'Ֆ': 'F',
        'ա': 'a', 'բ': 'b', 'գ': 'g', 'դ': 'd', 'ե': 'e', 'զ': 'z', 'է': 'e', 'ը': 'y', 'թ': 't',
        'ժ': 'zh', 'ի': 'i', 'լ': 'l', 'խ': 'kh', 'ծ': 'ts', 'կ': 'k', 'հ': 'h', 'ձ': 'dz', 'ղ': 'gh',
        'ճ': 'ch', 'մ': 'm', 'յ': 'y', 'ն': 'n', 'շ': 'sh', 'ո': 'o', 'չ': 'ch', 'պ': 'p', 'ջ': 'j',
        'ռ': 'r', 'ս': 's', 'վ': 'v', 'տ': 't', 'ր': 'r', 'ց': 'ts', 'փ': 'p', 'ք': 'q', 'օ': 'o', 'ֆ': 'f'
    };

    if (getLSItem('language') !== 'hy') {
        return '';
    }

    const specialPattern = /\bո/g;

    text = text.replace(specialPattern, 'vo');

    const pattern = new RegExp(Object.keys(translitMap).join('|'), 'g');

    return ` | ${text.replace(pattern, char => translitMap[char] || char)}`;
};

export const translateCountryNames = (word = '') => {
    const language = getLSItem('language') || 'en';
    const translations = {
        Yerevan: {
            ru: 'Ереван',
            hy: 'Երևան',
            en: 'Yerevan',
            ka: 'ერევანი'
        },
        Armenia: {
            ru: 'Армения',
            hy: 'Հայաստան',
            en: 'Armenia',
            ka: 'სომხეთი'
        }
    };

    const lang = language.toLowerCase();

    if (translations[word]) {
        return translations[word][lang] || translations[word].en;
    }

    return word;
};

export const calculateWorkingHours = (startTime, endTime) => {
    const start = new Date(`1970-01-01T${startTime}Z`);
    const end = new Date(`1970-01-01T${endTime}Z`);
    let hours = (end - start) / (1000 * 60 * 60);

    if (startTime === endTime) {
        return 24;
    }

    if (hours < 0) {
        hours += 24;
    }
    return hours;
};

export const calculateDailyPlaybacks = data => {
    const weekdayHours = calculateWorkingHours(data.weekdayStartTime, data.weekdayEndTime);
    const weekendHours = calculateWorkingHours(data.weekendStartTime, data.weekendEndTime);
    const workingHoursAverage = (weekdayHours + weekendHours) / 2;
    return Math.floor((workingHoursAverage * 3600) / (data.slotCount * data.slotDuration));
};

export const calculateMonthlyPlaybacks = data => {
    const dailyPlaybacks = calculateDailyPlaybacks(data);
    return dailyPlaybacks * 30;
};

export const calculatePricePerSlot = data => {
    const monthlyPlaybacks = calculateMonthlyPlaybacks(data);
    if (monthlyPlaybacks === 0) {
        return undefined;
    }

    return Math.round(data.monthlyPrice / monthlyPlaybacks);
};

export const iconSize = number => {
    const size = `${number / 10}rem`;

    return number ? ({
        width: size,
        height: size,
    }) : {};
};
