const languages = [
    {
        name: 'English',
        code: 'en',
        flag: 'usa',
        default: window.location.host !== 'www.advelit.am',
    },
    {
        name: 'Հայերեն',
        code: 'hy',
        flag: 'arm',
        default: window.location.host === 'www.advelit.am',
    },
    {
        name: 'ქართული',
        code: 'ka',
        flag: 'geo',
    },
    {
        name: 'Русский',
        code: 'ru',
        flag: 'rus',
    },
];

export default languages;
