import React, { Children, useState, Fragment, useRef, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { rem } from 'clyne-core';

import Icon from '../icon';
import Badge from '../badge';

import useDevice from '../../hooks/useDevice';
import useElementResize from '../../hooks/useElementResize';
import useMutationObserver from '../../hooks/useMutationObserver';

import './index.scss';

const Tabs = props => {
    const {
        selected,
        onChange,
        children,
        skeleton,
        onTabClick,
        head = true,
        body = true,
        tabsMaxWidth,
    } = props;

    const ref = useRef(null);
    const tabsRef = useRef(null);

    const { isMobile } = useDevice();

    const [activeTabId, setActiveTabId] = useState(selected || 0);
    const [activeTabHeight, setActiveTabHeight] = useState(37);

    const childes = Children.toArray(children).filter(child => child);
    const tabOffset = childes.length === 1 ? 0 : activeTabId * 100;

    const width = !isMobile && tabsMaxWidth ? rem(tabsMaxWidth) : `${100 / childes.length}%`;

    const { width: parentWidth } = useElementResize(tabsRef);

    useEffect(() => {
        ref.current && setActiveTabHeight(ref.current.scrollHeight);
    }, [activeTabId, ref.current, parentWidth]); // eslint-disable-line

    const setActiveTabHeightCb = useCallback(() => {
        ref.current && setActiveTabHeight(ref.current.scrollHeight);
    }, [ref.current, activeTabId]); // eslint-disable-line

    useEffect(() => {
        props.hasOwnProperty('selected') && setActiveTabId(selected);
    }, [selected]); // eslint-disable-line

    useEffect(() => {
        typeof onChange === 'function' && onChange(activeTabId);
    }, [activeTabId]); // eslint-disable-line

    useMutationObserver(ref, setActiveTabHeightCb);

    return (
        <div className='tabs-holder'>
            {!!head && (
                <ul className='tab-head'>
                    {childes.map((child, index) => {
                        const {
                            icon,
                            badge,
                            title,
                        } = child.props;

                        return (
                            <li
                                key={index}
                                style={{ width }}
                                className={classNames({
                                    'active': activeTabId === index,
                                })}
                                onClick={() => {
                                    setActiveTabId(index);
                                    !!onTabClick && onTabClick(index);
                                }}
                            >
                                {skeleton ? (
                                    <div className='skeleton-wave' />
                                ) : (
                                    <div
                                        className={classNames(
                                            'tab-title-holder',
                                            {
                                                'no-gap': badge?.badge === 0,
                                            }
                                        )}
                                    >
                                        {(isMobile && childes.length > 3) ? (
                                            <Icon
                                                size={20}
                                                type={icon}
                                            />
                                        ) : (
                                            <p className='text-ellipsis'>{title}</p>
                                        )}
                                        {!!badge && (
                                            <Badge
                                                size='small'
                                                position={isMobile ? 'absolute' : 'static'}
                                                {...(typeof badge === 'object' ? badge : { badge })}
                                            />
                                        )}
                                    </div>
                                )}
                            </li>
                        );
                    })}
                    {!skeleton && (
                        <li style={{ width, transform: `translateX(${tabOffset}%)` }} className='box' />
                    )}
                </ul>
            )}
            {!!body && (
                <ul
                    ref={tabsRef}
                    className='tab-body'
                    style={{ height: activeTabHeight }}
                >
                    <li ref={ref}>
                        {childes.map((child, index) => activeTabId === index && (
                            <Fragment key={index}>
                                {child}
                            </Fragment>
                        ))}
                    </li>
                </ul>
            )}
        </div>
    );
};

Tabs.propTypes = {
    head: PropTypes.bool,
    body: PropTypes.bool,
    selected: PropTypes.any,
    onChange: PropTypes.func,
    children: PropTypes.any,
    skeleton: PropTypes.bool,
    onTabClick: PropTypes.func,
    tabsMaxWidth: PropTypes.number,
};

export default Tabs;
