import PropTypes from 'prop-types';

import BlurSplash from '../blurSplash';

import { per } from '../../helpers';

import { availableScreensData } from './data';

import './index.scss';

const delays = [0.2, 0.3, 0.4, 0.5, 0.1];
const duration = [0.5, 0.8, 1, 1.2];

const AvailableScreens = props => {
    const {
        mapRef,
        setNoDataCTALoading,
    } = props;

    const positions = [
        [48, 284, 0],
        [38, 204, 21],
        [66, 80, 48],
        [46, 164, 68],
        [46, 370, 36],
        [22, 247, 64],
        [38, 294, 67],
        [22, 40, 98],
        [60, 61, 132],
        [38, 0, 164],
        [60, 416, 86],
        [38, 352, 96],
        [48, 218, 110],
        [66, 284, 128],
        [38, 144, 132],
        [46, 190, 181],
        [46, 462, 158],
        [48, 384, 160],
        [66, 263, 274],
        [60, 104, 202],
        [48, 198, 252],
        [38, 436, 210],
        [46, 32, 220],
        [38, 332, 212],
        [46, 260, 208],
        [48, 392, 272],
        [48, 130, 288],
        [32, 388, 230],
        [22, 350, 282],
    ];

    return (
        <div className='available-screens-c'>
            <div className='available-screens-holder pointer-events-none'>
                <BlurSplash />
                <ul>
                    {availableScreensData.map(screen => (
                        <li
                            key={screen.index}
                            style={{
                                '--animation-delay': `${delays[Math.floor(Math.random() * delays.length)]}s`,
                                '--animation-duration': `${duration[Math.floor(Math.random() * duration.length)]}s`,
                                '--background-position-y': `${((80 * screen.index / 2240) * 100) + '%'}`,
                                '--size': per(positions[screen.index][0], 508),
                                '--left': per(positions[screen.index][1], 508),
                                '--top': per(positions[screen.index][2], 340),
                            }}
                        >
                            <button
                                className='available-screen-item'
                                onClick={() => {
                                    const map = mapRef?.current;
                                    if (map) {
                                        setNoDataCTALoading(true);
                                        map.setCenter({ lat: screen.lat, lng: screen.lng });
                                        map.setZoom(18);
                                    }
                                }}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

AvailableScreens.propTypes = {
    mapRef: PropTypes.object,
    setNoDataCTALoading: PropTypes.func,
};

export default AvailableScreens;
