import React, { useMemo, useRef, useState } from 'react';
import { conditionalSpread } from 'clyne-core';
import PropTypes from 'prop-types';

import MenuItem from '../menuItem';

import { findDeep } from '../../helpers';

import useElementResize from '../../hooks/useElementResize';

import './index.scss';

const Menu = props => {
    const {
        data,
        closePopover,
    } = props;

    const [header, setHeader] = useState([]);
    const [indexStack, setIndexStack] = useState([]);

    const layer = useMemo(() => findDeep(data.filter(item => item), indexStack), [data, indexStack]);

    const handleClick = (e, item, isHeader, index) => {
        if (isHeader) {
            const newStack = [...indexStack];
            newStack.pop();
            setIndexStack(newStack);
            setHeader(val => val.slice(0, val.length - 1));
        } else {
            const { onClick, children } = item;
            onClick && onClick(e, item);
            if (children) {
                setIndexStack([...indexStack, index]);
                setHeader(val => val?.length ? ([...val, item]) : ([item]));
            } else {
                closePopover && closePopover();
            }
        }
    };

    const ref = useRef(null);

    const { height } = useElementResize(ref);

    const currentHeader = header?.[header.length - 1];

    return (
        <div
            style={conditionalSpread({
                height,
            }, height)}
            className='menu-items-wrapper'
        >
            <ul style={{ '--translate-x': `${indexStack.length * 100}%` }}>
                {indexStack.map((_, index) => <li key={index}/>)}
                <li ref={ref}>
                    {!!currentHeader && (
                        <>
                            <MenuItem
                                highlighted
                                name={currentHeader.name}
                                icon={{
                                    size: 24,
                                    type: 'icon-a-chevron-left',
                                }}
                                actions={currentHeader.actions}
                                onClick={e => handleClick(e, currentHeader, true)}
                            />
                            <div className='v-divider'/>
                        </>
                    )}
                    {Array.isArray(layer) ? layer.map((item, index) => item.divider ? (
                        <div
                            key={index}
                            className='v-divider'
                        />
                    ) : (
                        <MenuItem
                            url={item.url}
                            icon={item.icon}
                            flag={item.flag}
                            name={item.name}
                            badge={item.badge}
                            slash={item.slash}
                            avatar={item.avatar}
                            danger={item.danger}
                            loading={item.loading}
                            currency={item.currency}
                            disabled={item.disabled}
                            selected={item.selected}
                            forward={!!item.children}
                            dataToCopy={item.dataToCopy}
                            key={`${index}${item.name}`}
                            highlighted={item.highlighted}
                            onClick={e => handleClick(e, item, false, index)}
                        />
                    )) : layer}
                </li>
                {(Array.isArray(layer) && layer.some(item => !!item.children)) && <li/>}
            </ul>
        </div>
    );
};

Menu.propTypes = {
    data: PropTypes.array,
    closePopover: PropTypes.func,
};

export default Menu;
