import PropTypes from 'prop-types';

import usePrice from '../../hooks/usePrice';

const Price = props => {
    const {
        min,
        max,
        number = 0,
        postfix = '',
    } = props;

    const isRange = props.hasOwnProperty('min') && props.hasOwnProperty('max');

    const range = `${usePrice(min)} - ${usePrice(max, true, postfix)}`;

    const price = usePrice(number);

    return isRange ? range : price;
};

Price.propTypes = {
    min: PropTypes.any,
    max: PropTypes.any,
    number: PropTypes.any,
    postfix: PropTypes.string,
};

export default Price;
