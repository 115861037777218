import PropTypes from 'prop-types';

import To from '../to';
import Icon from '../icon';
import Translate from '../translate';

import features from '../../configs/features';

import './index.scss';

const LockedScreenInfo = props => {
    const {
        workspaceId,
    } = props;

    return (
        <div className='locked-screen-text'>
            <p>
                <Translate>You can't remove this screen, because the campaign is already running there.</Translate>
            </p>
            {features.messages && (
                <p>
                    <To
                        url={`/messages/create/${workspaceId}`}
                        target='_blank'
                    >
                        <Translate>Contact</Translate>
                        <Icon
                            size={16}
                            type='icon-a-external-link'
                        />
                    </To>
                    {` `}
                    <Translate>the screen owner and ask them to remove this screen manually.</Translate>
                </p>
            )}
        </div>
    );
};

LockedScreenInfo.propTypes = {
    workspaceId: PropTypes.string,
};

export default LockedScreenInfo;
