import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import observeElementResize from '../../utils/observeElementResize';

import './index.scss';

const Clip = props => {
    const {
        value,
        fallback,
    } = props;

    const [visibleFallback, setVisibleFallback] = useState(false);
    const valueRef = useRef(null);

    const checkSlipping = element => {
        setVisibleFallback(element.clientWidth < element.scrollWidth);
    };

    useEffect(() => {
        const observed = observeElementResize(
            valueRef.current,
            ([ResizeObserver]) => checkSlipping(ResizeObserver.target),
        );

        return () => {
            observed && observed.unobserve();
        };
    }, [valueRef]);

    useEffect(() => {
        valueRef.current && checkSlipping(valueRef.current);
    }, [value, valueRef]);

    return (
        <ul
            className={classNames(
                `clip-holder`,
                `text-ellipsis`,
                {
                    'active': visibleFallback,
                }
            )}
        >
            <li className='text-ellipsis'>
                {fallback}
            </li>
            <li
                ref={valueRef}
                className='text-ellipsis clip-value-holder'
            >
                {value}
            </li>
        </ul>
    );
};

Clip.propTypes = {
    value: PropTypes.any,
    fallback: PropTypes.any,
};

export default Clip;
