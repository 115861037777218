import { conditionalSpread, rem } from 'clyne-core';
import React, { forwardRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as Loader } from './media/loader.svg';

import './index.scss';

const Icon = forwardRef(function Icon(props, ref) {
    const {
        flag,
        type,
        size,
        today,
        onClick,
        currency,
        skeleton,
        tabIndex,
        className,
        multiColor,
        onMouseEnter,
        onMouseLeave,
        onAnimationEnd,
    } = props;

    const todayDate = `${new Date().getDate()}`;

    return (
        <i
            className={classNames(
                `icon`,
                type,
                className,
                {
                    flag,
                    currency,
                    skeleton,
                    'multi-color': multiColor,
                    'pointer-events-none': !onClick && !onMouseEnter && !onMouseLeave,
                }
            )}
            ref={ref}
            onClick={onClick}
            {...conditionalSpread({
                tabIndex,
                onKeyDown: e => {
                    if (e.code === 'Space' || e.code === 'Enter') {
                        e.preventDefault();
                        e.stopPropagation();
                        onClick();
                    }
                },
            }, tabIndex !== undefined && onClick)}
            tabIndex={tabIndex}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onAnimationEnd={onAnimationEnd}
            style={conditionalSpread({
                '--size': `var(--icon-external-size, ${rem(size)})`,
            }, size)}
        >
            {type === 'icon-a-loader' && (
                <Loader />
            )}
            {multiColor && (
                <>
                    <span className='path1' />
                    <span className='path2' />
                </>
            )}
            {!!flag && (
                <span
                    className={classNames(
                        `i-flag`,
                        `ft-${flag}`,
                    )}
                />
            )}
            {!!currency && (
                <span className='i-currency'>
                    {currency}
                </span>
            )}
            {!!today && (
                <span
                    className={classNames(
                        'i-today',
                        {
                            'indent': todayDate[0] === '1',
                        }
                    )}
                >
                    {todayDate}
                </span>
            )}
        </i>
    );
});

Icon.propTypes = {
    flag: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.number,
    today: PropTypes.bool,
    onClick: PropTypes.func,
    currency: PropTypes.string,
    skeleton: PropTypes.bool,
    tabIndex: PropTypes.number,
    className: PropTypes.string,
    multiColor: PropTypes.bool,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onAnimationEnd: PropTypes.func,
};

export default Icon;
