const api = {
    host: process.env.REACT_APP_API_ENV === 'development' ? process.env.REACT_APP_API_DEVELOPMENT_HOST : process.env.REACT_APP_API_ENV === 'production' ? process.env.REACT_APP_API_PRODUCTION_HOST : process.env.REACT_APP_API_LOCAL_HOST,
    mainEndpoint: process.env.REACT_APP_API_ENV === 'development' ? process.env.REACT_APP_API_DEVELOPMENT_MAIN_ENDPOINT : process.env.REACT_APP_API_ENV === 'production' ? process.env.REACT_APP_API_PRODUCTION_MAIN_ENDPOINT : process.env.REACT_APP_API_LOCAL_MAIN_ENDPOINT,
    authEndpoint: process.env.REACT_APP_API_ENV === 'development' ? process.env.REACT_APP_API_DEVELOPMENT_AUTH_ENDPOINT : process.env.REACT_APP_API_ENV === 'production' ? process.env.REACT_APP_API_PRODUCTION_AUTH_ENDPOINT : process.env.REACT_APP_API_LOCAL_AUTH_ENDPOINT,
    versions: {
        service: 'https://lit-service.advelit.com/api/versions/',
    },
};

export default api;
