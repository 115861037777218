import { useRecoilValue } from 'recoil';

import './index.scss';

import { userState, userTmpState, workspaceIdState } from '../../state';

const HotJarData = () => {
    const user = useRecoilValue(userState);
    const ws = useRecoilValue(workspaceIdState);
    const userTmp = useRecoilValue(userTmpState);

    const valueRenderer = key => user?.[key] || userTmp?.[key];

    return (
        <div className='info-layer-bottom'>
            {!!valueRenderer('id') && (
                <p>ID - <span>{valueRenderer('id')}{ws ? ' - WS' : ''}</span></p>
            )}
            {!!valueRenderer('name') && (
                <p><span>{valueRenderer('name')}</span></p>
            )}
            {!!valueRenderer('email') && (
                <p><span>{valueRenderer('email')}</span></p>
            )}
            {!!valueRenderer('subscribe') && (
                <p>🫠 - <span>{valueRenderer('subscribe')}</span></p>
            )}
        </div>
    );
};

export default HotJarData;
