import './index.scss';

const BlurSplash = () => {
    return (
        <>
            <div className='blur blur-1 pointer-events-none' />
            <div className='blur blur-2 pointer-events-none' />
        </>
    );
};

export default BlurSplash;
