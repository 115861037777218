import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { setLSItem } from '../helpers';

import { authenticationRoutes } from '../containers/authentication/data';

import { showAuthenticationModalState } from '../state';

const useRedirectToSignIn = (url = window.location.href, options) => {
    const setShowAuthenticationModal = useSetRecoilState(showAuthenticationModalState);

    const [shouldRedirect, setShouldRedirect] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (shouldRedirect) {
            if (!Object.values(authenticationRoutes).includes(window.location.pathname)) {
                setLSItem('droppedFrom', url.replace(window.location.origin, ''));
            }
            setShouldRedirect(false);
            if (options?.redirect) {
                navigate(authenticationRoutes.signIn);
            } else {
                setShowAuthenticationModal(true);
            }
        }
    }, [shouldRedirect]); // eslint-disable-line

    return setShouldRedirect;
};

export default useRedirectToSignIn;
