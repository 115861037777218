import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useSearchParams } from 'react-router-dom';
import { conditionalSpread } from 'clyne-core';
import { useEffect, useRef } from 'react';

import api from '../configs/api';

import { setImmediateInterval, subDomain } from '../helpers';

import { appStateChangedState, crState, dynamicDataLoadingState, dynamicDataState, dynamicDataTriggerState, userState } from '../state';

import { NIL } from '../constants/construct';
import { NOTIFICATIONS } from '../constants/apiKeys';

import connectionService from '../services/connectionService';

const useDynamicData = () => {
    const [searchParams] = useSearchParams();

    const cr = useRecoilValue(crState);
    const user = useRecoilValue(userState);
    const setDynamicData = useSetRecoilState(dynamicDataState);
    const appStateChanged = useRecoilValue(appStateChangedState);
    const dynamicDataTrigger = useRecoilValue(dynamicDataTriggerState);
    const setDynamicDataLoading = useSetRecoilState(dynamicDataLoadingState);

    const interval = useRef(null);

    const handleSetDynamicData = value => setDynamicData(val => {
        const newValue = {
            ...val,
            ...value,
        };

        return JSON.stringify(val) === JSON.stringify(newValue) ? val : newValue;
    });

    useEffect(() => {
        if (user) {
            const workspaceId = user?.workspace?.id !== NIL ? user?.workspace?.id : '';
            cr?.slug === 'messages' && handleSetDynamicData({
                messages: {
                    count: 0,
                },
            });

            interval.current = setImmediateInterval(() => {
                setDynamicDataLoading(true);
                const dataMapping = {
                    notifications: {
                        url: `${NOTIFICATIONS}/count`,
                    },
                    ...conditionalSpread({
                        ...conditionalSpread({
                            deviceStatuses: {
                                url: `/workspaces/${workspaceId}/screens/device-statuses`,
                            },
                        }, ['wpDashboard', 'wpScreens'].includes(cr?.slug) || searchParams.get('sync')),
                        workspaceNotifications: {
                            url: `/workspaces/${workspaceId}${NOTIFICATIONS}/count`,
                        },
                    }, workspaceId),
                };
                Object.keys(dataMapping).forEach((key, index) => connectionService.getJson(dataMapping[key].url, {}, false).subscribe(res => {
                    handleSetDynamicData({
                        [key]: res,
                    });
                    Object.keys(dataMapping).length - 1 === index && setDynamicDataLoading(false);
                }));
            }, 30000);
        } else {
            clearInterval(interval.current);
        }

        return () => {
            clearInterval(interval.current);
        };
    }, [user, dynamicDataTrigger, cr, appStateChanged]); // eslint-disable-line

    useEffect(() => {
        if (['wpCalendar', 'wpScreens', 'oneStatus'].includes(cr?.slug) || subDomain === 'kit') {
            fetch(api.versions.service).then(res => res.json()).then(availableVersions => handleSetDynamicData({
                availableVersions,
            })).catch(() => {
            });
        }
    }, [dynamicDataTrigger, cr, appStateChanged]); // eslint-disable-line

};

export default useDynamicData;
