import Translate from '../components/translate';

const days = [
    <Translate>Mon</Translate>,
    <Translate>Tue</Translate>,
    <Translate>Wed</Translate>,
    <Translate>Thu</Translate>,
    <Translate>Fri</Translate>,
    <Translate>Sat</Translate>,
    <Translate>Sun</Translate>,
];

export default days;
