import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { conditionalSpread } from 'clyne-core';
import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getLSItem, hasAnHourPassed, sendFBEvent, sendGtagEvent, setLSItem } from '../../helpers';

import { crState, currencyState, favouritesState, genericState, isProfileCompleteState, languageState, metasState, modeState, preciseLocationState, signedInState, systemModeState, userModeState, userRoleState, userState, wizardState, workspaceIdState, workspaceModeState } from '../../state';

import { userRoles, NIL } from '../../constants/construct';
import { FAVOURITES, GENERIC, GEOLOCATION, PROFILE } from '../../constants/apiKeys';

import translate from '../../utils/translate';
import onboardUser from '../../utils/onboardUser';
import identityHubSpotUser from '../../utils/identityHubSpotUser';

import useConnector from '../../hooks/useConnector';

import authService from '../../services/authService';

import whiteLabel from '../../constants/whiteLabel';

const StateHydrate = memo(function StateHydrate(props) {
    const {
        children,
    } = props;

    const cr = useRecoilValue(crState);
    const generic = useRecoilValue(genericState);
    const language = useRecoilValue(languageState);
    const setMetas = useSetRecoilState(metasState);
    const userMode = useRecoilValue(userModeState);
    const setWizard = useSetRecoilState(wizardState);
    const [mode, setMode] = useRecoilState(modeState);
    const [user, setUser] = useRecoilState(userState);
    const setCurrency = useSetRecoilState(currencyState);
    const setUserRole = useSetRecoilState(userRoleState);
    const setWorkspaceId = useSetRecoilState(workspaceIdState);
    const preciseLocation = useRecoilValue(preciseLocationState);
    const [signedIn, setSignedIn] = useRecoilState(signedInState);
    const setWorkspaceMode = useSetRecoilState(workspaceModeState);
    const [systemMode, setSystemMode] = useRecoilState(systemModeState);
    const setIsProfileComplete = useSetRecoilState(isProfileCompleteState);

    useConnector(PROFILE, null, null, true, signedIn, 'replace', undefined, false, userState);
    useConnector(GENERIC, {}, null, true, undefined, 'replace', undefined, true, genericState);
    useConnector(FAVOURITES, [], {}, true, signedIn, 'replace', undefined, false, favouritesState);
    useConnector(GEOLOCATION, {}, null, true, true, 'replace', undefined, false, preciseLocationState);

    const workspaceId = user?.workspace?.id;
    const isWorkspaceMode = getLSItem('workspaceMode');

    useEffect(() => {
        setCurrency(generic?.currencies?.find(currency => currency?.code === (user?.currency || preciseLocation?.currency)) || '');
    }, [generic]); // eslint-disable-line

    useEffect(() => {
        (!!workspaceId && isWorkspaceMode) && setWorkspaceMode(true);
    }, [workspaceId, isWorkspaceMode]); // eslint-disable-line

    useEffect(() => {
        if (!signedIn && signedIn !== null) {
            setUser(null);
            setUserRole(null);
        }
    }, [signedIn]); // eslint-disable-line

    useEffect(() => {
        const subscription = authService.isSignedIn.subscribe(setSignedIn);
        return () => {
            subscription.unsubscribe();
        };
    }, []); // eslint-disable-line

    useEffect(() => {
        setUserRole(!!workspaceId ? workspaceId === NIL ? userRoles.client : userRoles.partner : null);
        setWorkspaceId(workspaceId && workspaceId !== NIL ? workspaceId : '');
        if (user) {
            setLSItem('linkedScreenNoticeDismissed', !!user?.onboarding?.linkedScreenNoticeDismissed);
        }
    }, [user]); // eslint-disable-line

    useEffect(() => {
        identityHubSpotUser(true);
    }, [JSON.stringify(user || {})]); // eslint-disable-line

    useEffect(() => {
        if (signedIn && user?.id) {
            window.dataLayer?.push({
                'user_id': `${user?.id}`
            });
        }
    }, [signedIn, user]); // eslint-disable-line

    useEffect(() => {
        const title = translate(cr?.meta?.title || cr?.name);
        const appTitle = translate(whiteLabel?.meta?.title || process.env.REACT_APP_META_TITLE);
        let description = '';
        if (cr?.slug === 'home') {
            description = translate(cr?.meta?.description || whiteLabel?.meta?.description || process.env.REACT_APP_META_DESCRIPTION);
        } else if (cr?.slug === 'becomeAPartner') {
            description = translate('Become a partner with Advelit and monetize your screens effortlessly! Connect HDMI-enabled TVs, tablets, billboards, or displays in malls, gyms, cafes, taxis, hotels, and more. Install our free player software, manage ad campaigns through an easy-to-use dashboard, and start earning passive income from top global brands today.');
        } else {
            description = translate('Discover the best digital outdoor advertising screens on Advelit. Find and book high-visibility ad screens in malls, gyms, beauty salons, taxis, and outdoor locations. Boost your brand’s reach with targeted digital out-of-home advertising on LED screens, billboards, and more. Browse now for the perfect ad space to elevate your marketing campaigns');
        }

        setWizard(val => ({ ...val, key: cr?.wizard?.key || '', enabled: !!cr?.wizard }));
        setMetas(val => ({
            ...val,
            title: translate(cr?.slug === 'home' ? title : `${title ? `${title} | ` : ''}${appTitle}`),
            keywords: translate(cr?.meta?.keywords || whiteLabel?.meta?.keywords || process.env.REACT_APP_META_KEYWORDS),
            description,
        }));
    }, [cr, language?.code]); // eslint-disable-line

    useEffect(() => {
        const query = '(prefers-color-scheme: dark)';
        setSystemMode(window.matchMedia(query).matches ? 'dark' : 'light');
        const listener = e => setSystemMode(e.matches ? 'dark' : 'light');
        window?.matchMedia(query)?.addEventListener('change', listener);
    }, []); // eslint-disable-line

    useEffect(() => {
        setMode(userMode === 'automatic' ? systemMode : userMode);
    }, [userMode, systemMode]); // eslint-disable-line

    useEffect(() => {
        document.documentElement.setAttribute('mode', mode);
        const element = document.querySelector('meta[name="theme-color"]');
        !!element && element.setAttribute('content', mode === 'dark' ? cr?.header?.appearance === 'logo' ? '#0c0e11' : '#15171e' : '#ffffff');
    }, [mode, cr]);

    useEffect(() => {
        setIsProfileComplete(!!user?.billingDetails?.eligible);
    }, [user?.billingDetails?.eligible]); // eslint-disable-line

    useEffect(() => {
        if (user?.id) {
            const key = `onboarded-${user?.id}`;
            if (`${user?.email || ''}`.includes('@advelit.')) {
                setLSItem('internalPersonal', true);
            }
            if (!hasAnHourPassed(user?.created) && !getLSItem(key)) {
                setLSItem(key, true);
                sendGtagEvent('conversion_event_signup');
                sendFBEvent('CompleteRegistration', { value: 2.00, currency: 'USD' });
                onboardUser({
                    icon: user.facebookId ? ':facebook:' : user.googleId ? ':google:' : ':advelit:',
                    name: 'New User',
                    attachments: [
                        {
                            text: [
                                user.name,
                                '`' + user.email + '`',
                            ].join('\n'),
                            footer: [
                                `#${user.id}`,
                                user.currency,
                                user.countryId,
                                user.language,
                                new Date(user.created).toLocaleString(),
                            ].filter(Boolean).join(' - '),
                            type: 'section',
                            mrkdwn_in: ['text', 'footer'],
                            ...conditionalSpread({
                                type: 'image',
                                alt_text: user.name,
                                image_url: `${user.avatarURL || ''}`.includes('https:') ? user.avatarURL : `https:${user.avatarURL}`,
                            }, !!user.avatarURL),
                        },
                    ],
                });
            }
            import('../../utils/connectStreamChatUser').then(({ default: connectStreamChatUser }) => connectStreamChatUser(user).catch(() => {
            }));
        }
    }, [user?.id]); // eslint-disable-line

    return children;
});

StateHydrate.propTypes = {
    children: PropTypes.any,
};

export default StateHydrate;
