const shuffleArray = arr => arr.reduce((acc, val, i) => {
    const j = Math.floor(Math.random() * (i));
    [acc[i], acc[j]] = [acc[j], acc[i]];
    return acc;
}, [...arr]);

const content = [
    {
        id: 64,
        lat: 40.1841787,
        lng: 44.51479333
    },
    {
        id: 53,
        lat: 40.18397942,
        lng: 44.51500455
    },
    {
        id: 3,
        lat: 40.18005793,
        lng: 44.51451273
    },
    {
        id: 3,
        lat: 40.18005793,
        lng: 44.51451273
    },
    {
        id: 24,
        lat: 40.20612557,
        lng: 44.52089463
    },
    {
        id: 41,
        lat: 40.18340174,
        lng: 44.5150526
    },
    {
        id: 50,
        lat: 40.18264009,
        lng: 44.51473013
    },
    {
        id: 47,
        lat: 40.18198529,
        lng: 44.51466558
    },
    {
        id: 10,
        lat: 40.17084416,
        lng: 44.50843953
    },
    {
        id: 16,
        lat: 40.17381562,
        lng: 44.51762049
    },
    {
        id: 30,
        lat: 40.17880358,
        lng: 44.51580882
    },
    {
        id: 33,
        lat: 40.17954733,
        lng: 44.52349825
    },
    {
        id: 2,
        lat: 40.18598816,
        lng: 44.52131165
    },
    {
        id: 46,
        lat: 40.17131398,
        lng: 44.51336217
    },
    {
        id: 23,
        lat: 40.17122825,
        lng: 44.50838157
    },
    {
        id: 5,
        lat: 40.18063649,
        lng: 44.50778002
    },
    {
        id: 2,
        lat: 40.18598816,
        lng: 44.52131165
    },
    {
        id: 13,
        lat: 40.20742012,
        lng: 44.52774184
    },
    {
        id: 27,
        lat: 40.18496952,
        lng: 44.51697371
    },
    {
        id: 29,
        lat: 40.18685449,
        lng: 44.51550079
    },
    {
        id: 73,
        lat: 40.20076115,
        lng: 44.50556178
    },
    {
        id: 4,
        lat: 40.18380972,
        lng: 44.51885161
    },
    {
        id: 10,
        lat: 40.17084416,
        lng: 44.50843953
    },
    {
        id: 1,
        lat: 40.18831928,
        lng: 44.52427504
    },
    {
        id: 71,
        lat: 40.17962816,
        lng: 44.48821865
    },
    {
        id: 3,
        lat: 40.18005793,
        lng: 44.51451273
    },
    {
        id: 27,
        lat: 40.18496952,
        lng: 44.51697371
    },
    {
        id: 4,
        lat: 40.18380972,
        lng: 44.51885161
    },
    {
        id: 30,
        lat: 40.17880358,
        lng: 44.51580882
    },
].map((i, index) => ({ ...i, index }));

export const availableScreensData = shuffleArray(content);
