import { getRecoil } from 'recoil-nexus';

import { userState } from '../state';

const identityHubSpotUser = clearOnGuest => {
    const user = getRecoil(userState);

    const _hsq = window._hsq = window._hsq || [];

    if (user) {
        _hsq.push(['identify', {
            id: user.id,
            name: user.name,
            email: user.email,
            language: user.language,
        }]);
    } else if (clearOnGuest) {
        window?.HubSpotConversations?.resetAndReloadWidget?.();
    }
};

export default identityHubSpotUser;
