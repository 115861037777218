import translate from '../../utils/translate';

export const toastConfig = {
    type: {
        success: {
            type: 'success',
            title: translate('Successfully done'),
        },
        error: {
            type: 'danger',
            title: translate('Something went wrong'),
        },
        danger: {
            type: 'danger',
            title: translate('Something went wrong'),
        },
        info: {
            type: 'info',
            title: translate('Information'),
        },
        warning: {
            type: 'warning',
            title: translate('Something is wrong'),
        },
    },
    default: {
        type: 'success',
        container: 'top-right',
        animationIn: ['reveal'],
        animationOut: ['hide'],
        width: 340,
        dismiss: {
            click: false,
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
            showIcon: true,
        },
    },
};
