import React, { useEffect, useState } from 'react';
import { conditionalSpread } from 'clyne-core';
import Autosuggest from 'react-autosuggest';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import Knob from '../knob';
import Clip from '../clip';
import Input from '../input';
import NoData from '../noData';
import Option from '../option';
import Search from '../search';
import Popover from '../popover';
import Translate from '../translate';

import translate from '../../utils/translate';

import useDevice from '../../hooks/useDevice';

import { escapeRegexCharacters } from '../../helpers';

import connectionService from '../../services/connectionService';

import { dropDownProps } from './props';

import './index.scss';

const DropDown = props => {
    const {
        icon,
        size,
        width,
        error,
        label,
        onClear,
        onClose,
        readOnly,
        position,
        skeleton,
        onChange,
        renderer,
        required,
        disabled,
        clearable,
        fetchFrom,
        placeholder,
        cornerRadius,
        maxItems = 6,
        search = true,
        labelRenderer,
        initialLoading,
        defaultSelected,
        mode = 'single',
        checkmark = true,
        passState = true,
        selectDeselectAll,
        selfSizing = false,
        valueSelector = 'id',
        selectedClipFallback,
        labelSelector = 'name',
        data: initialData = [],
        appearance = 'default',
    } = props;

    const getValue = option => option?.[valueSelector];
    const getLabel = option => translate(option?.[labelSelector] === 'function' ? option?.[labelSelector]() : typeof option?.[labelSelector] === 'string' ? option?.[labelSelector] : option?.[labelSelector]?.props?.children);

    const singleMode = mode === 'single' || mode === 'radio';

    const { isMobile } = useDevice();

    const [data, setData] = useState(initialData);
    const [filteredData, setFilteredData] = useState();
    const [opened, setOpened] = useState(false);
    const [loading, setLoading] = useState(false);
    const [itemHeight, setItemHeight] = useState(0);
    const [headerRef, setHeaderRef] = useState(null);
    const [contentRef, setContentRef] = useState(null);

    const [selected, setSelected] = useState();
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        if (initialData?.length && !data?.length) {
            setData(initialData);
        }
    }, [initialData]); // eslint-disable-line

    useEffect(() => {
        if (fetchFrom) {
            setLoading(true);
            const subscription = connectionService.getJson(fetchFrom).subscribe(val => {
                setData(val);
                setLoading(false);
            });
            return () => {
                subscription && subscription.unsubscribe();
            };
        }
    }, [fetchFrom]);

    useEffect(() => {
        setSelected(defaultSelected !== undefined ? Array.isArray(defaultSelected) || defaultSelected !== Number(defaultSelected) ? defaultSelected : Number(defaultSelected) : singleMode ? null : []);
    }, [defaultSelected]); // eslint-disable-line

    useEffect(() => {
        setFilteredData(data);
    }, [data]);

    useEffect(() => {
        opened && onChange && onChange(selected ?? '');
        if (singleMode) {
            setOpened(false);
            handleClose();
        }
    }, [selected]); // eslint-disable-line

    const onPopoverClose = () => {
        setSearchValue('');
        setFilteredData(data);
    };

    const handleClose = () => {
        setOpened(false);
        onPopoverClose();
        onClose && onClose();
    };

    const inputProps = {
        value: searchValue,
        onChange: (_, { newValue }) => setSearchValue(newValue),
        onKeyDown: e => {
            if (e.keyCode === 13) {
                if (searchValue) {
                    onSelect(data.find(option => getLabel(option) === searchValue));
                } else {
                    handleClose();
                }
                onPopoverClose();
            }
            if (e.keyCode === 27) {
                setOpened(false);
            }
        }
    };

    const isOptionSelected = selectedOption => {
        if (singleMode) {
            return selected === getValue(selectedOption);
        } else {
            return Array.isArray(selected) ? selected?.some(option => option === getValue(selectedOption)) : false;
        }
    };

    const onSelect = selectedOption => {
        if (singleMode) {
            setSelected(getValue(selectedOption));
        } else {
            if (isOptionSelected(selectedOption)) {
                setSelected(options => options.filter(option => option !== getValue(selectedOption)));
            } else {
                setSelected(options => [...options, getValue(selectedOption)]);
            }
        }
    };

    const searchRenderer = props => (maxItems ? data?.length >= maxItems : search) && createPortal(
        <Search
            {...props}
            size='medium'
            autoFocus={!isMobile}
            appearance='inline'
            placeholder={translate('Search')}
        />,
        headerRef
    );

    const optionRenderer = (option, isHighlighted) => (
        <Option
            size='medium'
            icon={option.icon}
            key={getValue(option)}
            checkbox={!singleMode}
            name={getLabel(option)}
            radio={mode === 'radio'}
            check={mode === 'single' && checkmark}
            highlighted={isHighlighted}
            setItemHeight={setItemHeight}
            information={option.information}
            selected={isOptionSelected(option)}
            additionalPadding={cornerRadius === 'full'}
            renderer={typeof renderer === 'function' && renderer(option)}
            onClick={e => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                e.preventDefault();
                onSelect(option);
            }}
        />
    );

    const containerRenderer = (containerProps, children) => {
        containerProps.ref(contentRef);
        return children ? (
            <>
                {mode === 'multiple' && !searchValue && selectDeselectAll && (
                    <Option
                        checkbox
                        size='medium'
                        setItemHeight={setItemHeight}
                        name={<Translate>Select All</Translate>}
                        selected={selected?.length === data?.length}
                        additionalPadding={cornerRadius === 'full'}
                        intermediate={selected?.length !== data?.length && !!selected?.length}
                        onClick={e => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            e.preventDefault();
                            setSelected(val => val?.length === data?.length ? [] : data.map(item => getValue(item)));
                        }}
                    />
                )}
                {children}
            </>
        ) : (
            <NoData
                type='search'
                size='small'
                subTitle={
                    <Translate>And then there were none</Translate>
                }
            />
        );
    };

    const onSearch = value => {
        const escapedValue = escapeRegexCharacters(value.trim());

        if (!escapedValue || escapedValue === '') {
            setFilteredData(data);
        } else {
            setFilteredData(data.filter(option => getLabel(option)?.toLowerCase()?.includes(escapedValue?.toLowerCase())));
        }
    };

    const selectedValue = singleMode ? (
        getLabel(data?.filter(option => getValue(option) === selected)?.[0])
    ) : (
        data?.filter(option => selected?.includes(getValue(option))).map(option => getLabel(option)).join(', ')
    );

    const value = selectedValue && (
        singleMode ? (
            selectedValue
        ) : (
            <Clip
                value={selectedValue}
                fallback={
                    typeof selectedClipFallback === 'function' ? (
                        selectedClipFallback(selected?.length)
                    ) : (
                        <Translate replaceMap={{ '_COUNT_': selected?.length }}>_COUNT_ selected</Translate>
                    )
                }
            />
        ));

    const sharedProps = {
        label,
        required,
        disabled,
        onClick: () => setOpened(val => !val),
    };

    const children = labelRenderer ? (
        <div
            className='full-width'
            onClick={sharedProps.onClick}
        >
            {labelRenderer({
                value,
                label,
                disabled,
            })}
        </div>
    ) : appearance === 'knob' ? (
        <Knob
            value={value}
            onClear={() => {
                onClear();
                setOpened(false);
            }}
            clearable={clearable}
            placeholder={translate(placeholder)}
            {...sharedProps}
        />
    ) : (
        <Input
            icon={icon}
            size={size}
            error={error}
            type='select'
            value={value}
            skeleton={skeleton}
            readOnly={readOnly}
            loading={loading || initialLoading}
            placeholder={translate(placeholder)}
            cornerRadius={cornerRadius}
            {...sharedProps}
        />
    );

    return (disabled || readOnly) ? children : (
        <Popover
            width={width}
            opened={opened}
            maxItems={maxItems}
            position={position}
            passState={passState}
            onClose={handleClose}
            selfSizing={selfSizing}
            itemHeight={itemHeight}
            headerRef={ref => setHeaderRef(ref)}
            scrollerRef={ref => setContentRef(ref)}
            onClickOutside={() => setOpened(false)}
            className={classNames(
                {
                    'pointer-events-none': skeleton,
                }
            )}
            {...conditionalSpread({
                header: (
                    <div className='dropdown-mobile-header'>
                        {translate(label || placeholder)}
                    </div>
                ),
            }, isMobile && (!!placeholder || !!label))}
            content={
                !!headerRef && (
                    <Autosuggest
                        inputProps={inputProps}
                        alwaysRenderSuggestions
                        suggestions={filteredData}
                        focusInputOnSuggestionClick
                        onSuggestionsFetchRequested={({ value }) => onSearch(value)}
                        renderInputComponent={props => searchRenderer(props)}
                        getSuggestionValue={suggestion => getLabel(suggestion)}
                        renderSuggestion={(suggestion, { isHighlighted }) => optionRenderer(suggestion, isHighlighted)}
                        renderSuggestionsContainer={({ containerProps, children }) => containerRenderer(containerProps, children)}
                    />
                )
            }
        >
            {children}
        </Popover>
    );
};

DropDown.propTypes = dropDownProps;

export default DropDown;
