import { useEffect, useState } from 'react';

const amount = 5;

const dark = '#001140';
const light = '#ffffff';

const cssThemes = [
    'b-sc',
    'hero',
    'brand',
    'error',
    'success',
    'warning',
    'pureWhite',
];

const useColor = (color, over = 'hero') => {
    const [colors, setColors] = useState({});

    useEffect(() => {
        if (color) {
            if (cssThemes.includes(color)) {
                setColors({
                    [`--${over}`]: `var(--${color})`,
                    [`--${over}-hover`]: `var(--${color}-hover)`,
                    [`--${over}-sc`]: `var(--${color}-sc)`,
                    [`--${over}-rgb`]: `var(--${color}-rgb)`,
                    [`--${over}-sc-rgb`]: `var(--${color}-sc-rgb)`,
                });
            } else {
                import('tinycolor2').then(({ default: tinyColor }) => {
                    const toRGB = color => {
                        const { r, g, b } = tinyColor(color).toRgb();
                        return `${r}, ${g}, ${b}`;
                    };

                    const lightness = color => parseInt(tinyColor(color).toHsl().l * 100);

                    const setColor = lightness(color || 'white') > 60 ? dark : light;

                    setColors({
                        [`--${over}`]: color,
                        [`--${over}-hover`]: lightness(color) > 50 ? tinyColor(color).darken(amount) : tinyColor(color).lighten(amount),
                        [`--${over}-sc`]: setColor,
                        [`--${over}-rgb`]: toRGB(color),
                        [`--${over}-sc-rgb`]: toRGB(setColor),
                    });
                });
            }
        }
    }, [color]); // eslint-disable-line

    return colors;
};

export default useColor;
