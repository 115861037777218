import { conditionalSpread } from 'clyne-core';

import { successStatus } from '../helpers';

const messagesToIgnore = [
].map(s => s.toLowerCase());

const handleSuccessResponse = (response, callback) => successStatus(response) ? callback() : import('./addToast').then(({ default: addToast }) => {
    if (!messagesToIgnore.includes(response?.data?.message?.toLowerCase())) {
        addToast({
            type: 'error',
            ...conditionalSpread({
                id: response?.request?.url,
            }, response?.request?.url),
            ...conditionalSpread({
                message: response?.data?.message,
            }, response?.data?.message),
        });
    }
});

export default handleSuccessResponse;
