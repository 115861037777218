import { useState, useRef, useEffect } from 'react';

export const useClickOutside = () => {
    const ref = useRef(null);
    const [hasClickedOutside, setHasClickedOutside] = useState(false);

    const handleEvent = e => {
        if (ref.current) {
            setHasClickedOutside(!(typeof ref.current.contains === 'function' && ref.current.contains(e.target)));
        }
    };

    useEffect(() => {
        if (window.PointerEvent) {
            document.addEventListener('pointerdown', handleEvent);
        } else {
            document.addEventListener('mousedown', handleEvent);
            document.addEventListener('touchstart', handleEvent);
        }

        return () => {
            if (window.PointerEvent) {
                document.removeEventListener('pointerdown', handleEvent);
            } else {
                document.removeEventListener('mousedown', handleEvent);
                document.removeEventListener('touchstart', handleEvent);
            }
        };
    }, []);

    return [ref, hasClickedOutside];
};

export default useClickOutside;
