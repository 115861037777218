import { MinusSignIcon, PlusSignIcon } from '@hugeicons/react';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Tooltip from '../tooltip';
import HoldableButton from '../holdableButton';

import { iconSize } from '../../helpers';

import './index.scss';

const CountInput = props => {
    const {
        max,
        value,
        title,
        tooltip,
        min = 1,
        onChange,
        disabled,
        canAdd = true,
        canReduce = true,
    } = props;

    const [focused, setFocused] = useState(false);
    const [changing, setChanging] = useState(false);
    const [localValue, setLocalValue] = useState(value || 0);

    const handleBlur = val => {
        val = Math.max(val, min);
        val = Math.min(val, max);
        onChange && onChange(val);
        setLocalValue(val);
    };

    useEffect(() => {
        if (!changing) {
            handleBlur(localValue);
        }
    }, [changing]); // eslint-disable-line

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    useEffect(() => {
        value !== localValue && setChanging(true);
    }, [value, localValue]);

    const handleInputChange = e => {
        const val = e.target.value;
        if (val === '' || /[0-9]+/.test(val)) {
            setLocalValue(val !== '' ? Number(val.replace(/[^0-9]*/g, '')) : '');
        }
    };

    return (
        <Tooltip
            position='top'
            closeOnBlur={false}
            content={(disabled || focused) ? '' : tooltip}
        >
            <div
                title={title || ''}
                className={classNames(
                    'count-input-holder',
                    {
                        focused,
                        disabled,
                        'active': localValue,
                    }
                )}
            >
                <HoldableButton
                    disabled={localValue <= min || !canReduce}
                    onRelease={() => setChanging(false)}
                    onHold={() => {
                        if (localValue >= min) {
                            setChanging(true);
                            setLocalValue(localValue => localValue - 1);
                        }
                    }}
                    icon={(
                        <MinusSignIcon
                            variant='solid'
                            style={iconSize(16)}
                        />
                    )}
                />
                <input
                    step={1}
                    min={min}
                    max={max}
                    type='number'
                    value={localValue}
                    disabled={disabled}
                    onChange={handleInputChange}
                    onFocus={() => {
                        setFocused(true);
                    }}
                    onBlur={() => {
                        setFocused(false);
                        setChanging(false);
                        !localValue && setLocalValue(value);
                    }}
                />
                <HoldableButton
                    disabled={localValue >= max || !canAdd}
                    onRelease={() => setChanging(false)}
                    onHold={() => {
                        if (localValue <= max) {
                            setChanging(true);
                            setLocalValue(localValue => localValue + 1);
                        }
                    }}
                    icon={(
                        <PlusSignIcon
                            variant='solid'
                            style={iconSize(16)}
                        />
                    )}
                />
            </div>
        </Tooltip>
    );
};

CountInput.propTypes = {
    max: PropTypes.number,
    min: PropTypes.number,
    value: PropTypes.number,
    title: PropTypes.string,
    canAdd: PropTypes.bool,
    tooltip: PropTypes.any,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    canReduce: PropTypes.bool,
};

export default CountInput;
