import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Price from '../price';
import Rating from '../rating';
import LazyImage from '../lazyImage';
import Translate from '../translate';

import './index.scss';

const ScreenFeatured = props => {
    const {
        to,
        name,
        price,
        count,
        image,
        rating,
        blurHash,
        skeleton,
        description,
    } = props;

    const hasDescription = props.hasOwnProperty('description');

    return (
        <div
            className={classNames(
                `screen-featured-holder`,
                {
                    skeleton,
                    'pointer-events-none': skeleton,
                }
            )}
        >
            <figure>
                <div
                    className={classNames(
                        'screen-featured-figure-image',
                        'absolutely-splash',
                        {
                            'skeleton-blink': !blurHash && skeleton,
                        }
                    )}
                >
                    <LazyImage
                        alt={name}
                        blurHash={blurHash}
                        {...(typeof image === 'string' ? ({
                            source: image,
                        }) : image)}
                    />
                </div>
                {!skeleton && (
                    <>
                        {(!!rating) && (
                            <div className='screen-featured-rating-holder'>
                                <Rating rate={rating} />
                            </div>
                        )}
                        <NavLink
                            to={to}
                            aria-label={name}
                            className='absolutely-splash'
                        />
                    </>
                )}
            </figure>
            <h3 title={name}>
                {skeleton ? (
                    <div className='skeleton-wave' />
                ) : (
                    <NavLink
                        to={to}
                        className='text-ellipsis'
                    >
                        {name}
                    </NavLink>
                )}
            </h3>
            {!!hasDescription && (
                <div className='screen-featured-description'>
                    {skeleton ? (
                        <div className='skeleton-wave medium' />
                    ) : (
                        description
                    )}
                </div>
            )}
            <div className='divider' />
            <div className='screen-featured-additional-information'>
                {skeleton ? (
                    <>
                        <div className='skeleton-wave small' />
                        <div className='skeleton-wave wide' />
                    </>
                ) : (
                    <>
                        {!!count && (
                            <p>
                                <Translate replaceMap={{ '_COUNT_': count }}>_COUNT_ Screens</Translate>
                            </p>
                        )}
                        <p>
                            <strong>
                                <Price number={price} />
                            </strong>
                            <span>
                                {` `}
                                <Translate>average</Translate>
                            </span>
                        </p>
                    </>
                )}
            </div>
        </div>
    );
};

ScreenFeatured.propTypes = {
    to: PropTypes.string,
    name: PropTypes.any,
    count: PropTypes.number,
    price: PropTypes.number,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
        alt: PropTypes.string,
        style: PropTypes.object,
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        source: PropTypes.string,
        source2x: PropTypes.string,
        sourceWEBP: PropTypes.string,
        source2xWEBP: PropTypes.string,
    })]),
    rating: PropTypes.number,
    skeleton: PropTypes.bool,
    blurHash: PropTypes.string,
    description: PropTypes.any,
};

export default ScreenFeatured;
