import { ConfigProvider, theme } from 'antd';
import { conditionalSpread } from 'clyne-core';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';

import { modeState } from '../../state';

import './index.scss';

const AntHydrate = props => {
    const {
        children,
    } = props;

    const mode = useRecoilValue(modeState);

    return (
        <ConfigProvider
            theme={{
                ...conditionalSpread({
                    algorithm: theme.darkAlgorithm,
                }, mode === 'dark'),
                token: {
                    colorPrimary: mode === 'dark' ? '#3d76ff' : '#2b5cd1',
                    colorLinkHover: mode === 'dark' ? '#2464ff' : '#3e6bd7',
                    fontFamily: '\'Inter\', \'Noto Sans Armenian\', -apple-system, BlinkMacSystemFont, sans-serif',
                },
            }}
        >
            {children}
        </ConfigProvider>
    );
};

AntHydrate.propTypes = {
    children: PropTypes.node,
};

export default AntHydrate;
